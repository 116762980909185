import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { Container, Title } from './styles';

PageContainer.propTypes = {
    title: PropTypes.string,
    scroll: PropTypes.bool,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

PageContainer.defaultProps = {
    title: null,
    scroll: false,
};

function PageContainer({
    title, children, scroll, ...props
}) {

    if (scroll) {
        return (
            <Scrollbars
                autoHeight
                autoHeightMax="100%"
            >
                <Container {...props}>
                    {title && <Title>{title}</Title>}
                    {children}
                </Container>
            </Scrollbars>
        );
    }

    return (
        <Container {...props}>
            {title && <Title>{title}</Title>}
            {children}
        </Container>
    );
}

export default PageContainer;
