import React, {
    useRef, useState, useEffect, useContext,
} from 'react';
import { useHistory } from 'react-router';
import { Field, Formik } from 'formik';
import moment from 'moment';
import PageContainer from '../../../components/page-container';
import Table from '../../../components/table';
import Request, { getLimit } from '../../../utils/Request';
import Situacao from '../../../components/situacao';
import { getSituacaoDiario } from '../../../utils/diarios';
import Icon from '../../../components/icon';
import { colors } from '../../../configs/theme';
import { error, success } from '../../../components/alerts';
import confirmAlert from '../../../components/confirm-alert';
import { Row } from '../../../app/global-styles';
import ControlledInput from '../../../components/form-components/controlled-input';
import { parser } from '../../../utils/Select';
import { AppContext } from '../../../app/app-container';

moment.locale('pt-br');

function Diarios(props) {
    const { hasPermissao } = useContext(AppContext);
    const history = useHistory();
    const tableRef = useRef();
    const [searchParams, setSearchParams] = useState({});

    const initialFilters = {
        situacao: {},
        tipo: {},
        filtrar: '',
        data: null,
    };

    useEffect(() => {
        tableRef.current.fireFetchData();
    }, [searchParams]);

    async function getDiarios({ page, limit: table_limit, ...table_props }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_diarios = request.setRequest('diarios', 'listar', { limit, ...searchParams });
        const result = await request.execute();
        return result[req_diarios];
    }

    async function desativarDiario({ original }) {
        if (original && original.id) {
            const desativar = async () => {
                const request = new Request();

                const { id } = original;

                const req_desativar = request.setRequest('diarios', 'desativar', { id });
                const result = await request.execute();

                if (result[req_desativar] === true) {
                    success('Diário excluído com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir diário!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir diário?',
                subtitle: `Ao confirmar o diário ${original.numero_descricao} será excluído!`,
                onConfirm() {
                    desativar(original).then();
                },
            });

        } else {
            error('Diário inválida!');
        }
    }

    async function getTiposDiarioSelect(search) {
        const request = new Request();

        const req_tipos = request.setRequest('tiposDiario', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_tipos] || [];

        return parser('descricao', 'id', dados);
    }

    function makeFormFiltros(formikState) {
        return (
            <Row
                as="form"
                wrap="wrap"
            >
                <Field
                    name="filtrar"
                    type="text"
                    placeholder="Filtrar"
                    size={1}
                    minWidth={210}
                    onChange={(e) => {
                        const { value, name } = e.target;
                        setSearchParams({ ...searchParams, [name]: value });
                    }}
                    icon="icon-lupa"
                    kind="filtros"
                    show_filtros={false}
                    component={ControlledInput}
                />
                <Field
                    component={ControlledInput}
                    name="data"
                    margin="0 0 0 15px"
                    minWidth={210}
                    kind="filtros"
                    type="date-picker"
                    onChange={(value) => {
                        const new_data = moment(value).format('YYYY-MM-DD');
                        setSearchParams({ ...searchParams, data: new_data });
                    }}
                    placeholder="Data do diário"
                    size={1}
                />
                <Field
                    component={ControlledInput}
                    name="tipo"
                    margin="0 0 0 15px"
                    padding="0"
                    minWidth={210}
                    isClearable
                    type_select="async"
                    type="select"
                    placeholder="Tipo do diário"
                    size={1}
                    onChange={({ value }) => {
                        setSearchParams({ ...searchParams, tipo: value });
                    }}
                    kind="filtros"
                    defaultOptions
                    cacheOptions
                    loadOptions={getTiposDiarioSelect}
                />
                <Field
                    component={ControlledInput}
                    name="situacao"
                    margin="0 0 0 15px"
                    padding="0"
                    type="select"
                    minWidth={210}
                    isClearable
                    placeholder="Situação do diário"
                    size={1}
                    onChange={({ value }) => {
                        setSearchParams({ ...searchParams, situacao: value });
                    }}
                    kind="filtros"
                    options={[
                        { value: 1, label: 'Em andamento' },
                        { value: 2, label: 'Publicado' },
                    ]}
                />
                {Object.keys(searchParams).some((key) => searchParams[key] && searchParams[key] !== '') && (
                    <Icon
                        className="icon-limpar"
                        size="26px"
                        color={colors.grey_placeholder}
                        title="Limpar filtros"
                        onClick={() => {
                            setSearchParams({});
                            formikState.resetForm({ ...initialFilters });
                        }}
                        hover={colors.white}
                        marginLeft="15px"
                        kind="action-button"
                    />
                )}
            </Row>
        );
    }

    return (
        <PageContainer
            padding
            scroll
        >
            <Row
                margin="0 0 21px 0"
            >
                <Formik
                    initialValues={{ ...initialFilters }}
                >
                    {makeFormFiltros}
                </Formik>
            </Row>
            <Table
                headers={[
                    {
                        name: 'Número',
                        accessor: 'numero',
                    },
                    {
                        name: 'Data',
                        accessor: 'data',
                        Cell: (props) => <>{props.value.split('-').reverse().join('/')}</>,
                    },
                    {
                        name: 'Tipo',
                        accessor: 'tipo_diario_descricao',
                    },
                    {
                        name: 'Situação',
                        accessor: 'situacao',
                        Cell: (props) => (
                            <Situacao
                                message={props.original.situacao_descricao}
                                color={getSituacaoDiario(Number(props.value))}
                            />
                        ),
                    },
                ]}
                options={hasPermissao('D_DIARIOS') ? (table_props) => (
                    <Icon
                        hidden
                        size="16px"
                        hover={colors.red_error_message}
                        color={colors.black_table}
                        className="icon-trash"
                        onClick={() => desativarDiario(table_props)}
                    />
                ) : null}
                tableRef={tableRef}
                data_function={getDiarios}
                clickHandler={(row, column) => {
                    if (column.id !== 'options' && row && row.original && row.original.id) {
                        history.push(`/painel/diarios/editor/id=${row.original.id}`);
                    }
                }}
            />
        </PageContainer>
    );
}

export default Diarios;
