import React, {
    useState, useContext, createContext,
} from 'react';
import { Route, Switch } from 'react-router-dom';
import { AppContent, Container } from './styles';
import HeaderCidadao from '../../components/header-cidadao';
import HomeCidadao from '../../pages/cidadao/home';
import DiarioLista from '../../pages/cidadao/diario-lista';
import DiarioSumario from '../../pages/cidadao/diario-sumario';
import MateriaCidadao from '../../pages/cidadao/materia';
import Buscar from '../../pages/cidadao/buscar';
import Error from '../../components/error';
import FooterCidadao from '../../components/footer';
import { AppContext } from '../app-container';

export const CidadaoContext = createContext();

function Cidadao() {
    const { client } = useContext(AppContext);

    const searchParamsInitialValues = {
        orgao: {},
        tipo: {},
        filtrar: '',
        periodo: null,
    };

    const [searchParams, setSearchParams] = useState({ ...searchParamsInitialValues });

    return (
        <CidadaoContext.Provider value={{
            searchParams, setSearchParams, searchParamsInitialValues,
        }}
        >
            <Route>
                <Container>
                    <HeaderCidadao
                        instituicao={client.nome}
                    />
                    <AppContent>
                        <Switch>
                            <Route
                                path="/"
                                exact
                                component={HomeCidadao}
                            />
                            <Route
                                path="/diario/lista"
                                component={DiarioLista}
                            />
                            <Route
                                path="/diario/sumario"
                                component={DiarioSumario}
                            />
                            <Route
                                path="/materia"
                                component={MateriaCidadao}
                            />
                            <Route
                                path="/buscar"
                                component={Buscar}
                            />
                            <Route component={Error} />
                        </Switch>
                    </AppContent>
                    <FooterCidadao />
                </Container>
            </Route>
        </CidadaoContext.Provider>
    );
}

export default Cidadao;
