import React from 'react';
import PropTypes from 'prop-types';
import { StyledButton, StyledLink } from './styles';
import Icon from '../icon';
import { colors } from '../../configs/theme';

Button.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    icon: PropTypes.string,
    kind: PropTypes.oneOf(['save', 'cancel', 'reorder', 'custom', 'lista-materia', 'actions']),
    width: PropTypes.string,
    height: PropTypes.string,
    to: PropTypes.string,
    margin: PropTypes.string,
    padding: PropTypes.string,
    fontSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    iconSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    flex: PropTypes.number,
    text: PropTypes.string,
    type: PropTypes.string,
    color: PropTypes.string,
    disabled: PropTypes.bool,
};

Button.defaultProps = {
    icon: null,
    kind: 'custom',
    width: null,
    height: null,
    fontSize: null,
    to: null,
    flex: null,
    iconSize: null,
    disabled: false,
    type: 'button',
    padding: null,
    text: null,
    margin: null,
    color: colors.white,
};


function Button({
    children, icon, kind, color, iconSize, margin, to, ...props
}) {

    const button_props = {
        kind,
        button_margin: margin,
        to,
        ...props,
    };

    if (kind === 'save') {
        icon = 'icon-save';
    }

    if (kind === 'cancel') {
        icon = 'icon-close';
    }

    if (kind === 'reorder') {
        icon = 'icon-reorder-arrow';
    }

    const icon_props = {
        className: icon,
        marginRight: '8px',
        size: `${iconSize}px` || '15px',
    };

    if (color) {
        button_props.color = color;
        icon_props.color = color;
    }

    if (kind === 'actions') {
        button_props.color = colors.primary_blue;
        icon_props.color = colors.primary_blue;
    }

    const Component = to !== null ? StyledLink : StyledButton;

    return (
        <Component {...button_props}>
            {icon && <Icon {...icon_props} />}
            {children}
        </Component>
    );
}

export default Button;
