export function getSituacaoMateria(situacao) {
    switch (situacao) {
        case 3:
            return 'red';
        case 2:
            return 'green';
        case 1:
        default:
            return 'grey';

    }
}
