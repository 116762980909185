import React from 'react';
import PropTypes from 'prop-types';
import {
    Container, GroupContainer, StyledGroup, InputLabel,
} from './styles';
import { Label, LabelMessage, Required } from '../styles';
import ErrorMessage from '../error-message';

function CheckBoxGroup({
    name,
    id,
    label,
    error_message,
    required,
    options,
    onChange,
    value,
    margin, ...props
}) {
    return (
        <Container>
            {label && (
                <Label>
                    <LabelMessage>
                        {label}
                        {required && <Required>*</Required>}
                    </LabelMessage>
                </Label>
            )}
            <GroupContainer>
                <StyledGroup
                    name={name}
                    value={value}
                    onChange={onChange}
                >
                    {(CheckBox) => (
                        <>
                            {options.map((option) => (
                                <InputLabel
                                    key={option.value}
                                    checked={value && value.includes(option.value)}
                                    show_error={!!error_message}
                                    margin={margin}
                                >
                                    <CheckBox value={option.value} />
                                    {option.label}
                                </InputLabel>
                            ))}
                        </>
                    )}
                </StyledGroup>
            </GroupContainer>
            {error_message && <ErrorMessage error_message={error_message} />}
        </Container>
    );
}

export default CheckBoxGroup;

CheckBoxGroup.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    error_message: PropTypes.string,
    value: PropTypes.array,
    required: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    margin: PropTypes.string,
};

CheckBoxGroup.defaultProps = {
    name: null,
    id: null,
    value: [],
    label: null,
    error_message: null,
    required: false,
    margin: '0 15px 10px 0',
    onChange: null,
};
