import styled, { css } from 'styled-components';
import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

const getContainerStyle = (props) => {
    if (props.isDragAccept) {
        return css`
            border-color: ${colors.primary_green};
            background-color: ${colors.green_hover};
        `;
    }

    if (props.isDragReject) {
        return css`
            border-color: ${colors.red};
            background-color: ${colors.red_cancel_hover_light};
        `;
    }
};

export const Container = styled('div')`
    align-items: center;
    border: 1px dashed ${colors.grey_chumbo};
    display: flex;
    border-radius: 6px;
    height: 350px;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    z-index: 1;
    cursor: pointer;
    ${(props) => getContainerStyle(props)}
`;

export const Descricao = styled('span')`
    color: ${colors.grey_chumbo};
    font-size: ${parse(16)};
    text-align: center;
    margin-top: 10px;
`;

export const DiarioFileContainer = styled('div')`
    display: flex;
    border-radius: 6px;
    height: auto;
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
    background-color: white;
    padding: 0;
    flex-direction: column;
    position: relative;
`;

export const IconInfoContainer = styled('div')`
    height: calc(100% - 4px);
    width: 100%;
    padding: 11px 6px 6px 6px;
    display: flex;
`;

export const DiarioFileInfo = styled('div')`
    width: calc(100% - 87px);
    height: 100%;
    display: flex;
    justify-content: center;
    margin-left: 15px;
    margin-top: auto;
    margin-bottom: auto;
    flex-direction: column;
`;

export const DiarioFileDescricao = styled('div')`
    font-size: ${parse(18)};
    font-weight: bold;
    margin-bottom: 5px;
`;

export const DiarioFileDataPublicacao = styled('div')`
    font-size: ${parse(14)};
`;

export const ProgressBar = styled('div')`
    background-color: ${colors.primary_green};
    width: ${(props) => props.progress}%;
    border-radius: ${(props) => (props.progress && Number(props.progress) === 100 ? '0 0 6px 6px' : '0 0 0 6px')};
    transition: .2s;
    height: 6px;
`;
