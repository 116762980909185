import styled, {css} from 'styled-components';
import DatePicker from 'react-date-picker';
import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

export const StyledDatePicker = styled(DatePicker)`
    width: ${(props) => (props.padding_icon ? `calc(${props.width} - ${props.padding_icon})` : props.width)};
    height: 100%;
    background: ${(props) => props.background};
    color: ${colors.grey_chumbo};
    text-align: left;
    padding: 0 10px;
    font-size: ${parse(16)};

    .react-date-picker__inputGroup__input {
        color: ${(props) => (props.color ? props.color : colors.grey_chumbo)};
        opacity: 1;
    }

    &::placeholder {
        opacity: 1;
        color: ${colors.grey_placeholder};

        &::placeholder {
            opacity: 1;
        }
    }

    &:focus {
        border-color: ${colors.primary_blue};
    }

    ${(props) => props.padding && css`padding: ${props.padding};`}
    ${(props) => props.radius && css`border-radius: ${props.radius};`}
    ${(props) => props.border && css`border: ${props.border};`}
    ${(props) => props.shadow && css`box-shadow: 0 4px 2px -4px rgba(0,0,0,0.4);`}

    ${(props) => props.error_message && css`
        border-color: ${colors.red_error_message};

        &::placeholder {
            color: ${colors.red_error_message};
        }
    `}

    ${(props) => props.disabled && css`
        border-color: ${colors.grey_text};
        color: ${colors.grey_placeholder};
        background-color: ${colors.grey_lines_table};
        cursor: not-allowed;
        .react-date-picker__inputGroup__input {
            color: ${colors.grey_placeholder};
            cursor: not-allowed;
        }
    `}

    .react-date-picker__wrapper {
        border: unset;
        width: 100%;
    }
`;
