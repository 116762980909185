import React from 'react';
import PropTypes from 'prop-types';
import CKEditor from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import './styles.css';
import { Label, LabelMessage, Required } from '../styles';
import { Container, EditorContainer } from './styles';
import ErrorMessage from '../error-message';

function EditorWYSIWYG({
    onBlur, label, value, error_message, required, id, ...props
}) {

    function handleEditorBlur(e) {
        if (onBlur) {
            onBlur(e);
        }
    }

    return (
        <Container>
            {label && (
                <Label htmlFor={id}>
                    <LabelMessage>
                        {label}
                        {required && <Required>*</Required>}
                    </LabelMessage>
                </Label>
            )}
            <EditorContainer error={error_message}>
                <CKEditor
                    editor={ClassicEditor}
                    {...props}
                    data={value}
                    id={id}
                    onBlur={handleEditorBlur}
                />
            </EditorContainer>
            {error_message && <ErrorMessage error_message={error_message} />}
        </Container>
    );
}

EditorWYSIWYG.propTypes = {
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    label: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    id: PropTypes.string,
    error_message: PropTypes.string,
};


export default EditorWYSIWYG;
