export const menu = [
    {
        icon: 'icon-orgaos',
        link: '/painel/orgaos',
        title: 'Órgãos',
        permissao: ['R_ORGAOS', 'M_ORGAOS'],
    },
    {
        icon: 'icon-tipo-diario',
        link: '/painel/tipos_diario',
        title: 'Tipos de Diários',
        permissao: ['R_TIPOS_DIARIO', 'M_TIPOS_DIARIO'],
    },
    {
        icon: 'icon-tipo-materia',
        link: '/painel/tipos_materia',
        title: 'Tipos de Matérias',
        permissao: ['R_TIPOS_MATERIA', 'M_TIPOS_MATERIA'],
    },
    {
        icon: 'icon-materias',
        link: '/painel/materias',
        title: 'Matérias',
        permissao: 'R_MATERIAS',
    },
    {
        icon: 'icon-diario',
        link: '/painel/diarios',
        title: 'Diários',
        permissao: 'R_DIARIOS',
    },
];
