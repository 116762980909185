import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

const buttonType = (kind) => {
    switch (kind) {
        case 'lista-materia':
            return css`
                background-color: ${colors.primary_blue_hover_selected};
                border: 1px solid transparent;
                margin-right: 10px;

                &:last-child {
                  margin-right: 0;
                }

                &:hover {
                    border: 1px solid ${colors.primary_blue_hover_selected};
                    background-color: ${colors.white};
                }
            `;
        case 'actions':
            return css`
                background: linear-gradient(180deg, #FFFFFF, #E3E6E9);
                margin-right: 10px;
                box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.15);

                &:last-child {
                  margin-right: 0;
                }

                &:hover {
                    background: linear-gradient(180deg, #FFFFFF, #c2c5c8);
                }
            `;
        case 'cancel':
            return css`
                background-color: ${colors.red_cancel};

                &:hover {
                    background-color: ${colors.red_cancel_hover};
                }
            `;
        case 'custom':
        default:
            return css`
                background-color: ${colors.primary_blue};

                &:hover {
                    background-color: ${colors.primary_blue_hover_button};
                }
            `;
    }
};

const buttonStyle = css`
    border-radius: 5px;
    display: flex;
    align-items: center;
    padding: ${(props) => (props.padding ? props.padding : '10px 14px')};
    color: ${(props) => (props.color ? props.color : colors.white)};
    font-weight: bold;
    font-size: ${(props) => (props.fontSize ? parse(props.fontSize) : parse(14))};
    width: ${(props) => (props.width ? props.width : 'fit-content')};
    height: ${(props) => (props.height ? props.height : 'auto')};
    cursor: pointer;
    ${(props) => buttonType(props.kind)}
    ${(props) => props.button_margin && css`margin: ${props.button_margin}`}
    ${(props) => props.flex && css`flex: ${props.flex}`}
    ${(props) => props.text && css`
        display: flex;
        justify-content: center;
    `}
    ${(props) => props.disabled && css`
        cursor: not-allowed;
        background-color: ${colors.blue_disabled} !important;
    `}
`;

export const StyledButton = styled('button')`
    ${buttonStyle}
`;

export const StyledLink = styled(Link)`
    ${buttonStyle}
`;
