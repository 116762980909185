import styled, {css} from 'styled-components';
import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

export const Main = styled('main')`
    width: 100%;
`;

export const Container = styled('div')`
    display: grid;
    grid-template-areas: 'layer verification' 'layer calendar';
    grid-template-rows: 272px 560px;
    grid-template-columns: 1fr 1fr;
    grid-gap: 40px;
`;

export const BgLayer = styled('div')`
    width: 100%;
    height: 872px;
    background: ${colors.grey_chumbo_pagination};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    grid-area: layer;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 9px;
`;

export const Topo = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 21px;
`;

export const SemDiario = styled('div')`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    padding: 21px;
    align-items: center;
`;

export const SemDiarioText = styled('div')`
    color: ${colors.white};
    font-size: ${parse(22)};
    line-height: 30px;
`;

export const Title = styled('h1')`
    color: ${colors.white};
    font-size: ${parse(26)};
    line-height: 30px;
`;

export const DiarioDescricao = styled('div')`
    display: flex;
    flex-direction: column;
    padding-left: 30px;

    p {
        font-size: ${parse(11)};
        text-align: center;
        line-height: 13px;
        color: ${colors.grey_text};
        font-weight: bold;
    }
`;

export const ContIframe = styled('div')`
    width: 100%;
    flex-grow: 1;
    padding: 0 21px 30px;
`;

export const Frame = styled('iframe')`
    width: 100%;
    height: 100%;
`;

export const FieldSet = styled('div')`
    width: 100%;
    display: flex;
    height: 35px;
    justify-content: space-between;
`;

const styleBlocks = css`
    background: ${colors.white};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
`;

export const Verification = styled('div')`
    width: 100%;
    height: 272px;
    grid-area: verification;
    padding: 30px;
    display: flex;
    align-items: center;
    ${styleBlocks}
`;

export const ContainerAutenticidade = styled('div')`
    width: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`;

export const TitleBox = styled('h3')`
    font-size: ${parse(26)};
    line-height: 30px;
    color: ${colors.grey_chumbo_pagination};
    margin-bottom: 7px;
`;

export const AutenticidadeSubText = styled('span')`
    font-size: ${parse(16)};
    line-height: 20px;
    color: ${colors.grey_chumbo_pagination};
    margin-bottom: 11px;
`;

export const AutenticidadeFoto = styled('img')`
    width: 200px;
    height: 181px;
    margin-left: 28px;
    flex-shrink: 0;
`;

export const CalendarContainer = styled('div')`
    width: 554px;
    height: 560px;
    padding: 30px;
    position: relative;
    grid-area: calendar;
    ${styleBlocks}
`;
