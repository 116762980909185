import React, {
    useState, useEffect, useRef, useContext,
} from 'react';
import { useLocation, useRouteMatch, useHistory } from 'react-router';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import moment from 'moment';
import { pathname2Query } from '../../../utils/RouterRes';
import { Row } from '../../../app/global-styles';
import ControlledInput from '../../../components/form-components/controlled-input';
import Button from '../../../components/button';
import Resizer from '../../../components/resizer';
import PageContainer from '../../../components/page-container';
import Request, { getLimit } from '../../../utils/Request';
import { parser } from '../../../utils/Select';
import { error, loading, success } from '../../../components/alerts';
import Table from '../../../components/table';
import Situacao from '../../../components/situacao';
import { getSituacaoMateria } from '../../../utils/materias';
import { URL_SERVER } from '../../../data/constants';
import UploadDiario from './upload-diario';
import { AppContext } from '../../../app/app-container';

moment.locale('pt-br');

function Diario(props) {
    const { hasPermissao } = useContext(AppContext);
    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();
    const tableRef = useRef();

    const [diario, setDiario] = useState({});
    const [showButton, setShowButton] = useState(false);
    const [publicado, setPublicado] = useState(false);

    const initialValues = diario && Object.keys(diario).length > 0 ? diario : {
        data: '',
        tipo: {},
        numero: '',
    };

    useEffect(() => {
        tableRef.current.fireFetchData();
    }, [diario]);

    const url_params = pathname2Query(location.pathname, match.url);

    async function getDiario(id) {
        const request = new Request();

        const req_diario = request.setRequest('diarios', 'listar', { id });

        const result = await request.execute();

        const { dados } = result[req_diario];

        if (dados && dados.id) {
            const diario_to_edit = {};
            diario_to_edit.numero = dados.numero;
            diario_to_edit.id = dados.id;
            diario_to_edit.data = moment(dados.data, 'YYYY-MM-DD')
                .toDate();

            if (dados.situacao && Number(dados.situacao) === 2) {
                setPublicado(true);
            }

            if (dados.tipo && !isNaN(dados.tipo)) {
                diario_to_edit.tipo = {
                    value: dados.tipo,
                    label: dados.tipo_diario_descricao,
                };
            }

            setDiario(diario_to_edit);
        }
    }

    useEffect(() => {
        if (url_params.id && !isNaN(url_params.id)) {
            getDiario(url_params.id)
                .then();
        } else {
            setShowButton(false);
            setDiario(null);
            setPublicado(false);
        }
    }, [url_params.id]);

    async function handleSubmit(values, state) {
        const request = new Request();
        const diario_to_save = {};

        diario_to_save.numero = values.numero;

        if (values.tipo.value) {
            diario_to_save.tipo = values.tipo.value;
        }

        if (values.data) {
            diario_to_save.data = moment(values.data)
                .format('YYYY-MM-DD');
        }

        if (values.id) {
            diario_to_save.id = values.id;
        }

        diario_to_save.situacao = 1;

        const loadToast = loading('Salvando diário');

        try {
            const req_diario = request.setRequest(
                'diarios',
                'salvar',
                { diario: diario_to_save },
            );

            const result = await request.execute();

            if (result[req_diario] === true) {
                loadToast();
                success(`Diário ${diario_to_save.numero} alterado com sucesso`);
            } else if (Number(result[req_diario]) > 0) {
                loadToast();
                success(`Diário ${diario_to_save.numero} adicionado com sucesso`);
                history.push(`${match.url}/id=${result[req_diario]}`);
            } else {
                loadToast();
                error('Não foi possível salvar diário!');
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar diário');
        }

    }

    async function getTiposDiarioSelect(search) {
        const request = new Request();

        const req_tipos = request.setRequest('tiposDiario', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_tipos] || [];

        const formated_tipos = parser('descricao', 'id', dados);

        return formated_tipos;
    }

    async function getMaterias({ page, limit: table_limit, ...table_props }) {
        if (diario && diario.id) {
            const request = new Request();

            const limit = getLimit(page, table_limit);

            const params = {
                diario: diario.id,
            };

            const req_orgaos = request.setRequest('materias', 'listar', { limit, ...params });
            const result = await request.execute();
            return result[req_orgaos];
        }
        return {};
    }

    async function alterarSituacao(materias, situacao) {
        const request = new Request();

        const req_situacao = request.setRequest('materias', 'alterarSituacao', {
            situacao,
            materias,
        });

        const result = await request.execute();

        const situacao_text = situacao === 2 ? 'aprovadas' : 'reprovadas';

        if (result[req_situacao]) {
            success(`Matérias ${situacao_text} com sucesso!`);
            tableRef.current.props.Checkbox.setSelecteds({});
            tableRef.current.fireFetchData();
        }
    }


    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row
                    height="auto"
                    spaceBetween
                >
                    <Field
                        component={ControlledInput}
                        name="numero"
                        type="text"
                        required
                        disabled={publicado}
                        label="Número do diário"
                        placeholder="Número do diário"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="tipo"
                        type_select="async"
                        required
                        type="select"
                        label="Tipo de diário"
                        placeholder="Tipo de diário"
                        disabled={publicado}
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getTiposDiarioSelect}
                    />
                </Row>
                <Row
                    height="auto"
                    spaceBetween
                >
                    <Field
                        component={ControlledInput}
                        name="data"
                        type="date-picker"
                        required
                        disabled={publicado}
                        label="Data do diário"
                        placeholder="Data do diário"
                        size={8}
                    />
                </Row>
                <Row
                    contentEnd
                    padding="0 15px"
                >
                    {diario && diario.id && (
                        <>
                            <UploadDiario diario={diario} />
                            <Button
                                type="button"
                                margin="0 15px 0 0"
                                icon="icon-materias"
                                onClick={() => {
                                    window.open(`${URL_SERVER}/painel/diario?id=${diario.id}`);
                                }}
                            >
                            Download do Diário
                            </Button>
                            <Button
                                type="button"
                                margin="0 15px 0 0"
                                icon="icon-materias"
                                onClick={() => {
                                    window.open(`${URL_SERVER}/painel/diario?id=${diario.id}&force_download=false`);
                                }}
                            >
                            Visualizar Diário
                            </Button>
                        </>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={publicado || formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    return (
        <Resizer
            left={50}
            right={50}
            minLeft={500}
            minRight={500}
        >
            <PageContainer>
                <Row margin="0 0 10px 0">
                    {showButton && (
                        <>
                            <Button
                                kind="cancel"
                                onClick={() => {
                                    const materias = tableRef.current.props.Checkbox.getSelectedsArray();
                                    const situacao = 3;

                                    alterarSituacao(materias, situacao)
                                        .then();
                                }}
                            >
                                Reprovar
                            </Button>
                            <Button
                                kind="save"
                                margin="0 0 0 10px"
                                onClick={() => {
                                    const materias = tableRef.current.props.Checkbox.getSelectedsArray();
                                    const situacao = 2;

                                    alterarSituacao(materias, situacao)
                                        .then();
                                }}
                            >
                                Aprovar
                            </Button>
                        </>
                    )}
                </Row>
                <Table
                    headers={[
                        {
                            name: 'Titulo',
                            accessor: 'titulo',
                        },
                        {
                            name: 'Autor',
                            accessor: 'autor_nome',
                        },
                        {
                            name: 'Situação',
                            accessor: 'situacao',
                            Cell: (props) => (
                                <Situacao
                                    message={props.original.situacao_descricao}
                                    color={getSituacaoMateria(Number(props.value))}
                                />
                            ),
                        },
                    ]}
                    checkbox={!publicado && hasPermissao('AR_DIARIOS')}
                    onCheckboxChange={(selecteds, selecteds_array) => {
                        setShowButton(selecteds_array.length > 0);
                    }}
                    clickHandler={(row, column, state, instance) => {
                        if (row) {
                            const { original } = row;
                            const { id } = original || 0;

                            if (id > 0 && instance.props.Checkbox) {
                                instance.props.Checkbox.toggleSelected(id);
                            }
                        }

                    }}
                    noDataText="Nenhuma matéria associada à esse diário ainda"
                    tableRef={tableRef}
                    data_function={getMaterias}
                />
            </PageContainer>
            <PageContainer title="Diário">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object()
                        .shape({
                            tipo: yup.object()
                                .validSelect('Tipo é obrigatório!'),
                            numero: yup.string()
                                .required('Número é obrigatório!')
                                .max(15, 'Máximo de 15 caractreres!'),
                            data: yup.string()
                                .validDate('Data é inválida!')
                                .diarioData('Já existe um diário publicado nessa data!', diario && diario.id ? diario.id : null)
                                .required('Data é obrigatória!'),
                        })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        </Resizer>
    );
}

export default Diario;
