/*
 * Configurações imutaveis do sistema
 */

const tag_base = document.getElementsByTagName('base');

/**
 * A URL do servidor
 *
 * @type {string}
 */
export const URL_SERVER = process.env.NODE_ENV === 'development'
    ? 'http://localhost/diario-oficial/server/public_html' : 'http://serverdiariooficial.demo.nucleo.site';

/**
 * A URL para a API
 *
 * @type {string}
 */
export const URL_API = process.env.NODE_ENV === 'development'
    ? 'http://localhost/diario-oficial/server/public_html/api' : 'http://serverdiariooficial.demo.nucleo.site/api';

/**
 * Informa se a pagina possui uma tag base
 *
 * @return {boolean}
 */
export const HAS_BASE = tag_base.length > 0;

/**
 * Retorna a url ate o diretório onde está localizando o index
 *
 * @return {string}
 */
export const BASE = HAS_BASE
    ? tag_base[0].href.replace(/[/]+$/, '')
    : `${window.location.protocol}//${window.location.host}`;
