import React, { useContext } from 'react';
import {
    Link, useLocation, useRouteMatch,
} from 'react-router-dom';
import { AppContext } from '../../app/app-container';
import {
    Container, Left, Right, Tab,
} from './styles';
import { Bar } from '../../app/global-styles';
import Icon from '../icon';
import { colors } from '../../configs/theme';
import { getTabGroup } from '../../configs/tab-groups';

function HeaderNavigation(props) {
    const { logout, hasPermissao } = useContext(AppContext);
    const location = useLocation();
    const routeMatch = useRouteMatch();

    const tabs = getTabGroup(location.pathname, routeMatch.url).filter((tab) => tab.permissao && hasPermissao(tab.permissao));

    return (
        <Container>
            <Left>
                {tabs.map((tab) => (
                    <Tab
                        to={tab.to}
                        key={tab.to}
                        selected={tab.selected}
                    >
                        {tab.title}
                        {tab.selected && (
                            <Bar
                                absolute
                                orientation="horizontal"
                                left
                                bottom
                            />
                        )}
                    </Tab>
                ))}
            </Left>
            <Right>
                <Link
                    to="/painel/usuarios"
                    title="Usuários"
                >
                    <Icon
                        className="icon-user"
                        size="20px"
                        pointer
                        color={colors.grey_placeholder}
                    />
                </Link>
                <div
                    title="Sair"
                    onClick={(e) => logout && logout(e)}
                >
                    <Icon
                        className="icon-power-off"
                        size="20px"
                        pointer
                        color={colors.grey_placeholder}
                    />
                </div>
            </Right>
        </Container>
    );
}

export default HeaderNavigation;
