import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import moment from 'moment';
import { useLastLocation } from 'react-router-last-location';
import {
    Container, Header, Informacoes, Logo, Dados, Title, Text, Buttons, TituloMateria, ConteudoMateria, Alert,
} from './styles';
import brasao from '../../../assets/img/brasao.png';
import Button from '../../../components/button';
import { pathname2Query } from '../../../utils/RouterRes';
import Request from '../../../utils/Request';

function Materia(props) {
    const [materia, setMateria] = useState({});

    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();
    const lastLocation = useLastLocation();

    const url_params = pathname2Query(location.pathname, match.url);

    useEffect(() => {
        async function getMateria() {
            const request = new Request();

            const req_materia = request.setRequest('materias', 'listar', { id: url_params.id });
            const result = await request.execute();

            if (result[req_materia] && result[req_materia].dados && result[req_materia].dados.id) {
                const { dados } = result[req_materia];
                const { diario_data } = dados;
                const diario_data_brasil = moment(diario_data, 'YYYY-MM-DD').format('DD/MM/YYYY');
                setMateria({ ...dados, diario_data: diario_data_brasil });
            } else {
                history.push('/');
            }
        }

        if (url_params && url_params.id) {
            getMateria(url_params.id).then();
        } else {
            history.push('/');
        }
    }, [url_params.id]);

    return (
        <Container>
            <Header>
                <Informacoes>
                    <Logo src={brasao} />
                    <Dados>
                        <Title>Diário Oficial</Title>
                        <Text>
                            {`Publicado em: ${materia.diario_data}`}
                        </Text>
                        <Text>
                            {`Edição: ${materia.diario_edicao}`}
                        </Text>
                    </Dados>
                </Informacoes>
                <Buttons>
                    <Button
                        kind="actions"
                        icon="icon-voltar-materia"
                        onClick={() => {
                            if (lastLocation && lastLocation.pathname) {
                                history.push(lastLocation.pathname);
                            } else {
                                history.push('/');
                            }
                        }}
                    >
                        VOLTAR
                    </Button>
                    <Button
                        kind="actions"
                        icon="icon-materias"
                        onClick={() => {
                            window.open(materia.diario_url);
                        }}
                    >
                        VERSÃO CERTIFICADA
                    </Button>
                    <Button
                        kind="actions"
                        icon="icon-imprimir"
                        onClick={() => {
                            window.print();
                        }}
                    >
                        IMPRIMIR
                    </Button>
                </Buttons>
            </Header>
            <TituloMateria>{materia.titulo}</TituloMateria>
            <ConteudoMateria dangerouslySetInnerHTML={{ __html: materia.texto }} />
            <Alert>Este conteúdo não substitui o publicado na versão certificada.</Alert>
        </Container>
    );
}

export default Materia;
