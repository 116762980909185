import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const Orgao = styled('div')`
    width: 100%;
    min-height: 60px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    background: ${colors.white};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;

    &:last-child {
        margin-bottom: 0;
    }
`;

export const Titulo = styled('div')`
    width: 100%;
    display: flex;
    cursor: pointer;
    height: 60px;
    align-items: center;
    padding: 13px 20px;
`;

export const Descricao = styled('span')`
    color: ${colors.primary_blue};
    font-weight: bold;
    font-size: 16px;
    line-height: 18px;
    text-transform: uppercase;
`;

export const SubOrgaos = styled('ul')`
    width: 100%;
    padding: 0 0 20px 70px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    ${(props) => props.isChildren && css`
        padding: 0 40px;
    `}

    &:last-child {
        margin-bottom: 0;
    }

    &:first-letter {
        margin-bottom: 0;
    }
`;

export const SubOrgao = styled('li')`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Materia = styled(Link)`
    width: 100%;
    font-weight: bold;
    font-size: ${parse(16)};
    padding: 0 20px;
    line-height: 28px;
    color: ${colors.primary_blue};

    ${(props) => !isNaN(props.level) && props.level === 0 && css`padding-left: 0;`}
`;

export const SubOrgaoDescricao = styled('h4')`
    font-weight: bold;
    font-size: ${parse(16)};
    line-height: 18px;
    margin-top: 16px;
    color: ${colors.grey_chumbo_table};
    text-transform: uppercase;

    & + ${Materia} {
        margin-top: 10px;
    }
`;
