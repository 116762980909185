import React, {
    createContext, useEffect, useRef, useState,
} from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import {
    AutenticidadeFoto,
    AutenticidadeSubText,
    BgLayer,
    CalendarContainer,
    Container,
    ContainerAutenticidade,
    ContIframe,
    DiarioDescricao,
    FieldSet,
    Frame,
    Main,
    Title,
    TitleBox,
    SemDiario,
    Topo,
    Verification, SemDiarioText,
} from './styles';
import Input from '../../../components/form-components/input';
import Button from '../../../components/button';
import autenticidade_foto from '../../../assets/img/autenticidade.png';
import Calendar from '../../../components/calendar';
import Request from '../../../utils/Request';
import TableLoader from '../../../components/tableloader';
import { error } from '../../../components/alerts';

export const HomeContext = createContext();

function Home(props) {
    const [loadingCalendar, setLoadingCalendar] = useState(true);
    const [loadingFrame, setLoadingFrame] = useState(true);
    const [diasDiario, setDiasDiario] = useState([]);
    const [ultimoDiario, setUltimoDiario] = useState(null);
    const [daySelected, setDaySelected] = useState(new Date());
    const [diarioSelecionado, setDiarioSelecionado] = useState(null);
    const [icp, setICP] = useState('');
    const history = useHistory();
    const homeRef = useRef(0);

    async function handleMonthChange(data) {
        setLoadingCalendar(true);
        const data_format = moment(data).format('YYYY-MM');

        const request = new Request();
        const req_dias = request.setRequest('diarios', 'diariosPorDia', { data: data_format });

        const result = await request.execute();
        setLoadingCalendar(false);

        setDiasDiario(result[req_dias]);
    }

    async function getDiario(data) {
        setLoadingFrame(true);
        const data_format = moment(data).format('YYYY-MM-DD');

        const request = new Request();
        const req_diario = request.setRequest('diarios', 'listar', { data: data_format, situacao: 2, publicado: true });

        const result = await request.execute();

        if (result && result[req_diario] && result[req_diario].dados && result[req_diario].dados.length > 0) {
            const { dados } = result[req_diario];
            const [diario] = dados;

            diario.data_descricao = moment(data, 'YYYY-MM-DD').format('DD MMMM [de] YYYY');
            setDiarioSelecionado(diario);
            setLoadingFrame(false);
        } else {
            setDiarioSelecionado(null);
            setLoadingFrame(false);
        }
    }

    async function handleICPCheck() {
        const request = new Request();
        const req_icp = request.setRequest('diarios', 'verificarICP', { icp });

        const result = await request.execute();

        if (result && result[req_icp] && result[req_icp].id) {
            history.push(`/diario/lista/id=${result[req_icp].id}`);
        } else {
            error(`Diário com o código '${icp}' não autenticado!`);
            setICP('');
        }
    }

    useEffect(() => {
        if (homeRef.current !== 0) {
            getDiario(daySelected);
        }

        homeRef.current += 1;
    }, [daySelected]);

    useEffect(() => {
        async function getUltimo() {
            const request = new Request();
            const req_ultimo = request.setRequest('diarios', 'ultimoDiario', {});

            const result = await request.execute();

            if (result && result[req_ultimo] && result[req_ultimo].id) {
                result[req_ultimo].data_object = moment(result[req_ultimo].data).toDate();
                result[req_ultimo].data_descricao = moment(result[req_ultimo].data, 'YYYY-MM-DD').format('DD MMMM [de] YYYY');
                handleMonthChange(result[req_ultimo].data_object);
                setUltimoDiario(result[req_ultimo]);
                setDiarioSelecionado(result[req_ultimo]);
                setLoadingFrame(false);
                setLoadingCalendar(false);
            } else {
                setLoadingFrame(false);
                setLoadingCalendar(false);
            }
        }

        getUltimo().then();
    }, []);

    function renderDiario() {
        if (loadingFrame) {
            return (
                <BgLayer>
                    <TableLoader
                        loading
                        left="calc(50% - (59px / 2))"
                    />
                </BgLayer>
            );
        } else if (!loadingFrame && !diarioSelecionado) {
            const dia = moment(daySelected).format('DD/MM/YYYY');

            return (
                <BgLayer>
                    <SemDiario>
                        <SemDiarioText>
                            {`Nenhum diário publicado no dia ${dia}`}
                        </SemDiarioText>
                    </SemDiario>
                </BgLayer>
            );
        }

        return (
            <BgLayer>
                <Topo>
                    <Title>{diarioSelecionado.ultimo && Number(diarioSelecionado.ultimo) === 1 ? 'Última edição' : `Diário ${diarioSelecionado.numero_ano}`}</Title>
                    <DiarioDescricao>
                        <p>{`Edição ${diarioSelecionado.numero}`}</p>
                        <p>{diarioSelecionado.data_descricao}</p>
                    </DiarioDescricao>
                </Topo>
                <ContIframe>
                    <Frame src={diarioSelecionado.diario_url} />
                </ContIframe>
                <FieldSet>
                    <Button
                        icon="icon-lista"
                        fontSize={13}
                        flex={1}
                        iconSize={13}
                        to={`/diario/lista/id=${diarioSelecionado.id}`}
                        button_margin="0 10px 0 0"
                    >
                        VER EM LISTA
                    </Button>
                    <Button
                        button_margin="0 10px 0 0"
                        icon="icon-sumario"
                        fontSize={13}
                        iconSize={13}
                        to={`/diario/sumario/id=${diarioSelecionado.id}`}
                        flex={1}
                    >
                        VER EM SUMÁRIO
                    </Button>
                    <Button
                        icon="icon-save"
                        fontSize={13}
                        iconSize={13}
                        flex={1}
                        onClick={() => {
                            window.open(`${diarioSelecionado.diario_url}?download=true`);
                        }}
                    >
                        DIÁRIO COMPLETO
                    </Button>
                </FieldSet>
            </BgLayer>
        );

    }

    return (
        <Main>
            <Container>
                {renderDiario()}
                <Verification>
                    <ContainerAutenticidade>
                        <TitleBox>Verificar autencidade</TitleBox>
                        <AutenticidadeSubText>
                            Digite o código de certificação que aparece na matéria publicada para
                            verificar sua autenticidade.
                        </AutenticidadeSubText>
                        <Input
                            placeholder="Digitar código"
                            kind="circular"
                            margin="0 0 10px 0"
                            value={icp}
                            onChange={(e) => {
                                const { value } = e.target;
                                setICP(value);
                            }}
                        />
                        <Button
                            width="100%"
                            text="center"
                            padding="14px 95px"
                            onClick={handleICPCheck}
                        >
                            Verificar
                        </Button>
                    </ContainerAutenticidade>
                    <AutenticidadeFoto src={autenticidade_foto} />
                </Verification>
                <CalendarContainer>
                    <HomeContext.Provider value={{
                        handleMonthChange, loadingCalendar, diasDiario, ultimoDiario, setDaySelected,
                    }}
                    >
                        <Calendar />
                    </HomeContext.Provider>
                </CalendarContainer>
            </Container>
        </Main>
    );
}

export default Home;
