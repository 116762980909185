import styled from 'styled-components';
import CheckboxGroup from 'react-checkbox-group';

export const Container = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;    
    position: relative;
    padding: 0 15px;
`;

export const GroupContainer = styled('div')`
    display: flex;
    flex-wrap: wrap;
    height: auto;
    width: 100%;
`;

export const StyledGroup = styled(CheckboxGroup)`
    display: flex;
    height: auto;
    width: auto;
`;

export const InputLabel = styled('label')`
    align-items: center;
    display: flex;
    font-weight: bold;
    margin: ${(props) => props.margin};
`;
