import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { pathname2Query } from '../../../utils/RouterRes';
import { Row } from '../../../app/global-styles';
import ControlledInput from '../../../components/form-components/controlled-input';
import Button from '../../../components/button';
import Resizer from '../../../components/resizer';
import PageContainer from '../../../components/page-container';
import Request from '../../../utils/Request';
import { parser } from '../../../utils/Select';
import { error, loading, success } from '../../../components/alerts';
import { IframeContainer } from './styles';
import { URL_API } from '../../../data/constants';

function Materia(props) {
    const location = useLocation();
    const match = useRouteMatch();
    const history = useHistory();

    const [materia, setMateria] = useState({});
    const [aprovada, setAprovada] = useState(false);
    const [updatePreview, setUpdatePreview] = useState(true);

    useEffect(() => {
        if (!updatePreview) {
            setUpdatePreview(true);
        }
    }, [updatePreview]);


    const initialValues = materia && Object.keys(materia).length > 0 ? materia : {
        titulo: '',
        orgao: {},
        diario: {},
        texto: '',
        tipo: {},
        colunas: '2',
    };

    const url_params = pathname2Query(location.pathname, match.url);

    async function getMateria(id) {
        const request = new Request();

        const req_materia = request.setRequest('materias', 'listar', { id });

        const result = await request.execute();

        if (result[req_materia] && result[req_materia].dados && result[req_materia].dados.id) {
            const { dados } = result[req_materia];

            const materia_to_edit = {};
            const preview_data = {};
            materia_to_edit.titulo = dados.titulo;
            preview_data.titulo = dados.titulo;

            materia_to_edit.texto = dados.texto;
            preview_data.conteudo = dados.texto;

            materia_to_edit.id = dados.id;

            if (dados.layout) {
                try {
                    dados.layout = JSON.parse(dados.layout);
                } catch (e) {
                    // Não está no formato json
                    dados.layout = { colunas: '2' };
                }
            } else {
                dados.layout = { colunas: '2' };
            }

            if (dados.situacao && !isNaN(Number(dados.situacao)) && Number(dados.situacao) === 2) {
                setAprovada(true);
            }

            materia_to_edit.colunas = dados.layout.colunas;
            preview_data.colunas = dados.layout.colunas;

            if (dados.orgao_id && !isNaN(dados.orgao_id)) {
                preview_data.orgao = dados.orgao_descricao;
                materia_to_edit.orgao = {
                    value: dados.orgao_id,
                    label: dados.orgao_descricao,
                };
            }

            if (dados.tipo_materia_id && !isNaN(dados.tipo_materia_id)) {
                materia_to_edit.tipo = {
                    value: dados.tipo_materia_id,
                    label: dados.tipo_materia_descricao,
                };
            }

            if (dados.diario_id && !isNaN(dados.diario_id)) {
                materia_to_edit.diario = {
                    value: dados.diario_id,
                    label: dados.diario_numero_descricao,
                };
            }

            setMateria(materia_to_edit);
        }
    }

    useEffect(() => {
        if (url_params.id && !isNaN(url_params.id)) {
            getMateria(url_params.id)
                .then();
        } else {
            setMateria(null);
            setAprovada(false);
        }
    }, [url_params.id]);

    async function handleSubmit(values, state) {
        const request = new Request();
        const materia_to_save = {};

        materia_to_save.titulo = values.titulo;
        materia_to_save.texto = values.texto;
        materia_to_save.layout = {
            colunas: values.colunas,
        };

        if (values.orgao.value) {
            materia_to_save.orgao = values.orgao.value;
        }

        if (values.tipo.value) {
            materia_to_save.tipo = values.tipo.value;
        }

        if (values.diario.value) {
            materia_to_save.diario = values.diario.value;
        }

        if (values.id) {
            materia_to_save.id = values.id;
        }

        materia_to_save.situacao = 1;

        const loadToast = loading('Salvando matéria');

        try {
            const req_materia = request.setRequest(
                'materias',
                'salvar',
                { materia: materia_to_save },
            );

            const result = await request.execute();

            if (result[req_materia] === true) {
                loadToast();
                success(`Matéria ${materia_to_save.titulo} alterada com sucesso`);
                setUpdatePreview(false);
            } else if (Number(result[req_materia]) > 0) {
                loadToast();
                success(`Matéria ${materia_to_save.titulo} adicionada com sucesso`);
                history.push(`${match.url}/id=${result[req_materia]}`);
                setUpdatePreview(false);
            } else if (typeof result[req_materia] === 'string') {
                loadToast();
                error(result[req_materia]);
            } else {
                loadToast();
                error('Não foi possível salvar matéria!');
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar matéria');
        }
        state.setSubmitting(false);
    }

    async function getOrgaosSelect(search) {
        const request = new Request();

        const req_orgaos = request.setRequest('orgaos', 'listarTodos', { search });
        const result = await request.execute();

        const dados = result && result[req_orgaos] ? result[req_orgaos].dados : [];

        return parser('orgao_descricao_com_pai', 'id', dados);
    }

    async function getTiposMateriaSelect(search) {
        const request = new Request();

        const req_tipos_materia = request.setRequest('tiposMateria', 'listarTodos', { search });
        const result = await request.execute();

        const dados = result && result[req_tipos_materia] ? result[req_tipos_materia].dados : [];

        return parser('descricao', 'id', dados);
    }

    async function getDiariosSelect(search) {
        const request = new Request();

        const req_diarios = request.setRequest('diarios', 'listar', { search, situacao: 1 });
        const result = await request.execute();

        const dados = result && result[req_diarios] ? result[req_diarios].dados : [];

        return parser('numero_descricao', 'id', dados);
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row
                    height="auto"
                    spaceBetween
                >
                    <Field
                        component={ControlledInput}
                        name="titulo"
                        type="text"
                        required
                        disabled={aprovada}
                        label="Título da matéria"
                        placeholder="Título da matéria"
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="orgao"
                        type_select="async"
                        required
                        type="select"
                        label="Órgão responsável"
                        disabled={aprovada}
                        placeholder="Órgão responsável"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getOrgaosSelect}
                    />
                </Row>
                <Row
                    height="auto"
                    spaceBetween
                >
                    <Field
                        component={ControlledInput}
                        name="diario"
                        required
                        type_select="async"
                        disabled={aprovada}
                        type="select"
                        label="Diário"
                        placeholder="Diário"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getDiariosSelect}
                    />
                    <Field
                        component={ControlledInput}
                        name="tipo"
                        type_select="async"
                        type="select"
                        label="Tipo da matéria"
                        disabled={aprovada}
                        placeholder="Tipo da matéria"
                        required
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getTiposMateriaSelect}
                    />
                </Row>
                <Row>
                    <Field
                        component={ControlledInput}
                        name="texto"
                        label="Conteúdo da matéria"
                        disabled={aprovada}
                        required
                        type="editor"
                    />
                </Row>
                <Row>
                    <Field
                        component={ControlledInput}
                        required
                        options={[
                            { label: '1 coluna', value: '1' },
                            { label: '2 colunas', value: '2' },
                        ]}
                        size={4}
                        type="input-radio"
                        disabled={aprovada}
                        name="colunas"
                        label="Quantidade de colunas"
                    />
                </Row>
                <Row
                    contentEnd
                    padding="0 15px"
                >
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting || aprovada}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    return (
        <Resizer
            left={45}
            right={55}
            minLeft={500}
            minRight={500}
        >
            <PageContainer>
                <IframeContainer>
                    {updatePreview && materia && materia.id && <iframe src={`${URL_API}/materias?acao=materiaPrevisualizacao&materia=${materia.id}`} />}
                </IframeContainer>
            </PageContainer>
            <PageContainer title="Matéria">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object()
                        .shape({
                            texto: yup.string()
                                .valueRequired('Conteúdo da matéria é obrigatório!'),
                            titulo: yup.string()
                                .required('Título da matéria é obrigatório!'),
                            orgao: yup.object()
                                .validSelect('Órgão é obrigatório!'),
                            tipo: yup.object()
                                .validSelect('Tipo é obrigatório!'),
                            diario: yup.object()
                                .validSelect('Diário é obrigatório!'),
                            colunas: yup.string()
                                .required('Qunatidade de colunas é obrigatório!'),
                        })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        </Resizer>
    );
}

export default Materia;
