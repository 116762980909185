import React from 'react';
import PropTypes from 'prop-types';
import { Container, Texto, Bolinha } from './styles';
import { colors } from '../../configs/theme';

Situacao.propTypes = {
    color: PropTypes.oneOf(['green', 'grey', 'red']),
    message: PropTypes.string.isRequired,
};

function Situacao({ color, message, ...props }) {
    function situacao_color(prop_color) {
        switch (prop_color) {
            case 'red':
                return colors.red_error_message;
            case 'green':
                return colors.primary_green;
            case 'grey':
            default:
                return colors.grey_placeholder;
        }
    }

    return (
        <Container {...props}>
            <Bolinha color={situacao_color(color)} />
            <Texto color={situacao_color(color)}>{message}</Texto>
        </Container>
    );
}

export default Situacao;
