import React from 'react';
import PropTypes from 'prop-types';
import {
    PaginationContainer, Button, SelectContainer, LabelSelect, StyledSelect,
} from './style';
import { makePagination } from './util';
import Icon from '../icon';
import { colors } from '../../configs/theme';
import { Row } from '../../app/global-styles';

function Pagination({
    pages, page, onPageChange, previousText, nextText, onPageSizeChange, pageSize, pageSizeOptions, content, showPageJump, ...props
}) {
    pages = pages || 1;

    function changePage(this_page) {
        if (this_page === page) {
            return;
        }

        onPageChange(this_page);
    }

    return (
        <Row spaceBetween={content === null}>
            <PaginationContainer>
                <Button
                    onClick={() => {
                        if (page === 1) return;
                        changePage(page - 1);
                    }}
                    previous
                    className="icon-seta-esquerda"
                    disabled={page === 1}
                >
                    {previousText}
                </Button>
                {makePagination({
                    pageCount: pages,
                    selected: page,
                    Component: Button,
                    changePage,
                })}
                <Button
                    onClick={() => {
                        if (page === pages) return;
                        changePage(page + 1);
                    }}
                    next
                    className="icon-seta-direita"
                    disabled={page === pages}
                >
                    {nextText}
                </Button>
            </PaginationContainer>
            <Row width="auto">
                {showPageJump && (
                    <SelectContainer margin="0 10px 0 0">
                        <LabelSelect>
                        Ir para:
                        </LabelSelect>
                        <StyledSelect>
                            <select
                                name="page"
                                id="page"
                                value={page}
                                onChange={(e) => {
                                    const { value } = e.target.options[e.target.selectedIndex];
                                    changePage(Number(value));
                                }}
                            >
                                {
                                    Array(pages)
                                        .fill(0)
                                        .map((this_page, index) => (
                                            <option
                                                key={index}
                                                value={index + 1}
                                            >
                                                {index + 1}
                                            </option>
                                        ))
                                }
                            </select>
                            <Icon
                                absolute
                                color={colors.grey_chumbo_pagination}
                                className="icon-sort-arrow"
                                size="10px"
                                top="calc(50% - (10px / 2))"
                                right="5px"
                                pointer={false}
                            />
                        </StyledSelect>
                    </SelectContainer>
                )}
                <SelectContainer>
                    <LabelSelect>
                    Mostrar:
                    </LabelSelect>
                    <StyledSelect>
                        <select
                            name="page-size"
                            id="page-sze"
                            onChange={(e) => {
                                const { value } = e.target.options[e.target.selectedIndex];
                                if (onPageSizeChange) onPageSizeChange(Number(value), page);
                            }}
                            value={pageSize}
                        >
                            {pageSizeOptions.map((size) => (
                                <option
                                    key={size}
                                    value={size}
                                >
                                    {size}
                                </option>
                            ))}
                        </select>
                        <Icon
                            absolute
                            color={colors.grey_chumbo_pagination}
                            className="icon-sort-arrow"
                            size="10px"
                            top="calc(50% - (10px / 2))"
                            right="5px"
                            pointer={false}
                        />
                    </StyledSelect>
                </SelectContainer>
            </Row>
        </Row>
    );
}

Pagination.propTypes = {
    pages: PropTypes.number.isRequired,
    page: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    previousText: PropTypes.string,
    nextText: PropTypes.string,
    content: PropTypes.string,
    pageSize: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    pageSizeOptions: PropTypes.array,
    onPageSizeChange: PropTypes.func,
    showPageJump: PropTypes.bool,
};

Pagination.defaultProps = {
    previousText: '',
    nextText: '',
    showPageJump: true,
    content: null,
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
};

export default Pagination;
