import React from 'react';
import PropTypes from 'prop-types';
import {
    Container, GroupContainer, InputLabel,
} from './styles';
import { Label, LabelMessage, Required } from '../styles';
import ErrorMessage from '../error-message';

function RadioGroup({
    name,
    id,
    width,
    label,
    error_message,
    required,
    options,
    onChange,
    value,
    disabled,
    margin,
    ...props
}) {
    return (
        <Container width={width}>
            {label && (
                <Label>
                    <LabelMessage>
                        {label}
                        {required && <Required>*</Required>}
                    </LabelMessage>
                </Label>
            )}
            <GroupContainer>
                <>
                    {options.map((option) => (
                        <InputLabel
                            key={option.value}
                            checked={!isNaN(Number(value)) && Number(value) === Number(option.value)}
                            show_error={!!error_message}
                            margin={margin}
                        >
                            <input
                                type="radio"
                                disabled={disabled}
                                value={option.value}
                                onChange={(event) => onChange && onChange(event)}
                                checked={value === option.value}
                            />
                            {option.label}
                        </InputLabel>
                    ))}
                </>
            </GroupContainer>
            {error_message && <ErrorMessage error_message={error_message} />}
        </Container>
    );
}

export default RadioGroup;

RadioGroup.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    error_message: PropTypes.string,
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    width: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    options: PropTypes.array.isRequired,
    onChange: PropTypes.func,
    margin: PropTypes.string,
};

RadioGroup.defaultProps = {
    name: null,
    id: null,
    value: '',
    width: '100%',
    label: null,
    error_message: null,
    required: false,
    margin: '0 15px 10px 0',
    onChange: null,
    disabled: false,
};
