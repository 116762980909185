import React from 'react';
import { Container, ContainerOutside } from './styles';
import Icon from '../../icon';
import { colors } from '../../../configs/theme';

Card.propTypes = {

};

function Card({
    card,
    isDragging,
    isGroupedOver,
    provided,
    style,
    isClone,
    index,
    level,
    children,
    setHiddenId,
    hidden,
    ...props
}) {

    function getStyle(provided_prop, style_prop) {
        if (!style) {
            return provided_prop.draggableProps.style;
        }

        return {
            ...provided_prop.draggableProps.style,
            ...style_prop,
        };
    }

    return (
        <ContainerOutside
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getStyle(provided, style)}
            data-is-dragging={isDragging}
            isDragging={isDragging}
            isGroupedOver={isGroupedOver}
            isClone={isClone}
            ref={provided.innerRef}
            data-testid={card.id}
            data-index={index}
        >
            <Container
                level={level}
            >
                {card.title}
                {card && card.children && card.children.length > 0 && (
                    <Icon
                        onClick={() => {
                            setHiddenId(card.id);
                        }}
                        className="icon-voltar"
                        size="20px"
                        absolute
                        rotate={hidden ? '90' : '-90'}
                        right="20px"
                        top="calc(50% - (20px / 2))"
                        color={colors.black_table}
                    />
                )}
            </Container>
            {children}
        </ContainerOutside>
    );
}

export default Card;
