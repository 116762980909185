import React, { useState, useRef, useContext } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import Table from '../../../components/table';
import PageContainer from '../../../components/page-container';
import Resizer from '../../../components/resizer';
import { Row } from '../../../app/global-styles';
import ControlledInput from '../../../components/form-components/controlled-input';
import Button from '../../../components/button';
import { error, loading, success } from '../../../components/alerts';
import Request, { getLimit } from '../../../utils/Request';
import Icon from '../../../components/icon';
import { colors } from '../../../configs/theme';
import confirmAlert from '../../../components/confirm-alert';
import { AppContext } from '../../../app/app-container';

function TiposDiario(props) {
    const { hasPermissao } = useContext(AppContext);
    const [tipo_diario, setTipoDiario] = useState({});
    const tableRef = useRef();
    const initialValues = tipo_diario && Object.keys(tipo_diario).length > 0 ? tipo_diario : {
        descricao: '',
    };

    async function handleSubmit(values, state) {
        const request = new Request();
        const tipo_diario_to_save = {};

        tipo_diario_to_save.descricao = values.descricao;


        if (values.id) {
            tipo_diario_to_save.id = values.id;
        }

        const loadToast = loading('Salvando tipo de diário');

        try {
            const req_orgao = request.setRequest('tiposDiario', 'salvar', { tipo_diario: tipo_diario_to_save });

            const result = await request.execute();

            if (result[req_orgao] === true) {
                loadToast();
                success(`Tipo de diário ${tipo_diario_to_save.descricao} alterado com sucesso`);
                tableRef.current.fireFetchData();
            } else if (Number(result[req_orgao]) > 0) {
                loadToast();
                success(`Tipo de diário ${tipo_diario_to_save.descricao} adicionado com sucesso`);
                tableRef.current.fireFetchData();
            } else {
                loadToast();
                error('Não foi possível salvar tipo de diário!');
            }

            setTipoDiario({});
            state.resetForm();
        } catch (e) {
            loadToast();
            console.log(e);
            error('Falha ao salvar tipo de diários');
        }
    }

    function handleTipoDiarioClick(data, column) {
        if (column.id !== 'options') {
            const { original } = data;

            const tipo_diario_to_edit = {
                descricao: original.descricao,
                id: original.id,
            };

            setTipoDiario(tipo_diario_to_edit);
        }
    }

    function desativarTipoDiario({ original }) {
        if (original && original.id) {
            const desativar = async (tipo) => {
                const request = new Request();

                const { id } = tipo;

                const req_desativar = request.setRequest('tiposDiario', 'desativar', { id });
                const result = await request.execute();

                if (result[req_desativar] === true) {
                    success('Tipo de diário excluído com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir tipo de diário!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir tipo de diário ?',
                subtitle: `Ao confirmar o tipo de diário ${original.descricao} será excluído!`,
                onConfirm() {
                    desativar(original).then();
                },
            });

        } else {
            error('Tipo de diário inválido!');
        }
    }


    async function getTiposDiario({ page, limit: table_limit, ...table_props }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_tipos_diario = request.setRequest('tiposDiario', 'listar', { limit });
        const result = await request.execute();

        return result[req_tipos_diario];
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row
                    height="auto"
                    spaceBetween
                >
                    <Field
                        component={ControlledInput}
                        name="descricao"
                        placeholder="Tipo de Diário"
                        label="Tipo de Diário"
                        type="text"
                        required
                        size={4}
                    />
                </Row>
                <Row
                    contentEnd
                    padding="0 15px"
                >
                    {Object.entries(tipo_diario).length > 0 && (
                        <Button
                            type="reset"
                            kind="cancel"
                            margin="0 10px 0 0"
                            onClick={() => {
                                setTipoDiario({});
                                formState.resetForm();
                            }}
                        >
                            Cancelar
                        </Button>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    function renderTable() {
        return (
            <Table
                headers={[
                    {
                        name: 'Tipo de Diário',
                        accessor: 'descricao',
                    },
                ]}
                data_function={getTiposDiario}
                tableRef={tableRef}
                options={hasPermissao('D_TIPOS_DIARIO') ? (props) => (
                    <Icon
                        hidden
                        size="16px"
                        hover={colors.red_error_message}
                        color={colors.black_table}
                        className="icon-trash"
                        onClick={() => desativarTipoDiario(props)}
                    />
                ) : null}
                clickHandler={handleTipoDiarioClick}
            />
        );
    }

    function renderForm() {
        return (
            <PageContainer title="Tipos de Diários">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        descricao: yup.string().required('Descrição do tipo é obrigatório!'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }

    if (hasPermissao('R_TIPOS_DIARIO') && !hasPermissao('M_TIPOS_DIARIO')) {
        return (
            <PageContainer
                padding
                scroll
            >
                {renderTable()}
            </PageContainer>
        );
    } else if (!hasPermissao('R_TIPOS_DIARIO') && hasPermissao('M_TIPOS_DIARIO')) {
        return (
            <PageContainer
                padding
                scroll
            >
                {renderForm()}
            </PageContainer>
        );
    } else if (hasPermissao('M_TIPOS_DIARIO') && hasPermissao('R_TIPOS_DIARIO')) {
        return (
            <Resizer
                left={45}
                right={55}
                minLeft={500}
                minRight={500}
            >
                {renderTable()}
                {renderForm()}
            </Resizer>
        );
    }

}

export default TiposDiario;
