export function getSituacaoDiario(situacao) {
    switch (situacao) {
        case 2:
            return 'green';
        case 1:
        default:
            return 'grey';

    }
}
