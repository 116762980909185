import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    StyledPopup, Header, Content, Title, PopupGlobalStyle, Close, Footer, LoadContainer,
} from './styles';
import Icon from '../icon';
import { colors } from '../../configs/theme';
import Loader from '../tableloader';

Popup.propTypes = {
    closeOnDocumentClick: PropTypes.bool,
    modal: PropTypes.bool,
    lockScroll: PropTypes.bool,
    closeOnEscape: PropTypes.bool,
    contentOverflow: PropTypes.bool,
    title: PropTypes.string,
    buttons: PropTypes.func,
    height: PropTypes.string,
    onClose: PropTypes.func,
    onOpen: PropTypes.func,
    trigger: PropTypes.func,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]),
};

Popup.defaultProps = {
    closeOnDocumentClick: true,
    height: null,
    modal: true,
    buttons: null,
    trigger: null,
    contentOverflow: false,
    lockScroll: true,
    closeOnEscape: true,
    title: '',
};


function Popup({
    title, children, contentOverflow, buttons, height, onClose, onOpen, ...props
}) {
    const [loading_content, setLoadingContent] = useState(!!onOpen);
    return (
        <StyledPopup
            {...props}
            onClose={onClose}
            onOpen={() => {
                if (onOpen) {
                    onOpen(setLoadingContent);
                }
            }}
        >
            {
                (close) => (
                    <>
                        <PopupGlobalStyle />
                        <Header>
                            <Title>{title}</Title>
                            <Close onClick={close}>
                                <Icon
                                    className="icon-close"
                                    size="14px"
                                    width="13px"
                                    color={colors.primary_green}
                                />
                            </Close>
                        </Header>
                        <Content
                            height={height}
                            contentOverflow={contentOverflow}
                        >
                            {loading_content
                                ? (
                                    <LoadContainer>
                                        <Loader
                                            loading={loading_content}
                                            left="calc(50% - (75px / 2))"
                                        />
                                    </LoadContainer>
                                ) : children}
                        </Content>
                        {buttons && (
                            <Footer>
                                {buttons(close)}
                            </Footer>
                        )}
                    </>
                )
            }
        </StyledPopup>
    );
}

export default Popup;
