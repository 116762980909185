import styled from 'styled-components';
import { parse } from '../../utils/FontSize';

export const StyledHeader = styled('header')`
    width: 100%;
    min-height: 220px;
    background: #232A2E;
`;

export const Container = styled('div')`
    width: 100%;
    max-width: 1148px;
    margin: 0 auto;
    padding: 48px 0 50px 0;
    display: flex;
    flex-direction: column;
`;

export const Title = styled('h1')`
    width: 100%;
    font-weight: normal;
    font-size: ${parse(40)};
    line-height: 46px;
    letter-spacing: -0.025em;
    color: #ffffff;
    margin-bottom: 30px;

    b {
        font-weight: bold;
    }
`;

export const Filtros = styled('fieldset')`
    width: 100%;
    height: 45px;
    display: flex;
`;
