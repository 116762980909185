import axios from 'axios';

// Servidor para as requisições
let defaultUrl = '';
// Condições para ser executadas dependendo do retorno
const default_condition = {};

// Membros privados
const _ = new WeakMap();

/**
 * Classe de gerenciamento de requisições
 */
export default class Request {

    /**
     * Request constructor
     *
     * @param {object} [set_root_params] Setar requisições já na Instancia.
     * @param {object<string, function>} [condition]
     * Condições para serem executadas dependendo do retorno.<br>
     * Se o valor retornado for igual ao indice então executa o callback.
     */
    constructor(set_root_params, condition) {
        _.set(this, {
            condition: condition || {},
            ls_requests: {},
            request_time_out: 10000,
            root_params: {
                multi_request: true,
                params: null,
            },
            cancel_token: null,
            url: null,
        });

        // Se parametros para o root forem passados
        if (set_root_params && typeof set_root_params === 'object') {
            Object.keys(set_root_params)
                .forEach((i) => {
                    const v = set_root_params[i];

                    this.setRootParam(i, v);
                });
        }
    }

    /**
     * Parametros para ser enviados no raiz dos parametros
     * @param indice
     * @param valor
     */
    setRootParam(indice, valor) {
        if (indice !== 'long_polling' && indice !== 'multi_request' && indice !== 'params') {
            _.get(this).root_params[indice] = valor;
        } else {
            console.log('Parametros reservados para a requisição');
        }
    }

    /**
     * Setar uma requisição
     *
     * A resposta do servidor deve ser devolvida em um object com a chave da ação seguida do retorno
     * Ex: {"0-h9cc2f": 1, "1-uik2fn": [{id: 1, nome: "exemplo"}, {id: 2, nome: "exemplo 2"}...]}
     *
     * @param {string} api Uma a api Ex: "usuarios"
     * (A api deve estar cadastrada no manifest indicando qual classe chamar)
     * @param {string} acao A ação que será chamada (Referente ao metodo da api)
     * @param {object|null} [params] <p>
     *     Um objeto com o indice e o valor que serão passados
     *     Não pode conter o index "acao" ele será escrito pelo metodo
     *     </p>
     * @param {Function} [callback] O callback que será chamado após o retorno para a ação definida
     */
    setRequest(api, acao, params, callback) {
        params = params || {};
        callback = (callback !== null) ? callback : null;

        if (typeof params !== 'object' || (typeof params === 'object' && Array.isArray(params))) {
            throw new Error('"param" deve ser um object');
        }

        params.acao = `${api}/${acao}`;

        const key = `${Object.keys(_.get(this).ls_requests).length}-${Math.random()
            .toString(36)
            .substring(7)}`;

        _.get(this).ls_requests[key] = {
            params,
            callback,
        };

        return key;
    }

    /**
     * Seta tempo limite da requisição
     * @param {int} [time_out=10000] Tempo limite da requisição
     */
    setTimeOut(time_out) {
        time_out = (time_out || time_out === 0) ? time_out : 10000;

        _.get(this).request_time_out = time_out;
    }

    /**
     * Executa todas as requisições definidas, chamando o callback setado para cada uma
     * @param {object} [settings] Um objeto para concatenar com as configurações do ajax
     *
     * @param settings
     * @return {Promise<AxiosResponse<any>|never>|void}
     */
    execute(settings) {
        const url = _.get(this).url || defaultUrl || null;

        if (url === null) {
            throw new Error('O Servidor para as requisições não foi definido.');
        }

        if (Object.keys(_.get(this).ls_requests).length === 0) {
            console.log('Nenhuma requisição foi informada.');

            return;
        }

        const params = {};
        const calls = {};

        Object.keys(_.get(this).ls_requests)
            .forEach((i) => {
                const v = _.get(this).ls_requests[i];

                params[i] = v.params;
                calls[i] = v.callback;
            });

        _.get(this).root_params.params = JSON.stringify(params);

        const request_data = new URLSearchParams();
        Object.keys(_.get(this).root_params)
            .forEach((key) => {
                const v = _.get(this).root_params[key];
                request_data.append(key, v);
            });

        _.get(this).cancel_token = axios.CancelToken.source();

        const default_settings = {
            url: `${url}`,
            method: 'post',
            data: request_data,
            timeout: _.get(this).request_time_out,
            withCredentials: true,
            cancelToken: _.get(this).cancel_token.token,
        };

        // Se alguma configuração especial for passada então concatena
        if (settings) {
            Object.assign(default_settings, settings);
        }

        return axios(default_settings)
            .then((response) => {
                if (typeof response.data === 'object') {
                    Object.keys(response.data)
                        .forEach((i) => {
                            const v = response.data[i];

                            if (default_condition[v]) {
                                default_condition[v]();
                            } else if (_.get(this).condition[v]) {
                                _.get(this).condition[v]();
                            } else if (calls[i]) calls[i](v);
                        });
                }

                return response.data || {};
            })
            .then((response) => {
                this.limpar();

                return response;
            })
            .catch((e) => {
                if (!axios.isCancel(e)) {
                    console.log(e);
                }
            });
    }

    /**
     * Mata a requisição que estiver acontecendo
     */
    killRequest() {
        _.get(this)
            .cancel_token
            .cancel('Operação cancelada');
    }

    /**
     * Limpar todos requests setadas
     */
    limpar() {
        _.get(this).ls_requests = {};
    }

    /**
     * Define a URL padrão das requisições
     *
     * @param {string} url
     */
    static setDefaultUrl(url) {
        defaultUrl = url;
    }

    /**
     * Define a URL das requisições
     *
     * @param {string} url
     */
    setUrl(url) {
        _.get(this).url = url;
    }

    /**
     * Setar uma condição a ser aplicado em um retorno em qualquer requisição
     * @param {string} condition O retorna que irá acionar o callback
     * @param {function} callback O callback a ser executado quando o retorno bater
     */
    static setDefaultCondition(condition, callback) {
        default_condition[condition] = callback;
    }
}

/**
 * Executar requisição em uma unica linha
 *
 * A resposta do servidor deve ser devolvida em um object com a chave da ação seguida do retorno
 * Ex: {"0-h9cc2f": 1, "1-uik2fn": [{id: 1, nome: "exemplo"}, {id: 2, nome: "exemplo 2"}...]}
 *
 * @param {string} api Uma a api Ex: "usuarios"
 * (A api deve estar cadastrada no manifest indicando qual classe chamar)
 * @param {string} acao A ação que será chamada (Referente ao metodo da api)
 * @param {object|null} [params] <p>
 *     Um objeto com o indice e o valor que serão passados
 *     Não pode conter o index "acao" ele será escrito pelo metodo
 *     </p>
 * @param {Function} [callback] O callback que será chamado após o retorno para a ação definida
 */
export function request(api, acao, params, callback) {
    const req = new Request();
    req.setRequest(api, acao, params, callback);
    return req.execute();
}

/**
 * Retorna o limit para o padrão do backend
 *
 * 1 0,15
 * 2 15,15
 *
 * @param {number} page página atual da tabela
 * @param {number} limit quantidade total de registros a serem exibidos
 */
export function getLimit(page, limit) {
    const start = (page * limit) - limit;

    return `${start}, ${limit}`;
}
