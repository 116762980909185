import styled from 'styled-components';
import { colors } from '../../configs/theme';

export const StyledError = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 100%;
    color: ${colors.color_baltic_sea_approx};

    a:hover {
        text-decoration: underline;
    }

    h2 {
        font-weight: bold;
        font-size: 42px;
        margin-bottom: 30px;

        span {
            font-size: 20px;
        }
    }
`;
