import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { StyledTable, HiddenOptions } from './styles';
import Pagination from '../pagination';
import TableLoader from '../tableloader';
import Icon from '../icon';
import { colors } from '../../configs/theme';

Table.propTypes = {
    headers: PropTypes.array.isRequired,
    data_function: PropTypes.func,
    submenuOption: PropTypes.func,
    clickHandler: PropTypes.func,
    onCheckboxChange: PropTypes.func,
    subComponent: PropTypes.string,
    noDataText: PropTypes.string,
    checkbox: PropTypes.bool,
    scrollable: PropTypes.bool,
    subComponentHeaders: PropTypes.array,
    pageSizeOptions: PropTypes.array,
    subComponentOptions: PropTypes.func,
    tableRef: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.shape({ current: PropTypes.any }),
    ]),
    defaultPageSize: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]),
    options: (props, propName, componentName) => {
        if (props[propName] !== null && props.checkbox === true) {
            return new Error(`${propName} can't exists when prop checkbox is defined`);
        }

        return null;
    },
};

Table.defaultProps = {
    submenuOption: null,
    clickHandler: null,
    options: null,
    subComponent: '',
    noDataText: 'Nenhum registro encontrado',
    tableRef: null,
    onCheckboxChange: null,
    checkbox: false,
    defaultPageSize: 15,
    scrollable: false,
    subComponentHeaders: [],
    pageSizeOptions: [5, 10, 15, 20, 25, 50, 100],
    subComponentOptions: null,
};

const initialState = {
    loading: true,
    data: [],
    page: 1,
    pages: 0,
    showPageJump: false,
};

function Table({
    headers,
    data_function,
    submenuOption,
    options,
    subComponent,
    onCheckboxChange,
    scrollable,
    checkbox,
    subComponentHeaders,
    subComponentOptions,
    clickHandler,
    tableRef,
    ...attrs
}) {
    const [tableState, setTableState] = useState({ ...initialState });
    const [selecteds, setSelecteds] = useState({});

    const Checkbox = {
        getSelectedsArray() {
            return Object.keys(selecteds)
                .filter((key) => selecteds[key] === true);
        },
        getSelecteds() {
            return Object.fromEntries(Object.entries(selecteds)
                .filter(([key, value]) => value === true));
        },
        toggleSelected(id) {
            setSelecteds({
                ...selecteds,
                [id]: !selecteds[id],
            });
        },
        setSelecteds,
    };

    useEffect(() => {
        if (onCheckboxChange) {
            onCheckboxChange(Checkbox.getSelecteds(), Checkbox.getSelectedsArray(), Checkbox.setSelecteds);
        }
    }, [selecteds]);


    if (checkbox) {
        attrs.Checkbox = Checkbox;
    }

    const handleData = async (data_props) => {
        if (data_function) {
            setTableState({
                ...tableState,
                loading: true,
            });

            const { pageSize = 15, page } = data_props;

            const request_data = await data_function({
                page: page !== 0 ? page : 1,
                limit: pageSize,
            });
            if (request_data && request_data.dados) {
                const { total, dados } = request_data;

                const pages = Math.ceil(Number(total) / Number(pageSize));

                setTableState({
                    ...tableState,
                    loading: false,
                    data: dados,
                    pages,
                    showPagination: pages > 1,
                    showPageJump: pages > 1,
                    page: page !== 0 ? page : 1,
                });
            } else {
                setTableState({
                    ...tableState,
                    data: [],
                    pages: 0,
                    page: 1,
                    loading: false,
                });
            }
        } else {
            setTableState({
                ...tableState,
                loading: false,
            });
        }
    };

    const columns = headers.map((header) => ({
        Header: header.name,
        accessor: header.accessor,
        sortable: false,
        resizable: false,
        ...header,
    }));

    const options_column = (options_function, column_size = 35) => ({
        Header: (props) => <></>,
        Cell: (props) => (
            <HiddenOptions>
                {options_function(props)}
            </HiddenOptions>
        ),
        sortable: false,
        resizable: false,
        id: 'options',
        maxWidth: column_size,
    });

    if (options && !checkbox) {
        columns.unshift(options_column(options));
    }

    if (checkbox) {
        columns.unshift({
            Header: (props) => {
                // eslint-disable-next-line no-underscore-dangle
                const isAllChecked = props.data.every((item) => selecteds && selecteds[item._original.id]);
                const selectAll = () => {
                    const ids = {};

                    // eslint-disable-next-line no-underscore-dangle,no-return-assign
                    props.data.forEach((item) => ids[item._original.id] = true);

                    const length_selected = selecteds ? Object.keys(selecteds).length : 0;
                    const length_id = ids ? Object.keys(ids).length : 0;
                    if ((length_selected === 0)
                        || length_selected !== length_id) {
                        setSelecteds(ids);
                    } else {
                        setSelecteds({});
                    }
                };
                if (props.data.length > 0) {
                    return (
                        <input
                            type="checkbox"
                            checked={isAllChecked}
                            onChange={selectAll}
                        />
                    );
                }

                return (<></>);
            },
            acessor: '',
            resizable: false,
            sortable: false,
            maxWidth: 35,
            id: 'tb_checkbox',
            Cell: (props) => (
                <input
                    type="checkbox"
                    onChange={(e) => {
                        const { checked } = e.target;
                        setSelecteds({
                            ...selecteds,
                            [props.original.id]: checked,
                        });
                    }}
                    checked={selecteds && selecteds[props.original.id]}
                />
            ),
        });
    }


    const expanderColumn = {
        expander: true,
        Header: () => <></>,
        width: 45,
        Expander: ({ isExpanded, original, ...rest }) => {
            if (original[subComponent] && original[subComponent].length > 0) {
                return (
                    <div>
                        {isExpanded
                            ? (
                                <Icon
                                    className="icon-voltar"
                                    size="15px"
                                    rotate={90}
                                    color={colors.grey_chumbo}
                                />
                            )
                            : (
                                <Icon
                                    className="icon-voltar"
                                    size="15px"
                                    rotate={-90}
                                    color={colors.grey_chumbo}
                                />
                            )}
                    </div>
                );
            } else {
                return (<></>);
            }
        },
        style: {
            cursor: 'pointer',
            fontSize: 15,
            padding: '0',
            textAlign: 'center',
            userSelect: 'none',
        },
    };

    function subTableComponent(data, subcomponentIndex, viewIndex, level = 0) {
        const subTableAttrs = {
            data,
            headers: [],
            minRows: 0,
            hideHeader: true,
            showPagination: false,
            padding_children: level + 2,
            nesting: true,
            viewIndex,
            clickHandler,
        };

        if (subComponentHeaders.length > 0) {
            subTableAttrs.headers = subComponentHeaders;
            const hasExpander = subTableAttrs.headers.some((header) => header.expander);
            if (!hasExpander) {
                subTableAttrs.headers.push(expanderColumn);
            }
        } else {
            subTableAttrs.headers = columns;
        }

        if (subComponentOptions) {
            const hasOptions = subTableAttrs.headers.some((header) => header.id === 'options');

            if (!hasOptions) {
                subTableAttrs.headers.unshift(options_column(subComponentOptions, 35 + (20 * (level + 1))));
            } else {
                subTableAttrs.headers.forEach((item, index) => {
                    if (item.id === 'options') {
                        subTableAttrs.headers[index] = options_column(subComponentOptions, 35 + (20 * (level + 1)));
                    }
                });
            }
        } else {
            if (options && columns[0] && columns[0].id === 'options') {
                columns[0] = options_column(options, 35 + (20 * (level + 1)));
            }

            subTableAttrs.headers = columns;
        }

        data.find((sub, idx) => {
            if (sub[subcomponentIndex] && sub[subcomponentIndex].length > 0) {
                // eslint-disable-next-line max-len
                subTableAttrs.SubComponent = ({ original, viewIndex: viewIndexSub }) => subTableComponent(original[subcomponentIndex], subcomponentIndex, viewIndexSub + level + 1, level + 1);
                return true;
            }
            return false;
        });

        return (
            <Table
                key={viewIndex + level}
                {...subTableAttrs}
            />
        );
    }

    if (subComponent !== '') {
        columns.push(expanderColumn);

        // eslint-disable-next-line max-len
        attrs.SubComponent = ({ original, viewIndex, ...rest }) => subTableComponent(original[subComponent], subComponent, viewIndex, 0);
    }

    return (
        <StyledTable
            data={tableState.data}
            columns={columns}
            manual
            previousText=""
            scrollable={scrollable}
            nextText=""
            page={tableState.page}
            showPageJump={tableState.showPageJump}
            pages={tableState.pages}
            loading={tableState.loading}
            ref={tableRef}
            TbodyComponent={(tableStates) => {
                if (scrollable) {
                    return (
                        <Scrollbars
                            autoHeight
                            autoHeightMax="100%"
                            renderTrackHorizontal={(props) => (
                                <div
                                    {...props}
                                    className="track-horizontal"
                                    style={{ display: 'none' }}
                                />
                            )}
                            renderThumbHorizontal={(props) => (
                                <div
                                    {...props}
                                    className="thumb-horizontal"
                                    style={{ display: 'none' }}
                                />
                            )}
                        >
                            <div className="rt-tbody">
                                {tableStates.children}
                            </div>
                        </Scrollbars>
                    );
                }
                return (
                    <div className="rt-tbody">
                        {tableStates.children}
                    </div>
                );
            }}
            onFetchData={handleData}
            PaginationComponent={(props) => (<Pagination {...props} />)}
            LoadingComponent={(props) => (<TableLoader {...props} />)}
            getTdProps={(state, rowInfo, column, instance) => ({
                onClick: (e, handleOriginal) => {
                    if (handleOriginal) {
                        handleOriginal();
                    }

                    if (column.expander) {
                        return;
                    }

                    if (clickHandler) {
                        clickHandler(rowInfo, column, state, instance);
                    }
                },
            })}
            {...attrs}
        />
    );
}

export default Table;
