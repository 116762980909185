import styled from 'styled-components';
import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const Container = styled('div')`
    width: auto;
    height: 100%;
    display: flex;
    align-items: center;
`;

export const Bolinha = styled('div')`
    width: 11px;
    height: 11px;
    border-radius: 50%;
    margin-right: 6px;
    background-color: ${(props) => (props.color ? props.color : colors.grey_placeholder)};
`;

export const Texto = styled('div')`
    color: ${(props) => (props.color ? props.color : colors.grey_placeholder)};
    font-size: ${parse(12)};
    text-transform: uppercase;
    font-weight: bold;
`;
