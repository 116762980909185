import React, {
    useEffect, useRef, useState, useContext,
} from 'react';
import { Field, Formik } from 'formik';
import * as yup from 'yup';
import { error, loading, success } from '../../../components/alerts';
import Table from '../../../components/table';
import Resizer from '../../../components/resizer';
import PageContainer from '../../../components/page-container';
import ControlledInput from '../../../components/form-components/controlled-input';
import Request, { getLimit } from '../../../utils/Request';
import { parser } from '../../../utils/Select';
import { Row } from '../../../app/global-styles';
import Button from '../../../components/button';
import Icon from '../../../components/icon';
import { colors } from '../../../configs/theme';
import confirmAlert from '../../../components/confirm-alert';
import Reordable, { listOrdered, listParse } from '../../../components/reordable';
import Popup from '../../../components/popup';
import { AppContext } from '../../../app/app-container';

function TiposMateria(props) {
    const { hasPermissao } = useContext(AppContext);
    const tableRef = useRef();
    const [tipo_materia, setTipoMateria] = useState({});
    const [reorableList, setReorableList] = useState();

    const initialValues = tipo_materia && Object.keys(tipo_materia).length > 0 ? tipo_materia : {
        descricao: '',
        superior: {},
    };

    async function handleSubmit(values, state) {
        const request = new Request();
        const tipo_materia_to_save = {};

        tipo_materia_to_save.descricao = values.descricao;

        if (values.superior.value) {
            tipo_materia_to_save.pai = values.superior.value;
        }

        if (values.id) {
            tipo_materia_to_save.id = values.id;
        }

        const loadToast = loading('Salvando tipo de matéria');

        try {
            const req_tipo_materia = request.setRequest(
                'tiposMateria',
                'salvar',
                { tipo_materia: tipo_materia_to_save },
            );

            const result = await request.execute();

            if (result[req_tipo_materia] === true) {
                loadToast();
                success(`Tipo de matéria ${tipo_materia_to_save.descricao} alterada com sucesso`);
                tableRef.current.fireFetchData();
            } else if (Number(result[req_tipo_materia]) > 0) {
                loadToast();
                success(`Tipo de matéria ${tipo_materia_to_save.descricao} adicionada com sucesso`);
                tableRef.current.fireFetchData();
            } else {
                loadToast();
                error('Não foi possível salvar tipo de matéria!');
            }

            setTipoMateria({});
            state.resetForm();
        } catch (e) {
            loadToast();
            error('Falha ao salvar tipo de matéria');
        }

    }

    async function getTiposMateria({ page, limit: table_limit = 0, ...table_props }) {
        const request = new Request();

        const limit = table_limit !== 0 ? getLimit(page, table_limit) : null;

        const req_tipos_materia = request.setRequest('tiposMateria', 'listar', { limit });
        const result = await request.execute();

        return result[req_tipos_materia];
    }

    async function getTiposMateriaSelect(search) {
        const request = new Request();

        const id = tipo_materia.id || null;
        const req_tipos_materia = request.setRequest('tiposMateria', 'listarTodos', { search, id });
        const result = await request.execute();

        const { dados } = result[req_tipos_materia] || [];

        return parser('descricao', 'id', dados);
    }

    useEffect(() => {
        async function start() {
            getTiposMateriaSelect().then();
        }

        start();
    }, []);

    function handleTipoMateriaClick(data) {
        const { original } = data;

        const tipo_materia_to_edit = {
            descricao: original.descricao,
            id: original.id,
        };

        if (original.pai !== null) {
            tipo_materia_to_edit.superior = {
                value: original.pai,
                label: original.pai_descricao,
            };
        } else {
            tipo_materia_to_edit.superior = {};
        }

        setTipoMateria(tipo_materia_to_edit);
    }

    async function desativarTipoMateria({ original }) {
        if (original && original.id) {
            const desativar = async () => {
                const request = new Request();

                const { id, pai } = original;

                const req_desativar = request.setRequest('tiposMateria', 'desativar', { id, pai });
                const result = await request.execute();

                if (result[req_desativar]) {
                    success('Tipo de matéria excluído com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir tipo de matéria!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir tipo de matéria?',
                subtitle: `Ao confirmar o tipo de matéria ${original.descricao} e todos os seus subordinados serão excluídos!`,
                onConfirm() {
                    desativar(original).then();
                },
            });

        } else {
            error('Tipo de matéria inválido!');
        }
    }

    async function reordenar() {
        if (reorableList) {
            const request = new Request();
            const ordered_list = listOrdered(reorableList, 'subordinados');

            const req_reordenar = request.setRequest('tiposMateria', 'reordenar', { lista: ordered_list });

            const result = await request.execute();

            tableRef.current.fireFetchData();
            return result && result[req_reordenar] ? result[req_reordenar] : null;
        }
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row
                    height="auto"
                    spaceBetween
                >
                    <Field
                        component={ControlledInput}
                        name="descricao"
                        placeholder="Tipo de matéria"
                        label="Tipo de matéria"
                        type="text"
                        required
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="superior"
                        isClearable
                        type_select="async"
                        type="select"
                        label="Tipo de matéria superior"
                        placeholder="Tipo de matéria superior"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getTiposMateriaSelect}
                    />
                </Row>
                <Row
                    contentEnd
                    padding="0 15px"
                >
                    {Object.entries(tipo_materia).length > 0 && (
                        <Button
                            type="reset"
                            kind="cancel"
                            margin="0 10px 0 0"
                            onClick={() => {
                                setTipoMateria({});
                                formState.resetForm();
                            }}
                        >
                            Cancelar
                        </Button>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    function renderTable() {
        return (
            <>
                {
                    hasPermissao('RE_TIPOS_MATERIA') && (
                        <Popup
                            height="600px"
                            title="Reordenar Órgãos"
                            trigger={(close) => (
                                <Row margin="0 0 10px 0">
                                    <Button kind="reorder">Reordenar</Button>
                                </Row>
                            )}
                            contentOverflow
                            onOpen={(setLoading) => {
                                async function requestTiposMateria() {
                                    const tipos_materia = await getTiposMateria({}).then();

                                    setReorableList(listParse(tipos_materia.dados, 'id', 'descricao', 'subordinados'));
                                    setLoading(false);
                                }

                                requestTiposMateria().then();
                            }}
                            buttons={(close) => (
                                <>
                                    <Button
                                        kind="cancel"
                                        onClick={close}
                                        margin="0 10px 0 0"
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        kind="save"
                                        onClick={() => {
                                            async function reordenarOrgaos() {
                                                const reordenados = await reordenar();

                                                if (reordenados && typeof reordenados !== 'string') {
                                                    success('Órgãos reordenados com sucesso!');
                                                    close();
                                                } else {
                                                    error('Não foi possível reordenar órgãos');
                                                }
                                            }

                                            reordenarOrgaos().then();
                                        }}
                                    >
                                        Salvar
                                    </Button>
                                </>
                            )}
                        >
                            <Reordable
                                list={reorableList}
                                setList={setReorableList}
                            />
                        </Popup>
                    )
                }
                <Table
                    headers={[
                        {
                            name: 'Tipo de matéria',
                            accessor: 'descricao',
                        },
                    ]}
                    subComponent="subordinados"
                    subComponentHeaders={[
                        {
                            name: 'Tipo de matéria',
                            accessor: 'descricao',
                        },
                    ]}
                    data_function={getTiposMateria}
                    tableRef={tableRef}
                    options={hasPermissao('D_TIPOS_MATERIA') ? (table_props) => (
                        <Icon
                            hidden
                            size="16px"
                            hover={colors.red_error_message}
                            color={colors.black_table}
                            className="icon-trash"
                            onClick={() => desativarTipoMateria(table_props)}
                        />
                    ) : null}
                    clickHandler={handleTipoMateriaClick}
                />
            </>
        );
    }

    function renderForm() {
        return (
            <PageContainer title="Tipo de Matéria">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        descricao: yup.string().required('Descrição do tipo é obrigatório!'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }

    if (hasPermissao('R_TIPOS_MATERIA') && !hasPermissao('M_TIPOS_MATERIA')) {
        return (
            <PageContainer
                padding
                scroll
            >
                {renderTable()}
            </PageContainer>
        );
    } else if (!hasPermissao('R_TIPOS_MATERIA') && hasPermissao('M_TIPOS_MATERIA')) {
        return (
            <PageContainer
                padding
                scroll
            >
                {renderForm()}
            </PageContainer>
        );
    } else if (hasPermissao('M_TIPOS_MATERIA') && hasPermissao('R_TIPOS_MATERIA')) {
        return (
            <Resizer
                left={45}
                right={55}
                minLeft={500}
                minRight={500}
            >
                {renderTable()}
                {renderForm()}
            </Resizer>
        );
    }
}

export default TiposMateria;
