import styled, { css } from 'styled-components';
import MaskedInput from 'react-text-mask';
import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

export const StyledMaskedInput = styled(MaskedInput)`
    width: ${(props) => (props.padding_icon ? `calc(${props.width} - ${props.padding_icon})` : props.width)};
    height: 100%;
    background: ${(props) => props.background};
    color: ${(props) => (props.color ? props.color : colors.grey_chumbo)};
    text-align: left;
    font-size: ${parse(16)};
    
    &::placeholder {
        opacity: 1;
        color: ${colors.grey_placeholder};
    }
    
    &:focus {
        border-color: ${colors.primary_blue};
    }
    
    ${(props) => props.padding && css`padding: ${props.padding};`}
    ${(props) => props.radius && css`border-radius: ${props.radius};`}
    ${(props) => props.border && css`border: ${props.border};`}
    ${(props) => props.shadow && css`box-shadow: 0 4px 2px -4px rgba(0,0,0,0.4);`}
    
    ${(props) => props.error_message && css`
        border-color: ${colors.red_error_message};
       
        &::placeholder {
            color: ${colors.red_error_message};
        }
    `}
    
    ${(props) => props.select && css`
        padding: 10px ${props.isClearable ? '75px' : '45px'} 10px 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `}
`;
