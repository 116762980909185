import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Orgao, Titulo, Descricao, SubOrgao, SubOrgaoDescricao, Materia, SubOrgaos,
} from './styles';
import Icon from '../icon';
import { colors } from '../../configs/theme';


SumarioToggle.propTypes = {
    orgaos: PropTypes.object.isRequired,
};

function SumarioToggle({ orgaos }) {
    const [toggle, setToggle] = useState(false);


    function renderOrgaos(orgao_render, level = 0) {
        return (
            <SubOrgao key={orgao_render.id}>
                <SubOrgaoDescricao>{orgao_render.descricao}</SubOrgaoDescricao>
                {orgao_render.materias && orgao_render.materias.map((materia) => (
                    <Materia
                        key={materia.id}
                        to={`/materia/id=${materia.id}`}
                    >
                        {materia.titulo}
                    </Materia>
                ))}
                {orgao_render.subordinados && orgao_render.subordinados.length > 0 && orgao_render.subordinados.map((orgao_subordinado) => (
                    <SubOrgaos
                        isChildren
                        key={`${orgao_render.id}-${level}`}
                    >
                        {renderOrgaos(orgao_subordinado, level + 1)}
                    </SubOrgaos>
                ))}
            </SubOrgao>
        );
    }

    function renderHidden(orgao_render) {
        return (
            <SubOrgaos
                isChildren={false}
                key={orgao_render.id}
            >
                {orgao_render.materias.length > 0 && orgao_render.materias.map((materia) => (
                    <Materia
                        level={0}
                        key={materia.id}
                        to={`/materia/id=${materia.id}`}
                    >
                        {materia.titulo}
                    </Materia>
                ))}
                {orgao_render.subordinados && orgao_render.subordinados.length > 0 && orgao_render.subordinados.map((orgao_subordinado) => renderOrgaos(orgao_subordinado))}
            </SubOrgaos>
        );
    }

    return (
        <Orgao>
            <Titulo onClick={() => setToggle(!toggle)}>
                <Icon
                    color={colors.grey_chumbo_table}
                    size="18px"
                    className={toggle ? 'icon-minus' : 'icon-plus'}
                    marginRight="15px"
                />
                <Descricao>{orgaos.descricao}</Descricao>
            </Titulo>
            {toggle && renderHidden(orgaos)}
        </Orgao>
    );
}

export default SumarioToggle;
