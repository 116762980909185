import styled from 'styled-components';

export const IframeContainer = styled('div')`
    width: calc(100% - 40px);
    position: absolute;
    height: calc(100% - 40px);

    iframe {
        width: 100%;
        height: 100%;
    }
`;
