import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Field, Formik } from 'formik';
import moment from 'moment';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import Request from '../../utils/Request';
import { parser } from '../../utils/Select';
import { Row } from '../../app/global-styles';
import ControlledInput from '../form-components/controlled-input';
import Button from '../button';
import {
    Container, Filtros, StyledHeader, Title,
} from './styles';
import { CidadaoContext } from '../../app/cidadao/index';

moment.locale('pt-br');

HeaderCidadao.propTypes = {
    instituicao: PropTypes.string,
};

function HeaderCidadao({ instituicao }) {
    const { searchParams, setSearchParams } = useContext(CidadaoContext);
    const location = useLocation();
    const history = useHistory();

    const initialValues = { ...searchParams };

    async function getTiposMateriaSelect(search) {
        const request = new Request();

        const req_tipos_materia = request.setRequest('tiposMateria', 'listar', { search });
        const result = await request.execute();

        const dados = result && result[req_tipos_materia] ? result[req_tipos_materia].dados : [];

        return parser('descricao', 'id', dados);
    }

    async function getOrgaosSelect(search) {
        const request = new Request();

        const req_orgaos = request.setRequest('orgaos', 'listarTodos', { search });
        const result = await request.execute();

        const dados = result && result[req_orgaos] ? result[req_orgaos].dados : [];

        return parser('orgao_descricao_com_pai', 'id', dados);
    }

    function makeFormFiltros(formikState) {
        return (
            <Row
                as="form"
                onSubmit={formikState.handleSubmit}
            >
                <Field
                    name="filtrar"
                    type="text"
                    placeholder="Filtrar"
                    size={1}
                    flex={2}
                    show_filtros={false}
                    onChange={(e) => {
                        const { value } = e.target;
                        setSearchParams({ ...searchParams, filtrar: value });
                    }}
                    icon="icon-lupa"
                    type_button="submit"
                    kind="filtros"
                    component={ControlledInput}
                />
                <Field
                    component={ControlledInput}
                    name="orgao"
                    margin="0 0 0 10px"
                    type_select="async"
                    type="select"
                    placeholder="Órgão"
                    size={1}
                    flex={1}
                    onChange={(value) => {
                        setSearchParams({ ...searchParams, orgao: value });
                    }}
                    styled="gradient"
                    defaultOptions
                    kind="filtros"
                    cacheOptions
                    loadOptions={getOrgaosSelect}
                />
                <Field
                    component={ControlledInput}
                    name="tipo"
                    margin="0 0 0 10px"
                    padding="0"
                    type_select="async"
                    styled="gradient"
                    type="select"
                    placeholder="Tipo"
                    size={1}
                    flex={1}
                    onChange={(value) => {
                        setSearchParams({ ...searchParams, tipo: value });
                    }}
                    kind="filtros"
                    defaultOptions
                    cacheOptions
                    loadOptions={getTiposMateriaSelect}
                />
                <Field
                    component={ControlledInput}
                    name="periodo"
                    margin="0 0 0 10px"
                    minWidth={210}
                    kind="filtros"
                    styled="gradient"
                    onChange={(dates) => {
                        setSearchParams({ ...searchParams, periodo: dates });
                    }}
                    type="date-range"
                    placeholder="Data do diário"
                    size={1}
                />
                <Button
                    margin="0 0 0 10px"
                    type="submit"
                >
                    Filtrar
                </Button>
            </Row>
        );
    }


    return (
        <StyledHeader>
            <Container>
                <Title>
                    <Link to="/">
                        <b>Diário Oficial</b>
                        {` de ${instituicao}`}
                    </Link>
                </Title>
                <Filtros>
                    <Formik
                        initialValues={initialValues}
                        enableReinitialize
                        onSubmit={(values) => {
                            const forceRedirect = location.pathname.startsWith('/buscar');

                            if (!forceRedirect) {
                                history.push('/buscar');
                            }
                        }}
                    >
                        {makeFormFiltros}
                    </Formik>
                </Filtros>
            </Container>
        </StyledHeader>
    );
}

export default HeaderCidadao;
