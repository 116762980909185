import styled, { css } from 'styled-components';
import { colors } from '../../../configs/theme';

export const DropDown = styled('div')`
    width: ${(props) => (props.width)};
    position: relative;
    ${(props) => props.margin && css`margin: ${props.margin};`}
    ${(props) => props.minWidth && css`min-width: ${props.minWidth}px;`}
    ${(props) => props.flex && css`flex: ${props.flex};`}
`;

export const Blanket = styled('div')`
    bottom: 0;
    left: 0;
    top: 0;
    right: 0;
    position: fixed;
    z-index: 1;
`;

// const shadow = 'hsla(218, 50%, 10%, 0.1)';

export const Menu = styled('div')`
    background-color: ${colors.white};
    border-radius: 4px;
    padding: 10px;
    position: absolute;
    left: ${(props) => (props.kind && props.kind === 'filtros' ? null : '15px')};
    width: ${(props) => (props.kind && props.kind === 'filtros' ? '100%' : 'calc(100% - 30px)')};
    z-index: 2;
`;
