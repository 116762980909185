import queryString from 'query-string';

/**
 * Extrai o restante dos parâmetros da url que não foram utilizados pelo Router
 *
 * @param {string} pathname Acessivel pelo Router em "this.props.location.pathname"
 * @param {string} url Acessivel pelo Router em "this.props.match.url"
 * @return {object} Objeto com as strings separadas
 */
export const pathname2Query = (pathname, url) => {
    const params = extractParams(pathname, url);

    Object.keys(params)
        .forEach((value, index) => {
            if (params[value] === null) params[value] = true;
        });

    return params;
};

/**
 * Extrai os parâmetros da string como objeto mesmo que estejam vazios
 * @param {string} pathname Acessível em location.pathname
 * @param {string} url Acessível em mathRouter.url
 * @returns {object} Objeto dos parãmetros
 */
export const extractParams = (pathname, url) => {
    let params = extractParamsAsString(pathname, url);
    params = params.replace(/[/]+/g, '&');
    params = queryString.parse(params, { arrayFormat: 'bracket' });

    return params;
};

/**
 * Extarai os parâmetros da string como string mesmo que estejam vazios
 * @param {string} pathname Acessível em location.pathname
 * @param {string} url Acessível em mathRouter.url
 * @returns {string} String dos parâmetros
 */
export const extractParamsAsString = (pathname, url) => {
    let params = pathname.replace(url, '');
    params = params.replace(/^[/]+/, '');
    params = params.replace(/[/]+$/, '');

    return params;
};

/**
 * Transforma um objeto de parâmetros em querystring ignorando os valores inexistentes
 * @param {object} params Parâmetros para serem transformados em querystring
 * @returns {string}
 */
export function paramsToQuery(params) {
    const formated_params = Object.entries(params).filter(([key, value]) => value && value !== '').map(([key, value]) => `${key}=${value}`);
    return formated_params.join('/');
}
