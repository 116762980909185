import styled from 'styled-components';
import { colors } from '../../../configs/theme';

export const Container = styled('div')`
    width: 100%;
    height: 100%;
    position: relative;
    padding: 0 15px;
    display: flex;
    margin-bottom: 30px;
    flex-direction: column;                                   
`;

export const EditorContainer = styled('div')`
    border: 2px solid ${(props) => (props.error ? colors.red_required : colors.white)};
    z-index: 0;
    width: 100%;
    height: auto;
    box-shadow: 0 4px 2px -4px rgba(0,0,0,0.4);
`;
