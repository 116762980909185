import styled from 'styled-components';
import { colors } from '../../../configs/theme';
import { parse } from '../../../utils/FontSize';

export const Message = styled('span')`
    background-color: ${colors.red_error_message};
    margin-right: 14px;
    border-radius: 3px;
    transition: .1s;
    font-weight: bold;
    font-size: ${parse(12)};
    height: 25px;
    position: relative;
    color: ${colors.white};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 5px 0;
    width: 0;
    
    &::after {
        content: " ";
        width: 0;
        position: absolute;
        right: -8px;
        height: 0;
        top: calc(50% - (12.75px / 2));
        border-top: 6.375px solid transparent;
        border-bottom: 6.375px solid transparent;
        border-left: 14.72px solid #fd4747;
    }
`;

export const Container = styled('div')`
    position: absolute;    
    right: ${(props) => (props.select ? '45px' : '10px')};
    top: calc(50% - (25px / 2));
    align-items: center;
    display: flex;
    
    &:hover {
        ${Message}{
            width: auto;
            padding: 5px 9px;
            overflow: unset;
        }
    }
`;

export const Indicator = styled('div')`
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${colors.red_error_message};
    border-radius: 5px;
`;
