import styled, { css } from 'styled-components';
import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

const getContainerStyle = (kind) => {
    if (kind === 'circular') {
        return css`
            padding: 0;
            margin: 0;
      `;
    }
    if (kind === 'filtros') {
        return css`
            padding: 0;
            margin: 0;
      `;
    }
};

export const Container = styled('div')`
    width: ${(props) => props.width};
    height: ${(props) => props.height};
    background: ${(props) => props.background};
    font-size: ${(props) => parse(props.fontSize)};
    display: flex;
    padding: 0 15px;
    margin: 0 0 30px;
    flex-direction: column;

    ${(props) => props.radius && css`border-radius: ${props.radius};`}
    ${(props) => props.kind && getContainerStyle(props.kind)}
    ${(props) => props.margin && css`margin: ${props.margin};`}
    ${(props) => props.shadow && css`box-shadow: 0 4px 2px -4px rgba(0,0,0,0.4);`}
    ${(props) => props.minWidth && css`min-width: ${props.minWidth}px;`}
    ${(props) => props.flex && css`flex: ${props.flex};`}
    ${(props) => props.disabled && css`cursor: not-allowed;`}
`;

const getInputStyle = (kind) => {
    if (kind === 'circular') {
        return css`
            box-shadow: unset;
            border: 1px solid ${colors.grey_input_cidadao};
            padding: 0;
            text-align: center;
            font-size: ${parse(18)};
        `;
    }
};
const getInputStyled = (style) => {
    if (style === 'gradient') {
        return css`
            background: linear-gradient(180deg, #FFFFFF 0%, #E3E6E9 100%);
            border: unset;

            &:hover {
                background: linear-gradient(180deg, #FFFFFF 0%, #c2c5c8 100%);
            }
        `;
    }
};

export const StyledInput = styled('input')`
    width: ${(props) => (props.padding_icon ? `calc(${props.width} - ${props.padding_icon})` : props.width)};
    height: 100%;
    background: ${(props) => props.background};
    color: ${(props) => (props.color ? props.color : colors.grey_chumbo)};
    text-align: left;
    font-size: ${parse(16)};

    &::placeholder {
        opacity: 1;
        color: ${colors.grey_placeholder};
    }

    &:focus {
        border-color: ${colors.primary_blue};
    }

    ${(props) => props.padding && css`padding: ${props.padding};`}
    ${(props) => props.radius && css`border-radius: ${props.radius};`}
    ${(props) => props.border && css`border: ${props.border};`}
    ${(props) => props.shadow && css`box-shadow: 0 4px 2px -4px rgba(0,0,0,0.4);`}
    ${(props) => props.flex && css`flex: ${props.flex};`}

    ${(props) => props.error_message && css`
        border-color: ${colors.red_error_message};

        &::placeholder {
            color: ${colors.red_error_message};
        }
    `}

    ${(props) => props.select && css`
        padding: 10px ${props.isClearable ? '75px' : '45px'} 10px 10px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    `}

    ${(props) => props.kind && getInputStyle(props.kind)}
    ${(props) => props.styled && getInputStyled(props.styled)}
    ${(props) => props.disabled && css`cursor: not-allowed;`}
    ${(props) => props.disabled && css`
        border-color: ${colors.grey_text};
        color: ${colors.grey_placeholder};
        background-color: ${colors.grey_lines_table};
    `}
`;

export const IconContainer = styled('div')`
    width: ${(props) => props.padding_icon};
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    ${(props) => props.hasIcon && css`
        background: ${colors.background_buttons};
        border-radius: 5px 5px 0 0;
    `}
`;

export const Label = styled('label')`
    width: auto;
    height: auto;
`;

export const LabelMessage = styled('div')`
    color: ${colors.black_table};
    font-size: ${parse(14)};
    font-weight: bold;
    margin-bottom: 5px;
`;

export const Required = styled('span')`
    color: ${colors.red_required};
    font-size: ${parse(16)};
    line-height: 0;
    font-weight: bold;
`;
