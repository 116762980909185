import React, {
    createContext, useContext, useState, useEffect, useRef,
} from 'react';
import {
    Buttons, Category, Container, Data, FieldSet, ItemLista, Lista, Paginacao, Resumo, Title,
} from './styles';
import Button from '../button';
import { colors } from '../../configs/theme';
import Pagination from '../pagination';
import TableLoader from '../tableloader';

export const ListaMateriasContext = createContext();

export function useListaMateriasContext() {
    const context = useContext(ListaMateriasContext);

    if (!context) {
        throw new Error(
            'ListaMaterias compound components cannot be rendered outside the ListaMaterias component',
        );
    }

    return context;
}

export function useListaMaterias({
    materias = [],
    loading = false,
    showPagination = false,
    pageSize = 5,
    showPageJump = false,
    page = 1,
    getMaterias,
    ...props
} = {}) {
    const [listaState, setListaState] = useState({
        materias,
        loading,
        pageSize,
        showPagination,
        showPageJump,
        page,
        getMaterias,
        ...props,
    });

    const stateRef = useRef(0);

    function handleData(data_function, is_search = false) {
        async function handleDataAsyc() {
            if (data_function) {
                setListaState({
                    ...listaState,
                    loading: true,
                });

                const request_data = await data_function({
                    page: is_search ? 1 : listaState.page,
                    limit: listaState.pageSize,
                });

                if (request_data && request_data.dados) {
                    const { total, dados } = request_data;

                    const pages = Math.ceil(Number(total) / Number(listaState.pageSize));

                    const new_lista_state = {
                        ...listaState,
                        loading: false,
                        materias: dados,
                        pages,
                        showPagination: pages > 1 || (listaState.pageSize > pageSize && pages === 1),
                        showPageJump: pages > 1,
                    };

                    if (is_search) {
                        new_lista_state.page = 1;
                    } else if (pages <= listaState.page) {
                        new_lista_state.page = pages;
                    }

                    setListaState(new_lista_state);
                    return Number(total);
                } else {
                    setListaState({
                        ...listaState,
                        loading: false,
                    });
                    return 0;
                }
            } else {
                setListaState({
                    ...listaState,
                    loading: false,
                });

                return 0;
            }
        }

        return handleDataAsyc().then();
    }

    function onPageChange(page_to_go) {
        setListaState({
            ...listaState,
            page: page_to_go,
        });
    }

    function onPageSizeChange(page_size) {
        setListaState({
            ...listaState,
            pageSize: page_size,
        });
    }

    useEffect(() => {
        if (listaState.getMaterias && stateRef.current && stateRef.current !== 0) {
            handleData(listaState.getMaterias);
        }

        stateRef.current += 1;
    }, [listaState.pageSize, listaState.page]);

    function setMaterias(new_materias) {
        setListaState({
            ...listaState,
            materias: new_materias,
        });
    }

    return {
        ...listaState,
        onPageChange,
        onPageSizeChange,
        handleData,
        setMaterias,
    };
}

function ListaMaterias(props) {
    const {
        materias, showPagination, pages, page, onPageChange, onPageSizeChange, pageSize, loading,
    } = useListaMateriasContext();

    if (loading) {
        return (
            <TableLoader loading={loading} />
        );
    }

    return (
        <Container>
            {materias && materias.length > 0 && (
                <Lista>
                    {materias.map((materia) => (
                        <ItemLista key={materia.id}>
                            <Category>{materia.tipo_materia_descricao}</Category>
                            <Title>{materia.titulo}</Title>
                            <Resumo>
                                {materia.stripped_texto.length > 260 ? `${materia.stripped_texto.substring(0, 260)}...` : materia.stripped_texto}
                            </Resumo>
                            <FieldSet>
                                <Buttons>
                                    <Button
                                        kind="lista-materia"
                                        icon="icon-materias"
                                        color={colors.primary_blue}
                                        to={`/materia/id=${materia.id}`}
                                    >
                                        VER MATÉRIA
                                    </Button>
                                    <Button
                                        kind="lista-materia"
                                        icon="icon-save"
                                        color={colors.primary_blue}
                                        onClick={() => {
                                            window.open(materia.diario_url);
                                        }}
                                    >
                                        VER DIÁRIO COMPLETO
                                    </Button>
                                </Buttons>
                                <Data>
                                    {`PUBLICADO NO ${materia.tipo_diario_descricao} DE ${materia.data_diario_materia_listagem}`}
                                </Data>
                            </FieldSet>
                        </ItemLista>
                    ))}
                </Lista>
            )}
            {showPagination && (
                <Paginacao>
                    <Pagination
                        content="left"
                        pages={pages}
                        page={page}
                        pageSize={pageSize}
                        onPageChange={onPageChange}
                        onPageSizeChange={onPageSizeChange}
                    />
                </Paginacao>
            )}
        </Container>
    );
}

export default ListaMaterias;
