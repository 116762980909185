import styled, { createGlobalStyle, css } from 'styled-components';
import Popup from 'reactjs-popup';
import { parse } from '../../utils/FontSize';
import { colors } from '../../configs/theme';

export const StyledPopup = styled(Popup)`
    display: flex;
    flex-direction: column;
`;

export const Header = styled('div')`
    padding: 30px;
    position: relative;
    background: ${colors.primary_grey};
`;

export const Title = styled('h2')`
    font-size: ${parse(24)};
    font-weight: bold;
    width: calc(100% - 30px);
    color: ${colors.grey_chumbo_table};
`;

export const Content = styled('div')`
    padding: 25px 30px;
    position: relative;
    background: ${colors.white};
    height: ${(props) => (props.height ? props.height : 'auto')};
    max-height: calc(100vh - 200px);
    ${(props) => props.contentOverflow && css`
        overflow-x: hidden;
        overflow-y: auto;
    `}
`;

export const PopupGlobalStyle = createGlobalStyle`
    .popup-overlay .popup-content {
        padding: 0 !important;
        border: unset !important;
    }

    .popup-overlay {
        display: flex;
        justify-content: center;
        align-items: center;
    }
`;

export const Close = styled('div')`
    width: 30px;
    height: 30px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    position: absolute;
    top: 20px;
    right: 20px;
    background-color: ${colors.white};
    box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);

    &:hover {
        opacity: .8;
    }
`;

export const Footer = styled('div')`
    background-color: ${colors.primary_grey};
    padding: 10px 20px;
    display: flex;
    height: auto;
    justify-content: flex-end;
`;

export const LoadContainer = styled('div')`
    width: 100%;
    height: 160px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
