import React from 'react';
import PropTypes from 'prop-types';
import { StyledLoader } from './styles';
import logo from '../../assets/img/nucleo/gif.png';

function TableLoader({ loading, ...props }) {
    return (
        <>
            {loading ? (
                <StyledLoader
                    className="icon-loading-save"
                    src={logo}
                    {...props}
                />
            ) : ''}
        </>
    );
}


TableLoader.propTypes = {
    loading: PropTypes.bool,
    left: PropTypes.string,
};

TableLoader.defaultProps = {
    loading: false,
    left: 'calc(50% - (50px / 2))',
};

export default TableLoader;
