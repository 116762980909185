import React from 'react';
import { StyledFooter, Container, Bloco,ICP } from './styles';
import icp_green from '../../assets/img/icp-green.png';

function Footer(props) {
    return (
        <StyledFooter>
            <Container>
                <Bloco>
                    <p>
                        © <b>2019. Prefeitura de Valparaíso de Goiás - GO.</b> Todos os direitos reservados.</p><p> Sistema desenvolvido por <a href="https://nucleogov.com.br/">NucleoGov</a>
                    </p>
                </Bloco>
                <Bloco>
                    <p>
                        Os Atos oficiais publicados neste site são assinados digitalmente
                        <br />
                        por autoridade certificadora credenciada no âmbito da
                        <br />
                        Infraestrutura de Chaves Públicas Brasileira - ICP Brasil.
                    </p>
                    <ICP src={icp_green} />
                </Bloco>
            </Container>
        </StyledFooter>
    );
}

export default Footer;
