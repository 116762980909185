import React, { useCallback, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import { uploadFile } from '../../../utils/api';
import Button from '../../../components/button';
import DragAndDropFileUpload from '../../../components/drag-n-drop-file-upload';
import Popup from '../../../components/popup';
import { error, success } from '../../../components/alerts';
import Request from '../../../utils/Request';

moment.locale('pt-br');

function UploadDiario({ diario }) {
    const [progress, setProgress] = useState(0);
    const [file, setFile] = useState({});
    const [isUploading, setIsUploading] = useState(false);
    const [initialFile, setInitialFile] = useState(null);

    const onDrop = useCallback((acceptedFiles) => {
        async function upload() {
            const uploaded_file = await uploadFile(acceptedFiles[0], (progressUpload) => {
                setProgress(progressUpload);
            });

            setIsUploading(false);
            const timeOutUpload = setInterval(() => {
                setProgress(0);
                clearInterval(timeOutUpload);
            }, 2000);

            if (uploaded_file && uploaded_file.data) {
                const { data } = uploaded_file;
                if (data.id && data.id !== 0) {
                    data.data = moment(data.data).format('HH:mm:ss DD/MM/YY');
                    setFile(data);
                }
            } else {
                error('Não foi possível fazer upload de arquivo');
            }
        }

        if (acceptedFiles.length > 0) {
            setIsUploading(true);
            upload();
        }

    }, []);

    function deleteFile() {
        setFile({});
    }

    async function publicar() {
        const request = new Request();

        const req_diario_publicar = request.setRequest('diarios', 'publicarDiario', { id: diario.id, arquivo: file.id });

        const result = await request.execute();

        return result[req_diario_publicar];
    }

    async function getFile() {
        if (diario && diario.id && Number(diario.id) > 0) {
            const request = new Request();

            const req_diario = request.setRequest('diarios', 'listar', { id: diario.id });

            const result_diario = await request.execute();

            if (result_diario[req_diario] && result_diario[req_diario].dados) {
                const { dados } = result_diario[req_diario];

                if (dados && dados.arquivo && Number(dados.arquivo) > 0) {
                    const req_diario_arquivo = request.setRequest('midias', 'pegar', { id: dados.arquivo });

                    const result = await request.execute();

                    result[req_diario_arquivo].data = moment(result[req_diario_arquivo].data).format('HH:mm:ss DD/MM/YY');
                    setInitialFile(result[req_diario_arquivo]);
                    return result[req_diario_arquivo];
                }
            }
        }

        return {};
    }

    return (
        <Popup
            onOpen={(setLoading) => {
                async function requestFile() {
                    const this_file = await getFile();

                    if (this_file && this_file.id && Number(this_file.id) > 0) {
                        setFile(this_file);
                    }

                    setLoading(false);
                }

                requestFile().then();
            }}
            trigger={(close) => (
                <div>
                    <Button
                        margin="0 15px 0 0"
                        type="button"
                        icon="icon-materias"
                    >
                        Publicar diário
                    </Button>
                </div>
            )}
            buttons={(file && file.id && !initialFile) || (initialFile && initialFile.id && initialFile !== file && file.id) ? (close) => (
                <>
                    <Button
                        kind="cancel"
                        onClick={close}
                        margin="0 10px 0 0"
                    >
                        Cancelar
                    </Button>
                    <Button
                        kind="save"
                        onClick={() => {
                            async function publicarDiario() {
                                const publicado_request = await publicar();

                                if (publicado_request === true) {
                                    success('Diário publicado com sucesso!');
                                    close();
                                } else if (typeof publicado_request === 'string') {
                                    error(publicado_request);
                                } else {
                                    error('Não foi publicar diário');
                                }
                            }

                            publicarDiario().then();
                        }}
                    >
                        Publicar
                    </Button>
                </>
            ) : null}
            title={`Publicar diário ${diario.numero}`}
        >
            <DragAndDropFileUpload
                onDrop={onDrop}
                progress={progress}
                onDeleteCb={deleteFile}
                file={file}
                isUploading={isUploading}
            />
        </Popup>
    );
}

export default UploadDiario;

UploadDiario.propTypes = {
    diario: PropTypes.object.isRequired,
};
