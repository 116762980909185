import React from 'react';
import moment from 'moment';
import Request, { getLimit } from '../../../utils/Request';
import PageContainer from '../../../components/page-container';
import Table from '../../../components/table';

function Home(props) {
    async function getRegistros({ page, limit: table_limit, ...table_props }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_diarios = request.setRequest('registros', 'listar', { limit });
        const result = await request.execute();

        return result && result[req_diarios] ? result[req_diarios] : {};
    }

    return (
        <PageContainer
            padding
            scroll
        >
            <Table
                headers={[
                    {
                        name: 'Usuário',
                        accessor: 'nome_usuario',
                        maxWidth: 320
                    },
                    {
                        name: 'Data',
                        accessor: 'data',
                        Cell: (props) => {
                            const data = moment(props.value, 'YYYY-MM-DD HH:mm:ss').format('DD/MM/YYYY [às] HH:mm:ss');
                            return <>{data}</>;
                        },
                        maxWidth: 200
                    },
                    {
                        name: 'Mensagem',
                        accessor: 'msg',
                    },
                ]}
                data_function={getRegistros}
            />
        </PageContainer>
    );
}

export default Home;
