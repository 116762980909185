import styled from 'styled-components';
import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const Container = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
`;

export const StyledHeader = styled('header')`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
    width: 100%;
    height: 80px;
    background: ${colors.white};
    padding: 20px 30px 0 0;
    box-shadow: 0 4px 4px -5px rgba(0,0,0,.3);

    @media all and(min-width: 1360px) {
        padding-left: 50px !important;
        padding-right: 50px !important;
    }
`;

export const StyledHeaderLeft = styled('div')`
    display: flex;
    flex-direction: row;
    align-items: center;

    .container_busca {
        width: 400px;
        height: 70px;
        display: flex;
        flex-direction: row;
        position: relative;
    }
`;

export const StyledHeaderRight = styled('div')`
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .info {
        display: flex;
        align-items: center;
        justify-content: right;
    }

    .actions {
        display: flex;
        justify-content: right;
        flex-direction: row;
        align-items: center;
        padding-top: 2px;
        margin-left: 7px;

        .header-button {
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid ${colors.color_emperor_approx};
            color: ${colors.color_emperor_approx};
            cursor: pointer;
            padding: 1px 0 0 0;
            margin-left: 7px;
            position: relative;

            &::before {
                position: relative;
                display: block;
                width: 16px;
                font-size: 15px;
                top: -1px;
            }

            &:hover {
                background: transparent;
                color: ${colors.color_blaze_orange_approx};
                border-color: ${colors.color_blaze_orange_approx};
                cursor: pointer;
            }
        }

        .orange {
            background: ${colors.color_blaze_orange_approx};
            border-color: ${colors.color_blaze_orange_approx};
            color: ${colors.white};

            &:hover {
                background: transparent;
                color: ${colors.color_blaze_orange_approx};
            }
        }
    }
`;

export const Instituicao = styled('h2')`
    font-size: ${parse(24)};
    font-weight: normal;
`;

export const UserWelcome = styled('p')`
    font-size: ${parse(11)};
`;

export const StyledHeaderCidadao = styled('header')`
    width: 100%;
    min-height: 220px;
    background: #232A2E;
`;

export const ContainerHeaderCidadao = styled('div')`
    width: 100%;
    max-width: 1148px;
    margin: 0 auto;
    padding: 48px 0 50px 0;
    display: flex;
    flex-direction: column;
`;

export const TitleHeaderCidadao = styled('h1')`
    width: 100%;
    font-weight: normal;
    font-size: ${parse(40)};
    line-height: 46px;
    letter-spacing: -0.025em;
    color: #ffffff;
    margin-bottom: 30px;

    b {
        font-weight: bold;
    }
`;

export const FiltrosHeaderCidadao = styled('fieldset')`
    width: 100%;
    height: 45px;
    display: flex;
`;
