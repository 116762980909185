import { extractParamsAsString } from '../utils/RouterRes';

export const groups = {
    materias: [
        {
            title: 'Lista de matérias',
            to: '/painel/materias',
            permissao: 'R_MATERIAS',
        },
        {
            title: 'Adicionar matéria',
            to: '/painel/materias/editor',
            permissao: 'M_MATERIAS',
        },
    ],
    diarios: [
        {
            title: 'Lista de diários',
            to: '/painel/diarios',
            permissao: 'R_DIARIOS',
        },
        {
            title: 'Adicionar diário',
            to: '/painel/diarios/editor',
            permissao: 'M_DIARIOS',
        },
    ],
};

export const getTabGroup = (pathname, url) => {
    const { group_name, group_param, action_param } = getParams(pathname, url);
    const full_url = `/painel${group_param}${action_param}`;

    const group = getGroup(group_name);

    return selectedTabs(group, full_url);
};

const selectedTabs = (group, full_url) => {
    if (group.length > 0) {
        return group.map((tab) => ({
            ...tab,
            selected: tab.to === full_url,
        }));
    }

    return [];
};

const getParams = (pathname, url) => {
    const params_string = extractParamsAsString(pathname, url);

    const params = params_string.split('/');

    const group_name = params[0] ? params[0] : '';
    const group_param = params[0] ? `/${params[0]}` : '';
    const action_param = params[1] ? `/${params[1]}` : '';

    return {
        group_name,
        group_param,
        action_param,
    };
};

const getGroup = (group_name) => {
    const group_index = Object.keys(groups).find((group) => group === group_name);

    if (groups[group_index] && groups[group_index].length > 0) {
        return groups[group_index];
    }

    return [];
};

export const isSomeActive = (requested_group, pathname, url) => {
    const { group_name } = getParams(pathname, url);
    const {
        group_name: requested_group_name,
    } = getParams(requested_group, url);

    if (group_name === requested_group_name) {
        const group = selectedTabs(getGroup(group_name), requested_group);
        return group.some((item) => item.selected);
    }
    return false;
};
