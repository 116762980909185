import styled from 'styled-components';
import { colors } from '../../configs/theme';

export const StyledFooter = styled('footer')`
    width: 100%;
    min-height: 100px;
    background: ${colors.white};
`;

export const Container = styled('div')`
    display: flex;
    align-items: center;
    max-width: 1148px;
    margin: 0 auto;
    padding: 28px 0;
`;

export const Bloco = styled('div')`
    width: 50%;
    display: flex;

    &:first-child {
        flex-direction: column;

        p {
            font-size: 14px;
            line-height: 20px;

            a {
                font-weight: bold;
                color: ${colors.primary_blue};
            }
        }
    }

    &:last-child {
        justify-content: flex-end;
        flex-direction: row;

        p {
            font-size: 11px;
            line-height: 13px;
            color: ${colors.grey_placeholder};
            margin-right: 19px;
            text-align: right;
        }
    }
`;

export const ICP = styled('img')`
    width: 132.44px;
    height: 45px;
`;
