import styled from 'styled-components';
import { colors } from '../../../configs/theme';

export const StyledLogin = styled('div')`
    display: flex;
    width: 100%;
    height: 100%;
    border-top: 5px solid ${colors.primary_blue};
    background: ${colors.secondary_blue};
`;

export const Form = styled('form')`
    width: 300px;
    position: absolute;
    top: calc(50% - 135px);
    left: calc(50% - 150px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &::after {
        content: none;
    }
`;

export const Logo = styled('div')`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
`;

export const Imgs = styled('div')`
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;

    &::after {
        content: none;
    }

    img {
        width: 100%;
        margin-bottom: 5px;
    }
`;

export const Versao = styled('p')`
    width: 100%;
    font-size: 0.8em;
    text-align: right;
    color: ${colors.white};
`;

export const InputContainer = styled('label')`
    width: 100%;
    height: 40px;
    margin-bottom: 10px;
`;

export const Slider = styled('div')`
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.color_celeste_approx};
    transition: .4s;
    border-radius: 20px;

    &:before {
        position: absolute;
        content: "";
        height: 15px;
        width: 15px;
        left: 3px;
        bottom: 3px;
        background-color: ${colors.white};
        transition: .4s;
        border-radius: 50%;
    }
`;

export const Input = styled('input')`
    width: 100%;
    height: 100%;
    padding: 10px 14px;
    border-radius: 5px;

    &:focus {
        outline: none;

        + ${Slider} {
            box-shadow: 0 0 1px ${colors.primary_blue};
        }
    }

    &:checked + ${Slider} {
        background-color: ${colors.primary_blue};

        &:before {
            transform: translateX(15px);
        }
    }
`;

export const Entrar = styled('input')`
    width: 100%;
    height: 40px;
    border-radius: 5px;
    font-weight: bold;
    border: 3px solid ${colors.primary_blue};
    background: ${colors.primary_blue};
    color: ${colors.white};
    cursor: pointer;
    transition: 0.2s;

    &:hover {
        background: ${colors.secondary_blue};
        color: ${colors.primary_blue};
        transition: 0.2s;
    }
`;

export const Remember = styled('div')`
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    color: ${colors.white};
`;

export const Switch = styled('label')`
    position: relative;
    display: inline-block;
    width: 35px;
    height: 20px;
`;

export const StyledCheckBox = styled(Input)`
    opacity: 0;
    width: 0;
    height: 0;
`;

export const Conectado = styled('span')`
    float: left;
    color: ${colors.white};
    font-size: 0.9em;
    margin-left: 10px;
`;
