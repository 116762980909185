import styled from 'styled-components';
import { parse } from '../../../utils/FontSize';
import { colors } from '../../../configs/theme';

export const Container = styled('div')`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
`;

export const TitlePage = styled('h4')`
    width: 100%;
    font-weight: bold;
    font-size: ${parse(18)};
    line-height: 21px;
    color: ${colors.grey_chumbo_table};
    margin-bottom: 13px;
`;

export const FieldSet = styled('fieldset')`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

export const Filtros = styled('div')`
    display: flex;
    margin-right: 40px;
`;

export const Filtro = styled('div')`
    width: 220px;
    background: ${colors.grey_pagination};
    border: 1px solid ${colors.grey_lines_table};
    box-sizing: border-box;
    border-radius: 40px;
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-right: 10px;

    &:last-child {
        margin-right: 0;
    }
`;

export const FiltroDescricao = styled('div')`
    color: ${colors.grey_chumbo_table};
    font-size: ${parse(16)};
    line-height: 18px;
    width: calc(100% - 30px);
    margin-right: 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const ContadorResultados = styled('div')`
    font-style: italic;
    font-size: ${parse(14)};
    line-height: 16px;
    text-align: right;
    color: ${colors.grey_text};
`;

export const Resultados = styled('div')`
    width: 100%;
    margin-top: 37px;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
`;
