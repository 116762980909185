import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const Container = styled('nav')`
    height: 45px;
    display: flex;
    justify-content: space-between;
    align-self: flex-end;
    width: calc(100% - 343px);
    background-color: white;
`;

export const Left = styled('div')`
    display: flex;
    width: calc(100% - 90px);
    height: 100%;
`;

export const Right = styled('div')`
    width: 70px;
    height: 100%;
    padding-right: 25px ;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Tab = styled(Link)`
    width: auto;
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 20px;
    height: 100%;
    font-weight: bold;
    color: ${(props) => (props.selected ? colors.primary_blue : colors.black_table)};
    font-size: ${parse(15)};
    cursor: pointer;

    &:hover {
        color: ${colors.primary_blue};
    }
`;
