import styled from 'styled-components';
import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const Container = styled('div')`
    display: flex;
    width: 100%;
    height: auto;
    flex-direction: column;
`;

export const Lista = styled('ul')`
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
`;

export const ItemLista = styled('div')`
    background: ${colors.white};
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    padding: 30px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    
    &:last-child {
        margin-bottom: 0;
    }
`;

export const Category = styled('span')`
    font-weight: bold;
    font-size: ${parse(12)};
    line-height: 14px;
    text-transform: uppercase;
    color: ${colors.primary_blue};
    margin-bottom: 6px;
`;

export const Title = styled('h2')`
    font-weight: bold;
    font-size: ${parse(18)};
    line-height: 21px;
    text-transform: uppercase;
    color: ${colors.grey_chumbo_table};
    margin-bottom: 17px;
`;

export const Resumo = styled('p')`
    text-transform: uppercase;
    font-size: ${parse(13)};
    color: ${colors.grey_chumbo_table};
    margin-bottom: 23px;
`;

export const FieldSet = styled('div')`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Buttons = styled('div')`
    display: flex;
    width: fit-content;
    flex-wrap: wrap;
`;

export const Data = styled('div')`
    font-weight: bold;
    font-size: 11px;
    line-height: 13px;
    color: ${colors.primary_blue};
    text-transform: uppercase;
    margin-left: 30px;
`;

export const Paginacao = styled('div')`
    display: flex;
    width: 100%;
`;
