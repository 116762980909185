import React, { useContext } from 'react';
import { useLocation, useRouteMatch } from 'react-router';
import Icon from '../icon';
import {
    Container, Menu, NavMenu, StyledLink, LinkContainer,
} from './styles';
import { AppContext } from '../../app/app-container';
import { Bar } from '../../app/global-styles';
import { isSomeActive } from '../../configs/tab-groups';

function Sidebar() {
    const { menu, hasPermissao } = useContext(AppContext);
    const location = useLocation();
    const routeMatch = useRouteMatch();

    function isActive(link) {
        return location.pathname === link || isSomeActive(link, location.pathname, routeMatch.url) ? 'true' : null;
    }

    function getMenu() {
        const hasPermissaoArray = (permissoes) => permissoes.some((this_permissao) => hasPermissao(this_permissao));
        return menu.filter((item) => !!((item.permissao && Array.isArray(item.permissao) && hasPermissaoArray(item.permissao))
                || ((item.permissao && item.permissao !== '' && hasPermissao(item.permissao)) || item.publica)));
    }

    return (
        <Container>
            <Menu>
                <Icon
                    className="icon-menu"
                    size="18px"
                    marginRight="10px"
                />
                Menu
            </Menu>
            <NavMenu>
                {getMenu().map((item) => (
                    <StyledLink
                        to={item.link}
                        title={item.title}
                        key={item.link}
                        active={isActive(item.link)}
                    >
                        {isActive(item.link) && <Bar orientation="vertical" />}
                        <LinkContainer active={isActive(item.link)}>
                            <Icon
                                className={item.icon}
                                marginRight="10px"
                                size="18px"
                            />
                            {item.title}
                        </LinkContainer>
                    </StyledLink>
                ))}
            </NavMenu>
        </Container>
    );
}

export default Sidebar;
