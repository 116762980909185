import styled from 'styled-components';
import { parse } from '../../utils/FontSize';
import { colors } from '../../configs/theme';

export const Topo = styled('div')`
    width: 100%;
    margin-bottom: 38px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

export const Title = styled('h1')`
    font-weight: bold;
    font-size: ${parse(24)};
    line-height: 28px;
    color: ${colors.grey_chumbo_table};
    display: flex;
    align-items: center;
    margin-right: 50px;
`;

export const Current = styled('div')`
    display: flex;
    align-items: center;  
`;

export const Origin = styled('div')`
    font-weight: bold;
    font-size: ${parse(16)};
    line-height: 18px;
    color: ${colors.grey_chumbo_table};
    text-align: right;
`;
