import React from 'react';
import ReactDOM from 'react-dom';
import Request from './utils/Request';
import { URL_API } from './data/constants';
import './utils/yupApplyValidators';
import './assets/fonts/icons/styles.css';
import './assets/fonts/times-new-roman/times-new-roman.css';

import App from './app';

Request.setDefaultUrl(URL_API);

ReactDOM.render(<App />, document.getElementById('root'));
