import React, { useState, useRef, useContext } from 'react';
import { Formik, Field } from 'formik';
import * as yup from 'yup';
import { success, loading, error } from '../../../components/alerts';
import Table from '../../../components/table';
import Resizer from '../../../components/resizer';
import PageContainer from '../../../components/page-container';
import ControlledInput from '../../../components/form-components/controlled-input';
import Request, { getLimit } from '../../../utils/Request';
import { parser } from '../../../utils/Select';
import { Row } from '../../../app/global-styles';
import Button from '../../../components/button';
import Icon from '../../../components/icon';
import { colors } from '../../../configs/theme';
import confirmAlert from '../../../components/confirm-alert';
import Popup from '../../../components/popup';
import Reordable, { listOrdered, listParse } from '../../../components/reordable';
import { AppContext } from '../../../app/app-container';

function Orgaos(props) {
    const { hasPermissao } = useContext(AppContext);
    const tableRef = useRef();
    const [orgao, setOrgao] = useState({});
    const [reorableList, setReorableList] = useState();

    const initialValues = orgao && Object.keys(orgao).length > 0 ? orgao : {
        descricao: '',
        superior: {},
    };

    async function handleSubmit(values, state) {
        const request = new Request();
        const orgao_to_save = {};

        orgao_to_save.descricao = values.descricao;

        if (values.superior.value) {
            orgao_to_save.pai = values.superior.value;
        }

        if (values.id) {
            orgao_to_save.id = values.id;
        }

        const loadToast = loading('Salvando órgão');

        try {
            const req_orgao = request.setRequest('orgaos', 'salvar', { orgao: orgao_to_save });

            const result = await request.execute();

            if (result[req_orgao] === true) {
                loadToast();
                success(`Órgão ${orgao_to_save.descricao} alterado com sucesso`);
                tableRef.current.fireFetchData();
            } else if (Number(result[req_orgao]) > 0) {
                loadToast();
                success(`Órgão ${orgao_to_save.descricao} adicionado com sucesso`);
                tableRef.current.fireFetchData();
            } else {
                loadToast();
                error('Não foi possível salvar órgão!');
            }

            setOrgao({});
            state.resetForm();
        } catch (e) {
            loadToast();
            error('Falha ao salvar órgão');
        }

    }

    async function getOrgaos({ page, limit: table_limit = 0, ...table_props }) {
        const request = new Request();

        const limit = table_limit !== 0 ? getLimit(page, table_limit) : null;

        try {
            const req_orgaos = request.setRequest('orgaos', 'listar', { limit });
            const result = await request.execute();

            return result[req_orgaos];
        } catch (e) {
            return {};
        }
    }

    async function getOrgaosSelect(search) {
        const request = new Request();

        const id = orgao.id || null;
        const req_orgaos = request.setRequest('orgaos', 'listarTodos', { search, id });
        const result = await request.execute();

        const { dados } = result[req_orgaos] || [];

        const formated_orgaos = parser('descricao', 'id', dados);

        return formated_orgaos;
    }

    async function desativarOrgao({ original }) {
        if (original && original.id) {
            const desativar = async () => {
                const request = new Request();

                const { id, pai } = original;

                const req_desativar = request.setRequest('orgaos', 'desativar', { id, pai });
                const result = await request.execute();

                if (result[req_desativar]) {
                    success('Órgão excluído com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir órgão!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir órgão?',
                subtitle: `Ao confirmar o órgão ${original.descricao} e todos os seus subordinados serão excluídos!`,
                onConfirm() {
                    desativar(original).then();
                },
            });

        } else {
            error('Órgão inválido!');
        }
    }

    function handleOrgaoClick(data, column) {
        if (column.id !== 'options' && data.original) {
            const { original } = data;

            const orgao_to_edit = {
                descricao: original.descricao,
                id: original.id,
            };

            if (original.pai !== null) {
                orgao_to_edit.superior = {
                    value: original.pai,
                    label: original.pai_descricao,
                };
            } else {
                orgao_to_edit.superior = {};
            }

            setOrgao(orgao_to_edit);
        }
    }

    async function reordenar() {
        if (reorableList) {
            const request = new Request();
            const ordered_list = listOrdered(reorableList, 'subordinados');

            const req_reordenar = request.setRequest('orgaos', 'reordenar', { lista: ordered_list });

            const result = await request.execute();

            tableRef.current.fireFetchData();
            return result && result[req_reordenar];
        }
    }

    function makeForm(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                <Row
                    height="auto"
                    spaceBetween
                >
                    <Field
                        component={ControlledInput}
                        name="descricao"
                        placeholder="Órgão"
                        label="Órgão"
                        type="text"
                        required
                        size={4}
                    />
                    <Field
                        component={ControlledInput}
                        name="superior"
                        type_select="async"
                        type="select"
                        isClearable
                        label="Órgão Superior"
                        size={4}
                        defaultOptions
                        cacheOptions
                        loadOptions={getOrgaosSelect}
                    />
                </Row>
                <Row
                    contentEnd
                    padding="0 15px"
                >
                    {Object.entries(orgao).length > 0 && (
                        <Button
                            type="reset"
                            kind="cancel"
                            margin="0 10px 0 0"
                            onClick={() => {
                                setOrgao({});
                                formState.resetForm();
                            }}
                        >
                        Cancelar
                        </Button>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                            Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    function renderTable() {
        return (
            <>
                {
                    hasPermissao('RE_ORGAOS') && (
                        <Popup
                            height="600px"
                            title="Reordenar Órgãos"
                            trigger={(close) => (
                                <Row margin="0 0 10px 0">
                                    <Button kind="reorder">Reordenar</Button>
                                </Row>
                            )}
                            contentOverflow
                            onOpen={(setLoading) => {
                                async function requestOrgaos() {
                                    try {
                                        const orgaos = await getOrgaos({}).then();

                                        setReorableList(listParse(orgaos.dados, 'id', 'descricao', 'subordinados'));
                                        setLoading(false);
                                    } catch (e) {
                                        setLoading(false);
                                    }
                                }

                                requestOrgaos().then();
                            }}
                            buttons={(close) => (
                                <>
                                    <Button
                                        kind="cancel"
                                        onClick={close}
                                        margin="0 10px 0 0"
                                    >
                                        Cancelar
                                    </Button>
                                    <Button
                                        kind="save"
                                        onClick={() => {
                                            async function reordenarOrgaos() {
                                                const reordenados = await reordenar();

                                                if (reordenados && typeof reordenados !== 'string') {
                                                    success('Órgãos reordenados com sucesso!');
                                                    close();
                                                } else {
                                                    error('Não foi possível reordenar órgãos');
                                                }
                                            }

                                            reordenarOrgaos().then();
                                        }}
                                    >
                                        Salvar
                                    </Button>
                                </>
                            )}
                        >
                            <Reordable
                                list={reorableList}
                                setList={setReorableList}
                            />
                        </Popup>
                    )
                }
                <Table
                    headers={[
                        {
                            name: 'Órgão',
                            accessor: 'descricao',
                        },
                    ]}
                    subComponent="subordinados"
                    data_function={getOrgaos}
                    tableRef={tableRef}
                    options={hasPermissao('D_ORGAOS') ? (table_props) => (
                        <Icon
                            hidden
                            size="16px"
                            hover={colors.red_error_message}
                            color={colors.black_table}
                            className="icon-trash"
                            onClick={() => desativarOrgao(table_props)}
                        />
                    ) : null}
                    clickHandler={handleOrgaoClick}
                />
            </>
        );
    }

    function renderForm() {
        return (
            <PageContainer title="Órgãos">
                <Formik
                    initialValues={initialValues}
                    validationSchema={yup.object().shape({
                        descricao: yup.string().required('Nome do órgão é obrigatório!'),
                    })}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }


    if (hasPermissao('R_ORGAOS') && !hasPermissao('M_ORGAOS')) {
        return (
            <PageContainer
                padding
                scroll
            >
                {renderTable()}
            </PageContainer>
        );
    } else if (!hasPermissao('R_ORGAOS') && hasPermissao('M_ORGAOS')) {
        return (
            <PageContainer
                padding
                scroll
            >
                {renderForm()}
            </PageContainer>
        );
    } else if (hasPermissao('M_ORGAOS') && hasPermissao('R_ORGAOS')) {
        return (
            <Resizer
                left={45}
                right={55}
                minLeft={500}
                minRight={500}
            >
                {renderTable()}
                {renderForm()}
            </Resizer>
        );
    }
}

export default Orgaos;
