import React  from 'react';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import {
    Container,
    Descricao,
    DiarioFileContainer,
    DiarioFileInfo,
    DiarioFileDescricao,
    DiarioFileDataPublicacao,
    IconInfoContainer,
    ProgressBar,
} from './styles';
import Icon from '../icon';
import { colors } from '../../configs/theme';


function DragAndDropFileUpload({
    onDeleteCb, isUploading, file, onDrop, progress, ...props
}) {

    const {
        getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject,
    } = useDropzone({ onDrop, ...props });

    function getMessage() {
        if (isDragActive && isDragAccept) {
            return (
                <Descricao>Solte o arquivo aqui!</Descricao>
            );
        }

        if (isDragReject) {
            return (
                <Descricao>Arquivo não permitido!</Descricao>
            );
        }

        return (
            <Descricao>Clique ou arraste para adicionar um arquivo</Descricao>
        );
    }

    return (
        <>
            {isUploading || file.id
                ? (
                    <DiarioFileContainer>
                        <IconInfoContainer>
                            <Icon
                                size="72px"
                                className="icon-pdf-file"
                                color={colors.grey_chumbo}
                            />
                            <Icon
                                className="icon-trash"
                                color={colors.grey_chumbo}
                                hover={colors.red_error_message}
                                absolute
                                top="calc(50% - (20px / 2))"
                                right="6px"
                                size="20px"
                                onClick={onDeleteCb}
                            />
                            <DiarioFileInfo>
                                {file.titulo && <DiarioFileDescricao>{`Arquivo: ${file.titulo}`}</DiarioFileDescricao>}
                                {file.data
                                && <DiarioFileDataPublicacao>{`Publicado em: ${file.data}`}</DiarioFileDataPublicacao>}
                            </DiarioFileInfo>
                        </IconInfoContainer>
                        <ProgressBar progress={progress} />
                    </DiarioFileContainer>
                )
                : (
                    <Container {...getRootProps({
                        isDragActive, isDragAccept, isDragReject,
                    })}
                    >
                        <input {...getInputProps()} />
                        <Icon
                            className="icon-upload"
                            size="64px"
                            color={colors.grey_chumbo}
                        />
                        {getMessage()}
                    </Container>
                )}
        </>
    );
}

DragAndDropFileUpload.propTypes = {
    accept: PropTypes.string,
    disabled: PropTypes.bool,
    file: PropTypes.object,
    isUploading: PropTypes.bool,
    maxSize: PropTypes.number,
    minSize: PropTypes.number,
    multiple: PropTypes.bool,
    noClick: PropTypes.bool,
    noDrag: PropTypes.bool,
    noKeyboard: PropTypes.bool,
    onDeleteCb: PropTypes.func,
    onDragEnter: PropTypes.func,
    onDragLeave: PropTypes.func,
    onDragOver: PropTypes.func,
    onDrop: PropTypes.func,
    onDropAccepted: PropTypes.func,
    onDropRejected: PropTypes.func,
    onFileDialogCancel: PropTypes.func,
    preventDropOnDocument: PropTypes.bool,
    progress: PropTypes.number,
};

DragAndDropFileUpload.defaultProps = {
    accept: 'application/pdf',
    disabled: false,
    file: {},
    isUploading: false,
    maxSize: 200000000,
    minSize: 1000,
    multiple: false,
    noClick: false,
    noDrag: false,
    noKeyboard: false,
    onDeleteCb: null,
    onDrop: null,
    preventDropOnDocument: true,
    progress: 0,
};


export default DragAndDropFileUpload;
