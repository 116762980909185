import styled, { css } from 'styled-components';
import DayPicker from 'react-day-picker';
import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';
import 'react-day-picker/lib/style.css';

export const StyledCalendar = styled(DayPicker)`
    width: 100%;
    height: 100%;
    position: relative;

    .DayPicker-wrapper {
        width: 100%;
        height: 100%;
        position: relative;

        .DayPicker-Months {
            width: 100%;
            height: 100%;

            .DayPicker-Month {
                width: 100%;
                height: 100%;
                margin: 0;
                display: flex;
                flex-direction: column;

                 .DayPicker-Caption {
                    font-size: ${parse(26)};
                    line-height: 30px;
                    color: ${colors.grey_chumbo_pagination};
                    margin-bottom: 7px;
                    padding: 0;
                }

                .DayPicker-Weekdays {
                    border-bottom: 1px solid ${colors.grey_text};
                    margin-bottom: 0;
                    margin-top: 0;

                    .DayPicker-WeekdaysRow {
                        display: flex;
                        width: 100%;
                        justify-content: space-around;

                        .DayPicker-Weekday {
                            color: ${colors.grey_text};
                            font-size: ${parse(20)};
                            text-transform: unset;
                            padding: 30px 0 15px 0;

                            abbr {
                                text-transform: capitalize;
                            }
                        }
                    }
                }

                .DayPicker-Body {
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    .DayPicker-Week {
                        width: 100%;
                        justify-content: space-around;
                        display: flex;
                        .DayPicker-Day {
                            font-size: ${parse(18)};
                            color: ${colors.grey_chumbo_pagination};
                            width: 50px;
                            height: 50px;
                            border-radius: 50%;
                            margin: 10px;
                            padding: 0;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            &:hover {
                                background-color: ${colors.primary_blue_hover};
                            }

                            &.DayPicker-Day--hasDiario {
                                color: ${colors.primary_blue};
                            }

                            &.DayPicker-Day--highlighted,
                            &.DayPicker-Day--selected {
                                color: ${colors.white};
                                background-color: ${colors.primary_blue};
                            }

                            &.DayPicker-Day--outside {
                                color: ${colors.grey_pagination};
                                cursor: pointer;
                            }

                            &.DayPicker-Day--today {
                                font-weight: unset;
                            }
                        }
                    }
                }
            }
        }
    }
`;

export const TitleBox = styled('h3')`
    font-size: ${parse(26)};
    line-height: 30px;
    color: ${colors.grey_chumbo_pagination};
    margin-bottom: 7px;
`;

export const ActionButtons = styled('div')`
    display: flex;
    justify-content: space-between;
    width: 65px;
    height: 30px;
    position: absolute;
    right: 0;
    top: 0;
`;

export const LoaderContainer = styled('div')`
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    border-radius: 10px;
    width: 100%;
    height: 100%;
    background-color: white;
    ${(props) => (props.carregando ? null : css`display: none;`)}
`;
