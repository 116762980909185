import axios from 'axios';
import { URL_API } from '../data/constants';

export const api = axios.create({
    baseURL: URL_API,
});

export const uploadAPI = (params, onUploadProgressCb, acao = 'enviarOutros') => api.post(`midias?acao=${acao}`, params, { onUploadProgress: onUploadProgressCb });

export function uploadFile(file, onUploadProgressCb) {
    const data = new FormData();

    data.append('Filedata', file, file.name);

    return uploadAPI(data, (event) => {
        const progress = Math.round((event.loaded * 100) / event.total);
        onUploadProgressCb(progress, event);
    });
}
