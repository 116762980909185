import React from 'react';
import PropTypes from 'prop-types';
import Select from '../select';
import Input from '../input';
import Editor from '../editor-wysiwyg';
import DatePicker from '../date-picker';
import Mask from '../mask';
import CheckBoxGroup from '../checkbox-group';
import RadioGroup from '../radio-group';
import DateRangePicker from '../daterange-picker';

function ControlledInput({
    type, size, onChange, onBlur, onFocus, type_select, height, margin, id, background_color, form, field, ...props
}) {
    const { errors, touched } = form;
    const { name, value } = field;

    props.width = props.width || `calc((100% / 8) * ${size})`;

    const general_events = {
        onChange(event, editor = null) {
            if (type === 'editor') {
                form.setFieldValue(name, editor.getData());
            } else if (type === 'select' || type === 'date-picker' || type === 'date-range') {
                form.setFieldValue(name, event);
            } else if (type === 'mask') {
                const input_value = event.target.value;
                form.setFieldValue(name, input_value);
            } else if (type === 'checkbox-group') {
                form.setFieldValue(name, event);
            } else if (type === 'input-radio') {
                form.setFieldValue(name, event.target.value);
            }

            if (field.onChange) {
                field.onChange(event);
            }

            if (onChange && type === 'editor') {
                onChange(editor.getData());
            } else if (onChange && type === 'input-radio') {
                onChange(event.target.value);
            } else if (onChange) {
                onChange(event);
            }
        },
        onBlur(event, editor = null) {
            if (onBlur && type !== 'editor') {
                onBlur(event);
            }

            if (field.onBlur && type !== 'editor' && type !== 'select') {
                field.onBlur(event);
            }

            if (type === 'editor') {
                form.setFieldTouched(name, true);
            } else if (type === 'select' || type === 'date-picker' || type === 'mask' || type === 'checkbox-group' || type === 'input-radio') form.setFieldTouched(name, true);
        },
        onFocus(event, editor = null) {
            if (onFocus) {
                onFocus(event);
            }

            if (field.onFocus) {
                field.onFocus(event, editor);
            }
        },
    };

    const field_props = {
        height,
        margin,
        name,
        value,
        id,
        type,
        background_color,
        ...props,
        ...field,
        ...general_events,
    };

    if (errors[name] && touched[name]) {
        field_props.error_message = errors[name];
    }

    if (type === 'select') {
        field_props.type_select = type_select;
    }

    if (type === 'select') {
        return (
            <Select {...field_props} />
        );
    }

    if (type === 'date-picker') {
        return (
            <DatePicker {...field_props} />
        );
    }

    if (type === 'editor') {
        return (
            <Editor {...field_props} />
        );
    }

    if (type === 'mask') {
        return (
            <Mask {...field_props} />
        );
    }

    if (type === 'checkbox-group') {
        return (
            <CheckBoxGroup {...field_props} />
        );
    }

    if (type === 'input-radio') {
        return (
            <RadioGroup {...field_props} />
        );
    }

    if (type === 'date-range') {
        return (
            <DateRangePicker {...field_props} />
        );
    }

    return (
        <Input {...field_props} />
    );
}

ControlledInput.propTypes = {
    name: PropTypes.string,
    id: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    size: PropTypes.number,
    type: PropTypes.oneOf(['text', 'editor', 'select', 'date-picker', 'mask', 'password', 'checkbox-group', 'input-radio', 'date-range']).isRequired,
    type_select: PropTypes.oneOf(['async', 'normal']),
    defaultOptions: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
        PropTypes.bool,
    ]),
    mask: PropTypes.oneOfType([
        PropTypes.func,
        PropTypes.array,
    ]),
    cacheOptions: PropTypes.bool,
    loadOptions: PropTypes.func,
    options: PropTypes.array,
};

ControlledInput.defaultProps = {
    size: 4,
};


export default ControlledInput;
