import styled, { css } from 'styled-components';
import { colors } from '../../configs/theme';
import { parse } from '../../utils/FontSize';

export const Container = styled('div')`
    width: 100%;
    border-radius: 5px;
    height: 100%;
    display: flex;
    flex-direction: column;
    ${(props) => props.padding && css`padding: 20px`}
`;

export const Title = styled('h2')`
    font-size: ${parse(24)};
    font-weight: bold;
    margin-bottom: 15px;
    padding: 0 15px;
    color: ${colors.grey_chumbo_table};
`;
