import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import MomentLocaleUtils from 'react-day-picker/moment';
import {
    ActionButtons, StyledCalendar, TitleBox, LoaderContainer,
} from './styles';
import 'moment/locale/pt-br';
import Icon from '../icon';
import { colors } from '../../configs/theme';
import { HomeContext } from '../../pages/cidadao/home';
import TableLoader from '../tableloader';

const WEEKDAYS_SHORT = ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab'];

function Calendar({ ...props }) {
    const {
        loadingCalendar, diasDiario, handleMonthChange, ultimoDiario, setDaySelected,
    } = useContext(HomeContext);
    const [selectedDay, setSelectedDay] = useState();

    const modifiers = {
        highlighted: selectedDay ? null : (ultimoDiario ? ultimoDiario.data_object : null),
        hasDiario(day) {
            if (diasDiario.length > 0) {
                return diasDiario.map((data_diarios) => Number(data_diarios.dia)).includes(day.getDate());
            }
            return false;
        },
    };

    if (!ultimoDiario && !loadingCalendar) {
        return (
            <LoaderContainer carregando={loadingCalendar}>
                <TableLoader
                    loading={loadingCalendar}
                    left="calc(50% - (80px / 2))"
                />
            </LoaderContainer>
        );
    }
    return (
        <>
            <LoaderContainer carregando={loadingCalendar}>
                <TableLoader
                    loading={loadingCalendar}
                    left="calc(50% - (80px / 2))"
                />
            </LoaderContainer>
            <StyledCalendar
                {...props}
                onDayClick={(day, { selected }) => {
                    if (selected) {
                        setDaySelected(null);
                        setSelectedDay(null);
                        return;
                    }
                    setDaySelected(day);
                    setSelectedDay(day);
                }}
                selectedDays={selectedDay}
                modifiers={modifiers}
                initialMonth={ultimoDiario ? ultimoDiario.data_object : new Date()}
                captionElement={(caption_props) => {
                    const month = props.localeUtils.formatMonthTitle(caption_props.date, caption_props.locale);
                    return (
                        <TitleBox>
                            {month}
                        </TitleBox>
                    );
                }}
                navbarElement={(navbar_props) => (
                    <ActionButtons>
                        <Icon
                            className="icon-seta-calendar"
                            size="20px"
                            onClick={() => navbar_props.onPreviousClick && navbar_props.onPreviousClick()}
                            title="Mês anterior"
                            color={colors.primary_blue}
                        />
                        <Icon
                            className="icon-seta-calendar"
                            title="Mês posterior"
                            size="20px"
                            onClick={() => navbar_props.onNextClick && navbar_props.onNextClick()}
                            color={colors.primary_blue}
                            rotate="180"
                        />
                    </ActionButtons>
                )}
                onMonthChange={(data) => handleMonthChange && handleMonthChange(data)}
            />
        </>
    );
}

export default Calendar;

Calendar.propTypes = {
    locale: PropTypes.string,
    onMonthChange: PropTypes.func,
    localeUtils: PropTypes.object,
    weekdaysShort: PropTypes.array,
    showOutsideDays: PropTypes.bool,
};

Calendar.defaultProps = {
    locale: 'pt-br',
    localeUtils: MomentLocaleUtils,
    weekdaysShort: WEEKDAYS_SHORT,
    showOutsideDays: true,
};
