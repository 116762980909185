import React, { useContext, useEffect } from 'react';
import {
    Route, Switch, useHistory,
} from 'react-router-dom';
import { AppContent, Container } from './styles';
import Sidebar from '../../components/sidebar';
import Header, { HeaderRight } from '../../components/header';
import Home from '../../pages/painel/home';
import Orgaos from '../../pages/painel/orgaos';
import TiposDiario from '../../pages/painel/tipos_diario';
import TiposMateria from '../../pages/painel/tipos_materia';
import Materia from '../../pages/painel/materia';
import Materias from '../../pages/painel/materias';
import Diario from '../../pages/painel/diario';
import Diarios from '../../pages/painel/diarios';
import Usuarios from '../../pages/painel/usuarios';
import Error from '../../components/error';
import { AppContext } from '../app-container';
import PrivateRouter from './router/private-router';
import { error } from '../../components/alerts';
import favicon from '../../assets/img/favicon-nucleo.ico';

function Painel(props) {
    const { client, user } = useContext(AppContext);
    const history = useHistory();

    useEffect(() => {
        if (history.action === 'REPLACE') {
            error('Você não tem permissão para acessar essa página!');
        }
    }, [history.action]);

    useEffect(() => {
        const link = document.querySelector("link[rel*='icon']") || document.createElement('link');
        link.type = 'image/x-icon';
        link.rel = 'shortcut icon';
        link.href = favicon;
        document.getElementsByTagName('head')[0].appendChild(link);
    }, []);

    return (
        <Route>
            <Container border>
                <Sidebar />
                <Container
                    direction="column"
                    content="true"
                >
                    <Header>
                        <HeaderRight
                            instituicao={client.nome}
                            username={user.nome}
                        />
                    </Header>

                    <AppContent>
                        <Switch>
                            <PrivateRouter
                                path="/painel/"
                                exact
                                publica
                                component={Home}
                            />
                            <PrivateRouter
                                path="/painel/orgaos"
                                permissao={['R_ORGAOS', 'M_ORGAOS']}
                                component={Orgaos}
                            />
                            <PrivateRouter
                                path="/painel/tipos_diario"
                                permissao={['R_TIPOS_DIARIO', 'M_TIPOS_DIARIO']}
                                component={TiposDiario}
                            />
                            <PrivateRouter
                                path="/painel/tipos_materia"
                                permissao={['R_TIPOS_MATERIA', 'M_TIPOS_MATERIA']}
                                component={TiposMateria}
                            />
                            <PrivateRouter
                                path="/painel/materias/editor"
                                permissao="M_MATERIAS"
                                component={Materia}
                            />
                            <PrivateRouter
                                path="/painel/materias"
                                permissao="R_MATERIAS"
                                component={Materias}
                            />
                            <PrivateRouter
                                path="/painel/diarios/editor"
                                permissao="M_DIARIOS"
                                component={Diario}
                            />
                            <PrivateRouter
                                path="/painel/diarios"
                                permissao="R_DIARIOS"
                                component={Diarios}
                            />
                            <PrivateRouter
                                publica
                                path="/painel/usuarios"
                                component={Usuarios}
                            />
                            <Route component={Error} />
                        </Switch>
                    </AppContent>
                </Container>
            </Container>
        </Route>
    );
}

export default Painel;
