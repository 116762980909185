import React from 'react';
import PropTypes from 'prop-types';
import {
    Topo, Title, Current, Origin,
} from './styles';
import Icon from '../icon';
import { colors } from '../../configs/theme';

TitlePageCidadao.propTypes = {
    title: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    origin: PropTypes.string.isRequired,
};

function TitlePageCidadao({ title, icon, origin }) {
    return (
        <Topo>
            <Title>
                <Icon
                    className={icon}
                    size="25px"
                    color={colors.grey_chumbo_table}
                    marginRight="12px"
                />
                {title}
            </Title>
            <Current>
                <Icon
                    color={colors.primary_blue}
                    size="22px"
                    className="icon-materias"
                    marginRight="12px"
                />
                <Origin>{origin}</Origin>
            </Current>
        </Topo>
    );
}

export default TitlePageCidadao;
