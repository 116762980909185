import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import moment from 'moment';
import ListaMaterias, { ListaMateriasContext, useListaMaterias } from '../../../components/lista-materias';
import { Container } from './styles';
import TitlePageCidadao from '../../../components/title-page-cidadao';
import { pathname2Query } from '../../../utils/RouterRes';
import Request, { getLimit } from '../../../utils/Request';

moment.locale('pt-br');

function DiarioLista(props) {
    const [diario, setDiario] = useState({});

    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();

    const url_params = pathname2Query(location.pathname, match.url);

    async function getMaterias({
        page, limit: table_limit, ...lista_props
    }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const params = {
            diario: url_params.id,
        };

        const req_materias = request.setRequest('materias', 'listar', { limit, ...params });
        const result = await request.execute();
        return result[req_materias];
    }

    const materiasState = useListaMaterias({
        getMaterias,
    });


    useEffect(() => {
        async function getDiario() {
            const request = new Request();

            const req_diario = request.setRequest('diarios', 'listar', { id: url_params.id });
            const result = await request.execute();

            if (result[req_diario] && result[req_diario].dados && result[req_diario].dados.id) {
                const { dados } = result[req_diario];
                const { data } = dados;
                const data_descricao = moment(data, 'YYYY-MM-DD').format('DD MMMM [de] YYYY');
                const descricao_full = `${dados.tipo_diario_descricao} de ${data_descricao}`;

                setDiario({ ...dados, descricao_full, data_descricao });
            } else {
                history.push('/');
            }
        }

        if (url_params && url_params.id) {
            getDiario(url_params.id).then();
        } else {
            history.push('/');
        }
    }, [url_params.id]);

    useEffect(() => {
        if (diario && diario.id) {
            materiasState.handleData(getMaterias);
        }
    }, [diario]);

    return (
        <Container>
            {diario && diario.descricao_full && (
                <TitlePageCidadao
                    icon="icon-lista"
                    origin={diario.descricao_full}
                    title="Lista"
                />
            )}
            <ListaMateriasContext.Provider value={materiasState}>
                <ListaMaterias />
            </ListaMateriasContext.Provider>
        </Container>
    );
}

export default DiarioLista;
