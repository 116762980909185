import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory } from 'react-router';
import {
    Container, FieldSet, Filtro, Filtros, ContadorResultados, TitlePage, Resultados, FiltroDescricao,
} from './styles';
import Icon from '../../../components/icon';
import ListaMaterias, { ListaMateriasContext, useListaMaterias } from '../../../components/lista-materias';
import Request, { getLimit } from '../../../utils/Request';
import { colors } from '../../../configs/theme';
import { CidadaoContext } from '../../../app/cidadao';

function Buscar(props) {
    const { searchParams, setSearchParams, searchParamsInitialValues } = useContext(CidadaoContext);
    const [total, setTotal] = useState(0);
    const history = useHistory();

    function stateToParams(state) {
        const {
            filtrar, orgao, periodo, tipo,
        } = state;

        const { value: orgao_id = '' } = orgao;
        const { value: tipo_id = '' } = tipo;

        const filtros = {
            orgao: orgao_id,
            tipo: tipo_id,
            filtrar,
        };

        if (periodo) {
            const from = moment(periodo[0]).format('YYYY-MM-DD');
            const to = moment(periodo[1]).format('YYYY-MM-DD');

            filtros.de = from;
            filtros.ate = to;
        }

        return filtros;
    }

    async function getMaterias({
        page, limit: table_limit, ...lista_props
    }) {
        const request = new Request();

        const filtros = stateToParams(searchParams);

        const limit = getLimit(page, table_limit);

        const params = {
            ...filtros,
        };

        const req_materias = request.setRequest('materias', 'listar', { limit, situacao_diario: 2, ...params });
        const result = await request.execute();
        return result[req_materias];
    }

    const materiasState = useListaMaterias({
        getMaterias,
    });

    useEffect(() => {
        async function getLista() {
            const total_materias = await materiasState.handleData(getMaterias, true);
            setTotal(total_materias);
        }

        getLista().then();
    }, [searchParams]);

    function getFiltros() {
        const filtros = [];

        if (searchParams.periodo && searchParams.periodo) {
            const from = moment(searchParams.periodo[0]).format('DD/MM/YYYY');
            const to = moment(searchParams.periodo[1]).format('DD/MM/YYYY');

            filtros.push({
                index: 'periodo',
                description: `${from} - ${to}`,
                value: searchParams.periodo,
            });
        }

        if (searchParams.filtrar && searchParams.filtrar !== '') {
            filtros.push({
                index: 'filtrar',
                description: searchParams.filtrar,
                value: searchParams.filtrar,
            });
        }

        if (searchParams.tipo && searchParams.tipo.value) {
            filtros.push({
                index: 'tipo',
                description: searchParams.tipo.label,
                value: searchParams.orgao.value,
            });
        }

        if (searchParams.orgao && searchParams.orgao.value) {
            filtros.push({
                index: 'orgao',
                description: searchParams.orgao.label,
                value: searchParams.orgao.value,
            });
        }

        return filtros;
    }

    return (
        <Container>
            <TitlePage>Resultados do filtro:</TitlePage>
            <FieldSet>
                <Filtros>
                    {getFiltros().map((filtro) => (
                        <Filtro key={filtro.index}>
                            <FiltroDescricao>{filtro.description}</FiltroDescricao>
                            <Icon
                                className="icon-close"
                                size="10px"
                                color={colors.grey_placeholder}
                                hover={colors.primary_blue}
                                onClick={() => {
                                    setSearchParams(
                                        {
                                            ...searchParams,
                                            [filtro.index]: searchParamsInitialValues[filtro.index],
                                        },
                                    );

                                    if (getFiltros().length === 1) {
                                        history.push('/');
                                    }
                                }}
                            />
                        </Filtro>
                    ))}
                </Filtros>
                <ContadorResultados>{`${total} resultados`}</ContadorResultados>
                <Resultados>
                    <ListaMateriasContext.Provider value={materiasState}>
                        <ListaMaterias />
                    </ListaMateriasContext.Provider>
                </Resultados>
            </FieldSet>
        </Container>
    );
}

export default Buscar;
