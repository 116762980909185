import React from 'react';
import PropTypes from 'prop-types';
import { colors } from '../../configs/theme';
import { StyledIcon } from './styles';

Icon.propTypes = {
    size: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    color: PropTypes.string,
    marginRight: PropTypes.string,
    marginLeft: PropTypes.string,
    marginTop: PropTypes.string,
    marginBottom: PropTypes.string,
    margin: PropTypes.string,
    width: PropTypes.string,
    height: PropTypes.string,
    hover: PropTypes.string,
    right: PropTypes.string,
    left: PropTypes.string,
    rotate: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    zIndex: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]),
    top: PropTypes.string,
    kind: PropTypes.oneOf(['action-button', 'normal']),
    bottom: PropTypes.string,
    pointer: PropTypes.bool,
    absolute: PropTypes.bool,
};

Icon.defaultProps = {
    color: colors.white,
    marginRight: null,
    marginLeft: null,
    marginTop: null,
    marginBottom: null,
    margin: null,
    kind: 'normal',
    hover: null,
    width: null,
    height: null,
    zIndex: null,
    pointer: true,
};

function Icon({
    size, color, marginRight, marginLeft, marginTop, marginBottom, margin, className, ...props
}) {
    return (
        <StyledIcon
            size={size}
            color={color}
            className={className}
            marginRight={marginRight}
            marginLeft={marginLeft}
            marginTop={marginTop}
            marginBottom={marginBottom}
            margin={margin}
            {...props}
        />
    );
}

export default Icon;
