import React, {
    useRef, useState, useEffect, useContext,
} from 'react';
import { useHistory } from 'react-router';
import { Field, Formik } from 'formik';
import PageContainer from '../../../components/page-container';
import Table from '../../../components/table';
import Request, { getLimit } from '../../../utils/Request';
import Situacao from '../../../components/situacao';
import { getSituacaoMateria } from '../../../utils/materias';
import { colors } from '../../../configs/theme';
import Icon from '../../../components/icon';
import { error, success } from '../../../components/alerts';
import confirmAlert from '../../../components/confirm-alert';
import { Row } from '../../../app/global-styles';
import ControlledInput from '../../../components/form-components/controlled-input';
import { parser } from '../../../utils/Select';
import { AppContext } from '../../../app/app-container';

function Materias(props) {
    const { hasPermissao } = useContext(AppContext);
    const history = useHistory();
    const tableRef = useRef();
    const [searchParams, setSearchParams] = useState({});

    const initialFilters = {
        situacao: {},
        tipo: {},
        diario: {},
        filtrar: '',
    };

    async function getMaterias({ page, limit: table_limit, ...table_props }) {
        const request = new Request();

        const limit = getLimit(page, table_limit);

        const req_orgaos = request.setRequest('materias', 'listar', { limit, ...searchParams });
        const result = await request.execute();
        return result[req_orgaos];
    }

    useEffect(() => {
        tableRef.current.fireFetchData();
    }, [searchParams]);

    async function desativarMateria({ original }) {
        if (original && original.id) {
            const desativar = async () => {
                const request = new Request();

                const { id } = original;

                const req_desativar = request.setRequest('materias', 'desativar', { id });
                const result = await request.execute();

                if (result[req_desativar] === true) {
                    success('Matéria excluída com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir matéria!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir matéria?',
                subtitle: `Ao confirmar a matéria ${original.titulo} será excluída!`,
                onConfirm() {
                    desativar(original).then();
                },
            });

        } else {
            error('Matéria inválida!');
        }
    }

    async function getTiposMateriaSelect(search) {
        const request = new Request();

        const req_orgaos = request.setRequest('tiposMateria', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_orgaos] || [];

        return parser('descricao', 'id', dados);
    }

    async function getDiarioSelect(search) {
        const request = new Request();

        const req_diarios = request.setRequest('diarios', 'listar', { search });
        const result = await request.execute();

        const { dados } = result[req_diarios] || [];

        return parser('numero_descricao', 'id', dados);
    }

    function makeFormFiltros(formikState) {
        return (
            <Row as="form">
                <Field
                    name="filtrar"
                    type="text"
                    placeholder="Filtrar"
                    size={1}
                    minWidth={210}
                    onChange={(e) => {
                        const { value, name } = e.target;
                        setSearchParams({ ...searchParams, [name]: value });
                    }}
                    icon="icon-lupa"
                    kind="filtros"
                    show_filtros={false}
                    component={ControlledInput}
                />
                <Field
                    component={ControlledInput}
                    name="tipo"
                    margin="0 0 0 15px"
                    padding="0"
                    isClearable
                    type_select="async"
                    minWidth={210}
                    type="select"
                    placeholder="Tipo da matéria"
                    size={1}
                    onChange={({ value }) => {
                        setSearchParams({ ...searchParams, tipo: value });
                    }}
                    kind="filtros"
                    defaultOptions
                    cacheOptions
                    loadOptions={getTiposMateriaSelect}
                />
                <Field
                    component={ControlledInput}
                    name="diario"
                    margin="0 0 0 15px"
                    padding="0"
                    isClearable
                    type_select="async"
                    minWidth={210}
                    type="select"
                    placeholder="Diário"
                    size={1}
                    onChange={({ value }) => {
                        setSearchParams({ ...searchParams, diario: value });
                    }}
                    kind="filtros"
                    defaultOptions
                    cacheOptions
                    loadOptions={getDiarioSelect}
                />
                <Field
                    component={ControlledInput}
                    name="situacao"
                    margin="0 0 0 15px"
                    minWidth={210}
                    padding="0"
                    type="select"
                    isClearable
                    placeholder="Situação da matéria"
                    size={1}
                    onChange={({ value }) => {
                        setSearchParams({ ...searchParams, situacao: value });
                    }}
                    kind="filtros"
                    options={[
                        { value: 1, label: 'Aguardando' },
                        { value: 2, label: 'Aprovada' },
                        { value: 3, label: 'Reprovada' },
                    ]}
                />
                {Object.keys(searchParams).some((key) => searchParams[key] && searchParams[key] !== '') && (
                    <Icon
                        className="icon-limpar"
                        size="26px"
                        color={colors.grey_placeholder}
                        title="Limpar filtros"
                        onClick={() => {
                            setSearchParams({});
                            formikState.resetForm({ ...initialFilters });
                        }}
                        hover={colors.white}
                        marginLeft="15px"
                        kind="action-button"
                    />
                )}
            </Row>
        );
    }

    return (
        <PageContainer
            padding
            scroll
        >
            <Row
                margin="0 0 21px 0"
            >
                <Formik
                    initialValues={{ ...initialFilters }}
                >
                    {makeFormFiltros}
                </Formik>
            </Row>
            <Table
                headers={[
                    {
                        name: 'Órgão',
                        accessor: 'orgao_descricao',
                    },
                    {
                        name: 'Titulo',
                        accessor: 'titulo',
                    },
                    {
                        name: 'Tipo',
                        accessor: 'tipo_materia_descricao',
                    },
                    {
                        name: 'Autor',
                        accessor: 'autor_nome',
                    },
                    {
                        name: 'Situação',
                        accessor: 'situacao',
                        Cell: (props) => (
                            <Situacao
                                message={props.original.situacao_descricao}
                                color={getSituacaoMateria(Number(props.value))}
                            />
                        ),
                    },
                ]}
                options={hasPermissao('D_MATERIAS') ? (props) => (
                    <Icon
                        hidden
                        size="16px"
                        hover={colors.red_error_message}
                        color={colors.black_table}
                        className="icon-trash"
                        onClick={() => desativarMateria(props)}
                    />
                ) : null}
                data_function={getMaterias}
                clickHandler={(row, column) => {
                    if (column.id !== 'options' && row && row.original && row.original.id) {
                        history.push(`/painel/materias/editor/id=${row.original.id}`);
                    }
                }}
                tableRef={tableRef}
            />
        </PageContainer>
    );
}

export default Materias;
