import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import TitlePageCidadao from '../../../components/title-page-cidadao';
import { Container } from './styles';
import Request from '../../../utils/Request';
import { pathname2Query } from '../../../utils/RouterRes';
import SumarioToggle from '../../../components/sumario-toggle';

function DiarioSumario(props) {
    const [diario, setDiario] = useState({});
    const [orgaos, setOrgaos] = useState([]);

    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();

    const url_params = pathname2Query(location.pathname, match.url);

    useEffect(() => {
        async function getDiario() {
            const request = new Request();

            const req_diario = request.setRequest('diarios', 'listar', { id: url_params.id });
            const result = await request.execute();

            if (result[req_diario] && result[req_diario].dados && result[req_diario].dados.id) {
                const { dados } = result[req_diario];
                const { data } = dados;
                const data_descricao = moment(data, 'YYYY-MM-DD').format('DD MMMM [de] YYYY');
                const descricao_full = `${dados.tipo_diario_descricao} de ${data_descricao}`;

                setDiario({ ...dados, descricao_full, data_descricao });

                const req_sumario = request.setRequest('materias', 'selecionarSumario', { diario: url_params.id });
                const result_sumario = await request.execute();
                setOrgaos(result_sumario[req_sumario]);
            } else {
                history.push('/');
            }
        }

        if (url_params && url_params.id) {
            getDiario(url_params.id).then();
        } else {
            history.push('/');
        }
    }, [url_params.id]);
    return (
        <Container>
            {diario && diario.descricao_full && (
                <TitlePageCidadao
                    icon="icon-sumario"
                    origin={diario.descricao_full}
                    title="Listando por sumário"
                />
            )}
            {orgaos.length > 0 && orgaos.map((orgao) => (
                <SumarioToggle
                    key={orgao.id}
                    orgaos={orgao}
                />
            ))}
        </Container>
    );
}

export default DiarioSumario;
