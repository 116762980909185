import styled from 'styled-components';
import { parse } from '../../../utils/FontSize';
import { colors } from '../../../configs/theme';

export const Container = styled('div')`
    display: flex;
    flex-direction: column;
`;

export const Header = styled('div')`
    width: 100%;
    display: flex;
    align-items: flex-start;
    margin-bottom: 41px;
`;

export const Informacoes = styled('div')`
    width: auto;
    flex-grow: 1;
    display: flex;
`;

export const Logo = styled('img')`
    width: 120px;
    height: 120px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    flex-shrink: 0;
    margin-right: 19px;
`;

export const Dados = styled('div')`
    width: auto;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

export const Title = styled('h1')`
    font-weight: bold;
    font-size: ${parse(36)};
    line-height: 41px;
    color: ${colors.grey_chumbo_table};
    text-transform: uppercase;
`;

export const Text = styled('span')`
    font-weight: bold;
    font-size: ${parse(12)};
    line-height: 18px;
    color: ${colors.grey_chumbo_table};
    text-transform: uppercase;
`;

export const Buttons = styled('div')`
    width: 450px;
    height: 36px;
    display: flex;
    margin-left: 19px;
`;

export const TituloMateria = styled('h2')`
    font-weight: bold;
    font-size: ${parse(18)};
    line-height: 21px;
    color: ${colors.grey_chumbo_table};
    margin-bottom: 24px;
    text-transform: uppercase;
    text-decoration-line: underline;
`;

export const ConteudoMateria = styled('article')`
    width: 100%;
    color: ${colors.grey_chumbo_table};
    font-size: ${parse(14)};
    line-height: 20px;
    text-align: justify;
    
    table{
        border-collapse: collapse;
    }
    
    table th,
    table td {
        border: 1px solid black;
    }
`;

export const Alert = styled('h6')`
    font-size: ${parse(14)};
    line-height: 20px;
    font-style: italic;
    margin-top: 15px;
    font-weight: normal;
    color: ${colors.grey_text};
`;
