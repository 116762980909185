import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router';
import PropTypes from 'prop-types';
import { AppContext } from '../../app-container';

function PrivateRouter({ permissao, publica, ...props }) {
    const { hasPermissao } = useContext(AppContext);

    const hasPermissaoArray = (permissoes) => permissoes.some((this_permissao) => hasPermissao(this_permissao));

    if (
        (permissao && Array.isArray(permissao) && hasPermissaoArray(permissao))
        || ((permissao && permissao !== '' && hasPermissao(permissao)) || publica)
    ) {
        return (
            <Route
                {...props}
            />
        );
    } else {
        return (
            <Redirect to="/painel" />
        );
    }
}

PrivateRouter.propTypes = {
    permissao: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.array,
    ]),
    publica: PropTypes.bool,
};

PrivateRouter.defaultProps = {
    permissao: null,
    publica: false,
};


export default PrivateRouter;
