import React from 'react';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import { StyledSplit, Panel } from './styles';

Resizer.propTypes = {
    left: PropTypes.number.isRequired,
    minLeft: PropTypes.number,
    right: PropTypes.number.isRequired,
    minRight: PropTypes.number,
    children: (props, propName, componentName) => {
        if (!Array.isArray(props[propName]) || props[propName].length !== 2) {
            return new Error(`${propName} needs to be an array of two numbers`);
        }

        return null;
    },
};

function Resizer({
    left, right, minLeft, minRight, children, ...props
}) {
    const split_props = {
        ...props,
    };

    const minSize = [];
    if (minLeft) {
        minSize.push(minLeft);
    }
    if (minRight) {
        minSize.push(minRight);
    }

    if (minSize.length > 0) {
        split_props.minSize = minSize;
    }

    return (
        <StyledSplit
            sizes={[left, right]}
            {...split_props}
        >
            {children.map((child, idx) => (
                <Scrollbars
                    autoHeightMax="100%"
                    key={`panel${idx}`}
                    renderTrackHorizontal={(props) => (
                        <div
                            {...props}
                            className="track-horizontal"
                            style={{ display: 'none' }}
                        />
                    )}
                    renderThumbHorizontal={(props) => (
                        <div
                            {...props}
                            className="thumb-horizontal"
                            style={{ display: 'none' }}
                        />
                    )}
                >
                    <Panel>{child}</Panel>
                </Scrollbars>
            ))}
        </StyledSplit>
    );
}

export default Resizer;
