import React from 'react';
import PropTypes from 'prop-types';
import {
    Container, StyledInput, IconContainer, Label, Required, LabelMessage,
} from '../styles';
import { colors } from '../../../configs/theme';
import Icon from '../../icon';
import { Row } from '../../../app/global-styles';
import ErrorMessage from '../error-message';

function Input({
    height, background, radius, width, icon, type, kind, label, required, type_button, show_filtros, relative, name, id, flex, margin, disabled, minWidth, error_message, ...props
}) {
    const icon_size = `calc(${height} * 0.4)`;
    let padding_icon = kind === 'search' && icon ? '40px' : null;
    padding_icon = kind === 'filtros' && show_filtros ? '85px' : padding_icon;
    const border = kind !== 'search' || kind !== 'filtros' ? `2px solid ${disabled ? colors.grey_text : colors.white}` : null;
    const padding = kind !== 'search' || kind !== 'filtros' ? '10px' : null;
    id = id || name || null;

    const container_props = {
        width,
        minWidth,
        flex,
        disabled,
        margin,
    };

    const input_props = {
        width: '100%',
        id,
        name,
        error_message,
        disabled,
        ...props,
    };

    const icon_props = {};

    if (type_button) {
        icon_props.as = 'button';
        icon_props.type = type_button;
    }

    if (kind === 'search' || kind === 'filtros') {
        container_props.radius = radius;
        container_props.border = border;
        container_props.height = height;
        container_props.height = height;
        container_props.background = disabled ? colors.grey_lines_table : background;
        input_props.background = disabled ? colors.grey_lines_table : background;
    } else {
        input_props.radius = radius;
        input_props.border = border;
        input_props.background = disabled ? colors.grey_lines_table : background;
        input_props.shadow = true;
    }

    if (kind === 'filtros') {
        container_props.shadow = true;
    }

    if (disabled) {
        input_props.color = colors.grey_placeholder;
    }

    if (kind === 'filtros' && !show_filtros) {
        input_props.radius = '0 5px 5px 0';
    }

    return (
        <Container
            {...container_props}
            kind={kind}
        >
            <Label htmlFor={id}>
                {label && (
                    <LabelMessage>
                        {label}
                        {required && <Required>*</Required>}
                    </LabelMessage>
                )}
                <Row
                    height={height}
                    relative={relative}
                >
                    {icon
                    && (
                        <IconContainer padding_icon="40px">
                            <Icon
                                size={icon_size}
                                color={colors.grey_placeholder}
                                className={icon}
                                {...icon_props}
                            />
                        </IconContainer>
                    )}
                    <StyledInput
                        type={type}
                        padding={padding}
                        padding_icon={padding_icon}
                        {...input_props}
                        kind={kind}
                    />
                    {kind === 'filtros' && show_filtros && (
                        <IconContainer
                            padding_icon="45px"
                            hasIcon
                        >
                            <Icon
                                size={icon_size}
                                color={colors.grey_placeholder}
                                className="icon-filtros"
                            />
                        </IconContainer>
                    )}
                    {error_message && <ErrorMessage error_message={error_message} />}
                </Row>
            </Label>
        </Container>
    );
}

Input.propTypes = {
    background: PropTypes.string,
    error_message: PropTypes.string,
    fontSize: PropTypes.number,
    height: PropTypes.string,
    icon: PropTypes.string,
    id: PropTypes.string,
    kind: PropTypes.oneOf(['normal', 'circular', 'search', 'filtros']),
    label: PropTypes.string,
    margin: PropTypes.string,
    minWidth: PropTypes.number,
    flex: PropTypes.number,
    name: PropTypes.string,
    placeholder: PropTypes.string,
    radius: PropTypes.string,
    type_button: PropTypes.string,
    relative: PropTypes.bool,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    show_filtros: PropTypes.bool,
    type: PropTypes.string,
    width: PropTypes.string,
};

Input.defaultProps = {
    background: colors.white,
    error_message: null,
    fontSize: 20,
    height: '45px',
    icon: null,
    flex: null,
    id: null,
    kind: 'normal',
    margin: null,
    minWidth: null,
    type_button: null,
    name: null,
    placeholder: null,
    radius: '5px',
    relative: true,
    required: false,
    disabled: false,
    show_filtros: true,
    type: 'text',
    width: '100%',
};


export default Input;
