import React, { useState, createContext, useEffect } from 'react';
import {
    BrowserRouter as Router, Route, Switch,
} from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';
import { createBrowserHistory } from 'history';
import { StyledApp } from './styles';
import { getUsuario } from '../../data/Usuario';
import Login from '../../pages/painel/login';
import { basePath } from '../../data/AppData';
import Loader from '../../components/tableloader';
import Request from '../../utils/Request';

import { listar as listarConfigs } from '../../data/Configuracoes';
import { menu } from '../../data/Navigation';
import Cidadao from '../cidadao';
import Painel from '../painel';

const browserhistory = createBrowserHistory();

const AppContext = createContext();

function AppContainer(props) {
    const [show_loader, setShowLoader] = useState(true);
    const [show_loader_login, setShowLoaderLogin] = useState(true);
    const [user, setUser] = useState({});
    const [client, setClient] = useState({});
    const [is_logged, setIsLogged] = useState(false);

    useEffect(() => {
        const start = async () => {
            browserhistory.replace({
                search: '',
            });

            const configs = await listarConfigs();
            setClient({ nome: configs.orgao || '' });
            setShowLoader(false);
        };

        start();
    }, []);


    async function logout(e) {
        e.preventDefault();
        const request = new Request();

        const req_logout = request.setRequest('login', 'logout', {});
        const result = await request.execute();

        if (result[req_logout]) {
            window.location.href = '/';
        }
    }

    function routerCidadao() {
        return (
            <Cidadao />
        );
    }

    function getGroup(permissao = '') {
        const grupo_permissao = Object.entries(user.permissoes).filter(([index, value]) => value.includes(permissao))[0] || [];

        if (grupo_permissao[0]) {
            return grupo_permissao[0];
        } else {
            return '';
        }
    }

    function hasPermissao(permissao, group = '') {
        if (group.trim() === '') {
            group = getGroup(permissao);
        }

        if (group !== '' && user && user.permissoes && user.permissoes[group] && Array.isArray(user.permissoes[group])) {
            return user.permissoes[group].includes(permissao);
        } else return !isNaN(Number(user.admin)) && Number(user.admin) === 1;
    }

    function routerPainel() {
        return (
            <Painel />
        );
    }

    /**
     * Verifica o login do usuário cada vez que uma página do painel é carregada
     *
     * @return {*}
     * @private
     */
    function checkLogin() {
        const checkLoginAsync = async () => {
            const requested_user = await getUsuario();
            const this_is_logged = typeof requested_user === 'object' && Object.keys(requested_user).length > 0;

            // Verifica se o estado de login mudou ou se o usuário estava logado e saiu
            if (is_logged !== this_is_logged || (!this_is_logged && is_logged)) {
                setUser(requested_user);
                setIsLogged(this_is_logged);
            }
            setShowLoaderLogin(false);
        };

        checkLoginAsync();
        // Se o ultimo estado estiver logado acessa o painel
        if (is_logged && user) {
            return routerPainel();
        } else if (!show_loader_login) {
            return <Route component={Login} />;
        } else {
            return (
                <StyledApp>
                    <Loader loading={show_loader_login} />
                </StyledApp>
            );
        }
    }

    if (show_loader === true) {
        return (
            <StyledApp>
                <Loader loading={show_loader} />
            </StyledApp>
        );
    } else {
        const context_data = {
            menu,
            client,
        };

        if (is_logged) {
            context_data.user = user;
            context_data.logout = logout;
            context_data.hasPermissao = hasPermissao;
        }

        return (
            <AppContext.Provider value={context_data}>
                <StyledApp>
                    <Router basename={basePath()}>
                        <LastLocationProvider>
                            <Switch>
                                <Route
                                    path="/painel"
                                    render={checkLogin}
                                />
                                <Route
                                    path="/"
                                    component={routerCidadao}
                                />
                            </Switch>
                        </LastLocationProvider>
                    </Router>
                </StyledApp>
            </AppContext.Provider>
        );
    }
}

export default AppContainer;
export { AppContext };
