import React, {
    useRef, useState, useContext, useEffect,
} from 'react';
import * as yup from 'yup';
import { Field, Formik } from 'formik';
import { Row } from '../../../app/global-styles';
import Button from '../../../components/button';
import { error, loading, success } from '../../../components/alerts';
import Icon from '../../../components/icon';
import { colors } from '../../../configs/theme';
import PageContainer from '../../../components/page-container';
import Resizer from '../../../components/resizer';
import Request, { getLimit } from '../../../utils/Request';
import confirmAlert from '../../../components/confirm-alert';
import ControlledInput from '../../../components/form-components/controlled-input';
import Table from '../../../components/table';

import { AppContext } from '../../../app/app-container';
import { parse } from '../../../utils/Permissoes';
import { Title } from '../../../components/page-container/styles';

function Usuarios() {
    const tableRef = useRef();
    const [usuario, setUsuario] = useState({});
    const [pass_change, setPassChange] = useState(false);
    const { user, hasPermissao } = useContext(AppContext);
    const [permissoesGrupos, setPermissoesGrupos] = useState([]);
    const [permissoesEdit, setPermissoesEdit] = useState({});

    const initialValues = usuario && Object.keys(usuario).length > 0 ? usuario : {
        fone: '',
        nome: '',
        email: '',
        cpf: '',
        sexo: '',
        senha: '',
        senha_confirm: '',
        admin: '0',
    };

    const initialValuesPermissoes = permissoesEdit && Object.keys(permissoesEdit).length > 0 ? permissoesEdit : {
        TIPOS_MATERIA: [],
        TIPOS_DIARIO: [],
        ORGAOS: [],
        DIARIOS: [],
        MATERIAS: [],
        USUARIOS: [],
    };

    async function getUsuario(id) {
        const request = new Request();

        const req_user = request.setRequest('usuarios', 'listar', { id });

        const result = await request.execute();

        if (result[req_user] && result[req_user].dados) {
            const user_to_edit_info = { ...result[req_user].dados };

            const { permissoes, foto, ...user_to_edit } = user_to_edit_info;
            setUsuario({ ...user_to_edit, senha: '', senha_confirm: '' });
        }
    }

    useEffect(() => {
        if (user.id && !isNaN(user.id) && !hasPermissao('M_USUARIOS')) {
            getUsuario(user.id)
                .then();
        }
    }, [user]);

    async function handleSubmit(values, state) {
        const request = new Request();
        const usuario_to_save = {};

        usuario_to_save.fone = values.fone;
        usuario_to_save.nome = values.nome;
        usuario_to_save.email = values.email;
        usuario_to_save.admin = values.admin;
        usuario_to_save.cpf = values.cpf;
        usuario_to_save.sexo = values.sexo;
        usuario_to_save.senha = values.senha;
        usuario_to_save.senha_confirm = values.senha_confirm;

        if (usuario.id) {
            usuario_to_save.id = usuario.id;
        }

        const loadToast = loading('Salvando usuário');

        try {
            const req_orgao = request.setRequest('usuarios', 'salvar', { usuario: usuario_to_save });

            const result = await request.execute();

            if (result[req_orgao] === true) {
                loadToast();
                success(`Usuário ${usuario_to_save.nome} alterado com sucesso`);
                if (tableRef && tableRef.current) tableRef.current.fireFetchData();

                if (Number(user.admin) === 1) {
                    setUsuario({});
                    state.resetForm();
                }
            } else if (Number(result[req_orgao]) > 0) {
                loadToast();
                success(`Usuário ${usuario_to_save.nome} adicionado com sucesso`);
                if (tableRef && tableRef.current) tableRef.current.fireFetchData();

                setUsuario({});
                state.resetForm();
            } else {
                loadToast();
                error('Não foi possível salvar usuário!');
            }
        } catch (e) {
            loadToast();
            error('Falha ao salvar usuário');
        }
        state.setSubmitting(false);
    }

    async function desativarUsuario({ original }) {
        if (original && original.id) {
            const desativar = async () => {
                const request = new Request();

                const { id } = original;

                const req_desativar = request.setRequest('usuarios', 'desativar', { id });
                const result = await request.execute();

                if (result[req_desativar]) {
                    success('Usuário excluído com sucesso!');
                    tableRef.current.fireFetchData();
                } else if (typeof result[req_desativar] === 'string') {
                    error(result[req_desativar]);
                } else {
                    error('Ocorreu um erro ao excluir usuário!');
                }
            };

            confirmAlert({
                title: 'Deseja realmente excluir usuário?',
                subtitle: `Ao confirmar o usuário ${original.descricao} será excluído!`,
                onConfirm() {
                    desativar(original)
                        .then();
                },
            });
        } else {
            error('Usuário inválido!');
        }
    }

    function handleUsuarioClick(data, column) {
        if (column.id !== 'options' && data.original && hasPermissao('M_USUARIOS')) {
            const { original } = data;

            const usuario_to_edit = {
                nome: original.nome,
                email: original.email,
                sexo: original.sexo,
                fone: original.fone,
                cpf: original.cpf,
                id: original.id,
                senha: '',
                senha_confirm: '',
                admin: original.admin,
            };

            const permissoes_to_edit = {
                ...original.permissoes,
            };

            setUsuario(usuario_to_edit);
            setPermissoesEdit(permissoes_to_edit);
        }
    }

    async function getUsuarios({ page, limit: table_limit = 0, ...table_props }) {
        const request = new Request();

        const limit = table_limit !== 0 ? getLimit(page, table_limit) : null;

        const req_orgaos = request.setRequest('usuarios', 'listar', { limit });
        const result = await request.execute();

        return result[req_orgaos];
    }

    async function getPermissoes() {
        const request = new Request();

        const req_orgaos = request.setRequest('permissoes', 'listar', {});
        const result = await request.execute();

        return result[req_orgaos];
    }

    function submitPermissoes(values, state) {
        async function asyncSubmit(new_permissoes) {
            const request = new Request();

            const req_permissoes = request.setRequest('usuarios', 'alterarPermissoes', { id: usuario.id, permissoes: new_permissoes });

            const result = await request.execute();

            if (result[req_permissoes] === true) {
                success(`Permissões do usuário ${usuario.nome} atualizadas com sucesso!`);
                if (tableRef && tableRef.current) tableRef.current.fireFetchData();
            } else if (typeof result[req_permissoes] === 'string') {
                error(result[req_permissoes]);
            } else {
                error('Ocorreu um erro ao atualizar as permissões do usuário!');
            }
            state.setSubmitting(false);
        }

        asyncSubmit(values);
    }

    useEffect(() => {
        async function requestPermissoes() {
            const permissoes = await getPermissoes();
            setPermissoesGrupos(permissoes);
        }

        requestPermissoes().then();
    }, []);

    function makeForm(formState) {
        if (formState.values.senha !== '') {
            setPassChange(true);
        } else {
            setPassChange(false);
        }

        return (
            <form onSubmit={formState.handleSubmit}>
                <Row
                    height="auto"
                    spaceBetween
                >
                    <Field
                        component={ControlledInput}
                        type="text"
                        required
                        size={4}
                        name="email"
                        label="E-mail"
                        placeholder="Ex.: exemplo@gmail.com*"
                    />
                    <Field
                        component={ControlledInput}
                        type="text"
                        required
                        size={4}
                        name="nome"
                        label="Nome"
                        placeholder="Ex.: Equipe Núcleo"
                    />
                </Row>
                <Row
                    height="auto"
                    spaceBetween
                >
                    <Field
                        component={ControlledInput}
                        size={4}
                        type="mask"
                        name="fone"
                        required
                        label="Telefone"
                        mask={(val) => {
                            if (val.length < 14) {
                                return ['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
                            }
                            return ['(', /[1-9]/, /\d/, ')', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
                        }}
                        placeholder="Ex.: (00)99999-9999"
                    />
                    <Field
                        component={ControlledInput}
                        required
                        size={4}
                        type="mask"
                        mask={[/[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '.', /[0-9]/, /[0-9]/, /[0-9]/, '-', /[0-9]/, /[0-9]/]}
                        name="cpf"
                        label="CPF"
                        placeholder="Ex.: 000.000.000-00"
                    />
                </Row>
                <Row
                    height="auto"
                    spaceBetween
                >
                    <Field
                        component={ControlledInput}
                        required
                        options={[
                            { label: 'Masculino', value: 'M' },
                            { label: 'Feminino', value: 'F' },
                        ]}
                        size={4}
                        type="input-radio"
                        name="sexo"
                        label="Sexo"
                    />
                    {hasPermissao('P_USUARIOS') && (Number(usuario.id) !== Number(user.id) || !usuario.id) && (
                        <Field
                            name="admin"
                            label="Tipo de Usuário"
                            component={ControlledInput}
                            required
                            options={[{ value: '0', label: 'Publicador' }, { value: '1', label: 'Administrador' }]}
                            size={4}
                            type="input-radio"
                        />
                    )}
                </Row>
                <Row
                    height="auto"
                    spaceBetween
                >
                    <Field
                        component={ControlledInput}
                        required={!usuario.id || pass_change}
                        size={4}
                        name="senha"
                        label="Senha"
                        placeholder="Digite sua senha"
                        type="password"
                    />
                    <Field
                        component={ControlledInput}
                        required={!usuario.id || pass_change}
                        size={4}
                        type="password"
                        name="senha_confirm"
                        label="Confirme sua senha"
                        placeholder="Confirme sua senha"
                    />
                </Row>
                <Row
                    contentEnd
                    padding="0 15px"
                >
                    {hasPermissao('M_USUARIOS') && Object.entries(usuario).length > 0 && (
                        <Button
                            type="reset"
                            kind="cancel"
                            margin="0 10px 0 0"
                            onClick={() => {
                                setUsuario({});
                                setPermissoesEdit({});
                                formState.resetForm();
                            }}
                        >
                            Cancelar
                        </Button>
                    )}
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                        Salvar
                    </Button>
                </Row>
            </form>
        );
    }

    function formPermissoes(formState) {
        return (
            <form onSubmit={formState.handleSubmit}>
                {Object.entries(permissoesGrupos).map(([index, grupo]) => (
                    <Row
                        height="auto"
                        key={index}
                        spaceBetween
                    >
                        <Field
                            component={ControlledInput}
                            size={4}
                            name={index}
                            label={grupo.label}
                            options={parse(grupo.grupo)}
                            type="checkbox-group"
                        />
                    </Row>
                ))}
                <Row
                    contentEnd
                    padding="0 15px"
                >
                    <Button
                        type="submit"
                        kind="save"
                        disabled={formState.isSubmitting}
                    >
                    Salvar permissões
                    </Button>
                </Row>
            </form>
        );
    }

    const validations = {
        cpf: yup.string()
            .required('CPF é obrigatório!')
            .isCPF('CPF inválido!'),
        fone: yup.string()
            .required('Telefone é obrigatório!')
            .isPhone('Telefone inválido!'),
        email: yup.string()
            .required('E-mail é obrigatório!')
            .uniqueEmail({
                message: 'Esse e-mail já está cadastrado!',
                user_atual: usuario.id,
            })
            .email('E-mail inválido!'),
        nome: yup.string()
            .required('Nome é obrigatório!')
            .trim('Informe o nome!')
            .min(6, 'Nome deve conter no mímino 6 dígitos!')
            .max(32, 'Nome deve conter no máximo 32 dígitos!'),
        sexo: yup.string()
            .required('Sexo é obrigatório!'),
    };

    const admin_validations = {
        admin: yup.string()
            .required('Tipo de usuário é obrigatório!'),
    };

    const pass_validations = {
        senha: yup.string()
            .required('Senha é obrigatória!')
            .oneOf([yup.ref('senha_confirm'), null], 'Senhas devem ser iguais!')
            .min(6, 'Senha deve conter no mínimo 6 dígitos!'),
        senha_confirm: yup.string()
            .required('Confirmação de senha é obrigatória!')
            .oneOf([yup.ref('senha'), null], 'Senhas devem ser iguais!')
            .min(6, 'Senha deve conter no mínimo 6 dígitos!'),

    };

    if (!usuario.id || pass_change) {
        Object.assign(validations, pass_validations);
    }

    if (Number(user.admin) === 1 && (Number(usuario.id) !== Number(user.id) || !usuario.id)) {
        Object.assign(validations, admin_validations);
    }

    const SignupSchema = yup.object(validations);

    if (Number(user.admin) === 1 || hasPermissao('R_USUARIOS')) {
        return (
            <Resizer
                left={45}
                right={55}
                minLeft={500}
                minRight={500}
            >
                <Table
                    headers={[
                        {
                            Header: 'Nome',
                            accessor: 'nome',
                        },
                        {
                            Header: 'E-mail',
                            accessor: 'email',
                        },
                    ]}
                    data_function={getUsuarios}
                    tableRef={tableRef}
                    options={hasPermissao('D_USUARIOS') ? (table_props) => (
                        <Icon
                            hidden
                            size="16px"
                            hover={colors.red_error_message}
                            color={colors.black_table}
                            className="icon-trash"
                            onClick={() => desativarUsuario(table_props)}
                        />
                    ) : null}
                    clickHandler={handleUsuarioClick}
                />
                <PageContainer title="Usuários">
                    <Formik
                        initialValues={initialValues}
                        validationSchema={SignupSchema}
                        enableReinitialize
                        onSubmit={handleSubmit}
                    >
                        {makeForm}
                    </Formik>
                    {hasPermissao('AP_USUARIOS') && usuario.id && usuario.id !== user.id && (
                        <>
                            <Title>Permissões</Title>
                            <Formik
                                initialValues={initialValuesPermissoes}
                                onSubmit={submitPermissoes}
                                enableReinitialize
                            >
                                {formPermissoes}
                            </Formik>
                        </>
                    )}
                </PageContainer>
            </Resizer>
        );
    } else {
        return (
            <PageContainer
                padding
                title="Usuários"
            >
                <Formik
                    initialValues={initialValues}
                    validationSchema={SignupSchema}
                    enableReinitialize
                    onSubmit={handleSubmit}
                >
                    {makeForm}
                </Formik>
            </PageContainer>
        );
    }
}

export default Usuarios;
